import { Theme } from "@informed-innovations/components";
import { Helmet } from "react-helmet";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components";
import LogExternalLink from "../../common/components/LogExternalLink";
import LogInternalLink from "../../common/components/LogInternalLink";
import { Container, ContentContainer, FeatureContainer, TabSection } from "../../common/components/WebLayoutComponents";
import { LOCKER_LOCATION_PAGE_URL, MERCHANT_PAGE_URL } from "../../config/pages";
import { Api } from "../../services/Api";
import FAQHeroMobile from "../../static/img/faqhero-mobile.png";
import FAQHero from "../../static/img/faqhero.png";
import ShippingLabel from "../../static/img/shipping-label.png";

const FAQHeroContainer = styled.div`
   background-image: url(${FAQHero});
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
   background-color: ${Theme.colors.gray[200]};
   display: flex;

   height: 241px;
   max-height: 241px;

   b {
      font-size: 62px;
      color: white;
      margin: auto;
      text-align: center;
   }

   @media screen and (max-width: 1185px) {
      max-width: 100%;
      background-image: url(${FAQHeroMobile});
   }
`;

const TableOfContents = styled.div`
   display: flex;
   flex-direction: column;
   max-width: 248px;
   margin-right: 30px;

   span {
      color: #3573b1;
      font-weight: bold;
   }

   h4,
   span {
      text-decoration: underline;
   }

   h4:hover,
   span:hover {
      text-decoration: none;
   }

   @media screen and (max-width: 1185px) {
      margin: 0 auto;

      ul {
         list-style-type: none;
      }
   }
`;

const CenterFAQHolder = styled.div`
   display: flex;
   flex-direction: column;
   max-width: 1080px;
   word-break: break-word;

   h2 {
      text-align: left;
   }

   h3 {
      margin-bottom: 1.5rem;
   }

   p {
      margin-bottom: 1.5rem;
   }

   ol {
      list-style-type: decimal;
   }

   a {
      max-width: 242px;
   }

   img {
      margin-left: 15px;

      @media screen and (max-width: 1185px) {
         margin: 0 auto;
      }
   }

   @media screen and (max-width: 1185px) {
      a {
         max-width: 100%;
      }
   }
`;

const MobileDiv = styled.div`
   display: flex;
   max-width: 950px;

   @media screen and (max-width: 1185px) {
      flex-direction: column;
   }
`;

export const Faq = () => {
   const api = new Api();
   api.setup();

   const getCSVDownload = async () => {
      return await api.getCSVFile().then(response => {
         const blob = new Blob([response.data], { type: "text/csv" });
         const url = URL.createObjectURL(blob);
         const a = document.createElement("a");
         a.href = url;
         a.download = "USPSSmartLockersActiveAll.csv";
         a.style.display = "none";
         document.body.appendChild(a);
         a.click();
         a.remove();
         URL.revokeObjectURL(url);
      });
   };

   return (
      <Container>
         <Helmet>
            <title>Smart Lockers FAQ | USPS</title>
            <meta
               name="description"
               content="Get answers to common questions about USPS Smart Lockers for package shippers, recipients, and businesses."
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@USPS" />
            <meta name="twitter:title" content="Smart Lockers FAQ | USPS" />
            <meta
               name="twitter:description"
               content="Get answers to common questions about USPS Smart Lockers for package shippers, recipients, and businesses."
            />
            <meta
               name="twitter:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />

            <meta property="og:url" content="https://www.uspssmartpackagelockers.com/" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Smart Lockers FAQ | USPS" />
            <meta
               property="og:description"
               content="Get answers to common questions about USPS Smart Lockers for package shippers, recipients, and businesses."
            />
            <meta
               property="og:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />
         </Helmet>
         <FAQHeroContainer>
            <b>How can we help?</b>
         </FAQHeroContainer>
         <ContentContainer className="medium-pb">
            <FeatureContainer className="horizontal">
               <TableOfContents>
                  <h3>Table of Contents</h3>
                  <ScrollLink to="overview" offset={-101}>
                     <h4>Overview</h4>
                  </ScrollLink>
                  <ScrollLink to="cust-faqs" offset={-101}>
                     <h4>Customer FAQs</h4>
                  </ScrollLink>
                  <ul>
                     <li>
                        <ScrollLink to="cust-general" offset={-101}>
                           <span>General</span>
                        </ScrollLink>
                     </li>
                     <li>
                        <ScrollLink to="cust-package-tracking" offset={-101}>
                           <span>Package Tracking</span>
                        </ScrollLink>
                     </li>
                     <li>
                        <ScrollLink to="cust-package-delivery" offset={-101}>
                           <span>Package Delivery</span>
                        </ScrollLink>
                     </li>
                     <li>
                        <ScrollLink to="cust-package-pickup" offset={-101}>
                           <span>Package Pickup</span>
                        </ScrollLink>
                     </li>
                  </ul>
                  <ScrollLink to="troubleshooting" offset={-101}>
                     <h4>Troubleshooting</h4>
                  </ScrollLink>
                  <ScrollLink to="ship-faqs" offset={-101}>
                     <h4>Shipper FAQs</h4>
                  </ScrollLink>
                  <ul>
                     <li>
                        <ScrollLink to="ship-general" offset={-101}>
                           <span>General</span>
                        </ScrollLink>
                     </li>
                     <li>
                        <ScrollLink to="ship-eligibility" offset={-101}>
                           <span>Eligibility Requirements</span>
                        </ScrollLink>
                     </li>
                     <li>
                        <ScrollLink to="ship-integration" offset={-101}>
                           <span>Integrating with Smart Lockers</span>
                        </ScrollLink>
                     </li>
                  </ul>
                  <ScrollLink to="future-capabilities" offset={-101}>
                     <h4>Future Capabilities</h4>
                  </ScrollLink>
                  <ul>
                     <li>
                        <ScrollLink to="returns" offset={-101}>
                           <span>Returns</span>
                        </ScrollLink>
                     </li>
                     <li>
                        <ScrollLink to="package-tracking" offset={-101}>
                           <span>Package Tracking via Informed Delivery</span>
                        </ScrollLink>
                     </li>
                  </ul>
               </TableOfContents>
               <CenterFAQHolder>
                  <h1 id="overview">Overview: What are USPS Smart Lockers?</h1>
                  <p>
                     USPS Smart Lockers are electronic, keyless lockers that provide a free, secure, and convenient
                     package delivery and returns alternative.
                  </p>
                  <p>
                     USPS Smart Lockers are currently being installed in Post Office lobby locations across the country
                     and give many customers 24/7 access to retrieve their packages.
                  </p>
                  <p>
                     To see a list of active USPS Smart Locker locations, please visit:{" "}
                     <LogInternalLink alternate outline="white" to={LOCKER_LOCATION_PAGE_URL}>
                        Locker Location Page
                     </LogInternalLink>
                     .
                  </p>
                  <h4>How do USPS Smart Lockers work?</h4>
                  <p>
                     Currently, Smart Lockers support direct-to-locker deliveries, redeliveries, and oversized PO Box
                     <sup>&trade;</sup> packages. These capabilities allow for customers to skip the retail line and
                     pick up their packages directly from a Smart Locker.
                  </p>
                  <p>In the near future, updates will enable customers to utilize Smart Lockers for returns as well.</p>
                  <TabSection>
                     <b>For Direct-to-Locker Delivery:</b>
                     <p>
                        You can have packages from USPS approved eCommerce website, Post Office Retail Counters, and
                        Post Office self-service kiosks delivered to a Smart Locker near you.
                     </p>
                     <p>
                        When a package is delivered and placed in the Smart Locker compartment, you will receive an
                        email containing the pickup (QR) code, the address of the Post Office where the package is
                        located and simple instructions. When you arrive at the Post Office Smart Locker location,
                        follow the instructions and scan the QR code to automatically open the designated locker. Once
                        the package has been retrieved, you will receive another email confirming that your package was
                        picked up.{" "}
                     </p>
                     <b>For Redelivery:</b>
                     <p>
                        When you schedule a Redelivery to a USPS Smart Locker, the local Post Office clerk will load
                        your package into a locker and you will receive an email including the pickup (QR) code, the
                        address of the Post Office where the package is located and simple instructions. When you arrive
                        at the USPS Smart Locker location, follow the instructions, and scan the QR code to retrieve the
                        package from the designated locker.{" "}
                     </p>
                     <b>For Oversized PO Box Packages:</b>
                     <p>
                        In some instances, you may receive a Parcel Pickup Notice (PS Form 3908) notifying you that your
                        package could not fit into your PO Box and was placed into a Smart Locker compartment.
                     </p>
                     <p>
                        To retrieve your large package, use the QR code
                        <sup>
                           <ScrollLink to={"footnote1"} offset={-101} style={{ cursor: "pointer" }}>
                              1
                           </ScrollLink>
                        </sup>{" "}
                        or USPS barcode on the form. Either scan the QR code or enter the USPS barcode at the Smart
                        Locker display to open the locker containing your package.
                     </p>
                  </TabSection>
                  <h4>Is there an additional charge for shipping packages to a Smart Locker?</h4>
                  <p>No, this is a free service offered by the United States Postal Service.</p>
                  <h4>Where are USPS Smart Lockers being deployed? </h4>
                  <p>
                     By June 2024, USPS anticipates over 500 Smart Lockers locations will be available in Post Office
                     lobbies nationwide.
                  </p>
                  <p>
                     To check and see if there is a Smart Locker location near you, visit:{" "}
                     <LogInternalLink alternate outline="white" to={LOCKER_LOCATION_PAGE_URL}>
                        Locker Location Page
                     </LogInternalLink>
                     .
                  </p>
                  <h2 id="cust-faqs">Customer FAQs</h2>
                  <h3 id="cust-general">General</h3>
                  <TabSection>
                     <h4>What are the benefits of having my package delivered to a Smart Locker?</h4>
                     <p>
                        Smart Lockers ensure that your packages have been delivered to a safe and secure location. USPS
                        Smart Lockers also provide a flexible, self-service option, allowing you to retrieve your
                        package at your earliest convenience.
                     </p>
                     <h4>How do I ship my package to a Smart Locker?</h4>
                     <p>
                        To ship your package to a USPS Smart Locker location, the merchant must offer Smart Locker as an
                        alternate delivery option at checkout. If available:
                     </p>
                     <ol>
                        <li>Choose “USPS Smart Locker” when selecting a shipping address during checkout. </li>
                        <li>Select which Post Office Smart Locker location you wish to ship your package to. </li>
                        <li>
                           Once you have selected the Post Office Smart Locker location, complete and place your order.{" "}
                        </li>
                     </ol>
                     <h4>I missed my package delivery. Can I have my package re-delivered to a Smart Locker?</h4>
                     <p>
                        Yes. You can choose to have a missed delivery placed in a USPS Smart Locker by following these
                        steps:
                     </p>
                     <ol>
                        <li>
                           Visit the{" "}
                           <LogExternalLink
                              href="https://tools.usps.com/redelivery.htm"
                              target="_blank"
                              rel="noreferrer"
                           >
                              USPS Redelivery site
                           </LogExternalLink>{" "}
                           to schedule your redelivery.{" "}
                        </li>
                        <li>Follow the steps to check if redelivery is available for your address. </li>
                        <li>
                           If there is a Smart Locker available at your local Post Office, you will have the option to
                           select the Smart Locker as your redelivery address.{" "}
                        </li>
                        <li>
                           Complete the redelivery form by selecting your package for redelivery and confirming your
                           information.{" "}
                        </li>
                     </ol>
                     <p>
                        <strong>Note:</strong> Redelivery is only available at your local Post Office. You cannot
                        schedule a redelivery for another location.
                     </p>
                     <p>
                        See{" "}
                        <LogExternalLink
                           href="https://faq.usps.com/s/article/Redelivery-The-Basics"
                           target="_blank"
                           rel="noreferrer"
                        >
                           Redelivery - The Basics
                        </LogExternalLink>{" "}
                        for more information on requesting a Redelivery and other details about the application.{" "}
                     </p>
                     <h4>How long is my QR code valid?</h4>
                     <p>
                        Once your package is delivered to a Smart Locker, your QR code is valid for 5 calendar days.
                        After this period, your package will be removed from the Smart Locker and will be held for
                        pickup at the Post Office Retail Counter.
                     </p>
                     <p>
                        To retrieve your package from the retail counter, please be prepared to present a{" "}
                        <LogExternalLink
                           href="https://faq.usps.com/s/article/Acceptable-Form-of-Identification"
                           target="_blank"
                           rel="noreferrer"
                        >
                           valid ID
                        </LogExternalLink>
                        .
                     </p>
                  </TabSection>
                  <h3 id="cust-package-tracking">Package Tracking</h3>
                  <TabSection>
                     <h4>Where can I see the shipping status of my package?</h4>
                     <p>
                        To track the status of your package, please reference the USPS tracking details on the tracking
                        page that was provided to you.
                     </p>
                  </TabSection>
                  <h3 id="cust-package-delivery">Package Delivery</h3>
                  <TabSection>
                     <h4>How will I be notified that my package has been delivered?</h4>
                     <p>
                        You will receive an email notifying you that your package has been delivered to the Smart
                        Locker. This email will include the Post Office physical address and a one-time QR code or
                        access code that you will use at time of pick-up.
                     </p>
                     <p>
                        Following the initial pickup notification, you may receive up to two (2) additional emails
                        reminding you that your package is ready for pick-up. You will also receive a final email
                        notifying you that QR code has expired and the package has been removed from the Smart Locker
                        compartment and stored behind the retail counter.{" "}
                     </p>
                     <h4>What if my package does not fit or cannot be delivered to a Smart Locker?</h4>
                     <p>
                        If for any reason your package cannot be delivered to a Smart Locker, it will be held for pickup
                        at the Post Office Retail Counter.
                     </p>
                     <p>
                        To pick up your package from the Post Office Retail Counter, please be prepared to present a{" "}
                        <LogExternalLink
                           alternate
                           href="https://faq.usps.com/s/article/Acceptable-Form-of-Identification"
                        >
                           valid ID
                        </LogExternalLink>
                        .
                     </p>
                  </TabSection>
                  <h3 id="cust-package-pickup">Package Pickup</h3>
                  <TabSection>
                     <h4>When can I retrieve my package from a USPS Smart Locker?</h4>
                     <p>
                        Once you’ve received confirmation that your package has been delivered to a Smart Locker, you
                        can go to the Post Office Smart Locker location to retrieve your package. Many Post Office
                        locations have extended lobby access, making it easy for you to retrieve your package at a time
                        that works for you.
                     </p>
                     <p>
                        <strong>Note:</strong> that packages will remain in the locker compartment for five (5) days.
                        Packages that exceeded the 5-day pick-up window will be removed from the Smart Locker
                        compartment and will be held for pickup at the Post Office Retail Counter.
                     </p>
                     <h4>What do I need to bring with me to pick up my package from a Smart Locker?</h4>
                     <p>
                        You will need to scan the one-time QR code or enter the access code to open and retrieve your
                        package from the Smart Locker compartment.
                     </p>
                     <p>
                        If you are picking up a package after the 5-calendar day pickup window, you will need to present
                        a{" "}
                        <LogExternalLink
                           alternate
                           href="https://faq.usps.com/s/article/Acceptable-Form-of-Identification"
                        >
                           valid ID
                        </LogExternalLink>{" "}
                        at the Post Office Retail Counter to retrieve your package.{" "}
                     </p>
                     <h4>Can someone else pick up my package for me?</h4>
                     <p>
                        You are responsible for maintaining the confidentiality of your QR code. While sharing the code
                        is permitted, make sure to think about your safety and privacy, and only share it with people
                        that you trust.
                     </p>
                  </TabSection>
                  <h2 id="troubleshooting">Troubleshooting</h2>
                  <TabSection>
                     <h4>What if I did not receive a QR code?</h4>
                     <p>Check your deleted items or junk email folders to see if it was delivered. </p>
                     <h4>What should I do if the QR code is not working?</h4>
                     <p>
                        If your Smart Locker QR code is not working, you can visit the retail counter to request
                        assistance from a Post Office staff member.
                     </p>
                     <h4>How can I be sure that the email with my QR code can be trusted?</h4>
                     <p>
                        All correspondence regarding packages being delivered to a Smart Locker will be sent from a
                        USPS.com email.
                     </p>
                     <h4>The Smart Locker door is jammed. How can I get my package out?</h4>
                     <p>
                        If your Smart Locker is jammed, please ask a Post Office staff member for assistance at the
                        retail counter.
                     </p>
                     <h4>I am at the Post Office, but the Smart Locker is offline. What should I do?</h4>
                     <p>
                        Please contact a Post Office staff member during normal business hours for further assistance.
                     </p>
                  </TabSection>
                  <h2 id="ship-faqs">Shipper FAQs</h2>
                  <h3 id="ship-general">General</h3>
                  <TabSection>
                     <h4>What are the benefits to shipping with Smart Lockers?</h4>
                     <p>
                        USPS Smart Lockers help ensure successful delivery of your customers’ packages by offering a
                        safe and secure delivery alternative. Smart Lockers provide your customers with a positive post
                        purchase experience, which helps drive customer satisfaction and brand loyalty.
                     </p>
                     <h4>
                        How will my customer be notified that their package has been delivered to a USPS Smart Locker?
                     </h4>
                     <p>
                        When the package is placed in the locker, your customer will receive an email with a QR code and
                        the address of the Post Office where the package is located.
                     </p>
                     <h4>Will I be notified once a customer has retrieved their package?</h4>
                     <p>
                        Yes. You will receive a tracking update that confirms your recipient's package has been picked
                        up.
                     </p>
                     <h4>What will happen if my customer's package does not fit in a Smart Locker?</h4>
                     <p>
                        If your customer's package does not fit in a USPS Smart Locker, the package will be held behind
                        the retail counter of the Post Office.
                     </p>
                     <p>Please review the Eligibility Requirements section for specific requirements.</p>
                     <h4>How can I find active Smart Locker locations?</h4>
                     <ol>
                        <li>
                           Navigate to the Smart Lockers{" "}
                           <LogInternalLink alternate outline="white" to={MERCHANT_PAGE_URL}>
                              Package Shippers
                           </LogInternalLink>{" "}
                           page.
                        </li>
                        <li>Find the Smart Locker location csv flat file and click “Download”.</li>
                        <li>Download the csv file to view all active Smart Locker locations.</li>
                     </ol>
                     <p>
                        <strong>Note:</strong> The active locker location file will be updated on as need basis as new
                        locker locations are deployed.
                     </p>
                  </TabSection>
                  <h3 id="ship-eligibility">Eligibility Requirements</h3>
                  <TabSection>
                     <h4>What size packages will fit in a USPS Smart Locker?</h4>
                     <p>Any package with dimensions up to 18” x 14.5” x 18.5” is accepted for USPS Smart Locker use.</p>
                     <b>Eligible Items:</b>
                     <ul>
                        <li>
                           Priority Mail<sup>&reg;</sup>
                        </li>
                        <li>
                           Priority Mail Express<sup>&reg;</sup>
                        </li>
                        <li>
                           USPS Ground Advantage<sup>&trade;</sup>
                        </li>
                        <li>
                           Media Mail<sup>&reg;</sup>
                        </li>
                        <li>Bound Printed Matter</li>
                        <li>Library Mail</li>
                        <li>Any HAZMAT items not affected by extreme temperatures (lithium batteries, etc.)</li>
                     </ul>
                     <b>Ineligible Items:</b>
                     <ul>
                        <li>Cremated Remains</li>
                        <li>Live Animals</li>
                        <li>Day Old Poultry</li>
                        <li>Any HAZMAT items affected by extreme temperatures (volatile chemicals, etc.)</li>
                        <li>
                           Registered Mail<sup>&reg;</sup>
                        </li>
                        <li>Collect on Delivery (COD)</li>
                        <li>COD Hold for Pickup</li>
                        <li>Return Receipt</li>
                        <li>Restricted Delivery Requests</li>
                        <li>Adult Signature Services</li>
                     </ul>
                     <h4>Are there specific labelling requirements for packages destined to a Smart Locker?</h4>
                     <MobileDiv>
                        <div>
                           <p>
                              Yes. To ensure that a package is delivered to a USPS Smart Locker, the shipping label must
                              include:
                           </p>
                           <ol>
                              <li>Recipient name</li>
                              <li>Recipient return address</li>
                              <li>Distinct delivery address with unique Smart Locker 5 or 9-digit ZIP</li>
                           </ol>
                           <p>
                              <strong>Note:</strong> Each Smart Locker has its own{" "}
                              <strong>distinct delivery address</strong>. The Smart Locker address is different from the
                              actual physical Post Office location address where the Smart Locker is located. The Smart
                              Locker address must be used on the shipping label to ensure the package is successfully
                              delivered to the Smart Locker.
                           </p>
                           <p>
                              To ensure that your package label is USPS compliant, please reference the USPS Parcel
                              Labeling Guide:{" "}
                              <LogExternalLink alternate href="https://postalpro.usps.com/parcellabelingguide">
                                 https://postalpro.usps.com/parcellabelingguide
                              </LogExternalLink>
                              .
                           </p>
                        </div>
                        <img
                           src={ShippingLabel}
                           alt="Shipping Label example"
                           style={{ maxWidth: 320 + "px", marginBottom: 1 + "rem" }}
                        />
                     </MobileDiv>
                     <h4>Is the “DELIVER TO USPS SMART LOCKER” banner required?</h4>
                     <p>
                        While the additional banner is not required, it is highly recommended. The banner indicates to
                        USPS retail personnel that the package should be placed in a Smart Locker compartment.
                     </p>
                  </TabSection>
                  <h3 id="ship-integration">Integrating with Smart Lockers</h3>
                  <TabSection>
                     <h4>How can I start integrating Smart Lockers into my existing checkout experience?</h4>
                     <p>
                        USPS currently offers tools and resources that make it easy for your business to start
                        integrating Smart Lockers into your current systems. Available tools include:
                     </p>
                     <ul>
                        <li>
                           <strong>Locker Lookup API:</strong> Indicates if Smart Locker is located within specified Zip
                           Code
                        </li>
                        <li>
                           <strong>eVS Label API:</strong> Allows high-volume package mailers to print and pay postage
                           electronically
                        </li>
                        <li>
                           <strong>USPS Smart Locker Flat File:</strong>{" "}
                           <button className="button-normal-link" onClick={getCSVDownload}>
                              Downloadable CSV file
                           </button>{" "}
                           of all Smart Locker Locations
                        </li>
                     </ul>
                     <p>
                        If you are looking for more information on Smart Lockers for shippers, please visit the{" "}
                        <LogInternalLink alternate outline="white" to={MERCHANT_PAGE_URL}>
                           Package Shippers
                        </LogInternalLink>{" "}
                        page for more information.
                     </p>
                  </TabSection>
                  <h2 id="future-capabilities">Future Capabilities</h2>
                  <p>
                     USPS is working to add additional features and capabilities to the Smart Locker program. Features
                     like returns and package tracking via Informed Delivery will be available in the future.
                  </p>
                  <h3 id="returns">Returns</h3>
                  <TabSection>
                     <h4>Can I make a return using a Smart Locker?</h4>
                     <p>
                        Currently, customers are not able to make a return using Smart Lockers. However, in the coming
                        months customers will be able to initiate and ship return(s) via a Smart Locker.
                     </p>
                     <p>
                        Please visit the{" "}
                        <LogInternalLink alternate outline="white" to="/">
                           Smart Locker homepage
                        </LogInternalLink>{" "}
                        for updates on when the returns capability is available.
                     </p>
                  </TabSection>
                  <h3 id="package-tracking">Package Tracking via Informed Delivery</h3>
                  <TabSection>
                     <h4>Can I use Informed Delivery to track my package?</h4>
                     <p>
                        This feature is not yet available. However, in the future, users that are enrolled for Informed
                        Delivery will be able to track their packages destined to a Smart Locker via their Daily Digest.
                     </p>
                     <p>
                        For more information on Informed Delivery, please visit the{" "}
                        <LogExternalLink alternate href="https://www.usps.com/manage/informed-delivery.htm">
                           Informed Delivery homepage
                        </LogExternalLink>
                        .
                     </p>
                  </TabSection>
               </CenterFAQHolder>
            </FeatureContainer>
         </ContentContainer>
      </Container>
   );
};

export default Faq;
