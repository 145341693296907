import { Theme } from "@informed-innovations/components";
import { useCallback, useEffect, useState } from "react";
import { isAndroid, isIOS, isTablet } from "react-device-detect";
import { Navigate, useNavigate } from "react-router";
import styled from "styled-components";
import { Container, FlexColumn } from "../../common/components/WebLayoutComponents";
import { loadScript } from "../../common/utils";
import { MobileBreakpoint } from "../../config/constants";
import { NPF_URL } from "../../config/pages";
import { Api } from "../../services/Api";
import AppleWalletImage from "../../static/img/apple-wallet.png";
import NPFLogo from "../../static/img/NPF-Logo.png";
import PropTypes from "prop-types";

const FeatureContainer = styled(FlexColumn)`
   color: ${Theme.colors.black};

   width: 100%;

   a {
      border-radius: 5px;
      max-width: 200px;
      margin: 0 auto;
   }

   b {
      margin: 50px auto 5px;
   }

   .access-code {
      margin: 10px 0;
   }

   .qr-code {
      height: 200px;
      width: 200px;
      margin: 0 0 20px;
   }

   .npf-logo {
      margin: auto 0 auto auto;
      height: 140px;
      width: 190px;
      padding: 0;
   }

   .content-width {
      max-width: 584px;
   }

   .numbered-list {
      list-style-type: decimal;
      font-weight: bold;
      max-width: 854px;

      li span {
         font-weight: normal;
      }
   }

   h2 {
      font-size: 36px;
      margin-top: 25px;
      margin-bottom: 25px;
      color: black;
      font-weight: normal;
   }

   h3 {
      font-size: 22px;
      color: #333366;
   }

   ul {
      padding-left: 15px;
      color: #000;
   }

   @media screen and (max-width: ${MobileBreakpoint}) {
      h2 {
         text-align: center;
      }
      p:first-of-type {
         margin-bottom: 25px;
      }

      .qr-code {
         margin: 20px auto;
      }

      .wallet-image {
         width: fit-content;
         margin: 0 auto;
      }

      .google-pay {
         width: fit-content;
         margin: 0 auto;
      }

      .access-code {
         margin: 10px auto;
      }

      .npf-logo {
         margin: 0 auto;
      }
   }
`;

const ContentContainer = styled.div`
   margin: 0 auto;
   max-width: 1365px;
   margin-bottom: 60px;

   padding: 0 40px;

   &.full {
      width: 100%;
   }

   @media screen and (max-width: 960px) {
      margin-bottom: 40px;
   }
`;

const ItemContainer = styled.div`
   margin-bottom: 30px;

   .npf-item-image-small {
      height: 83px;
      width: 101px;
   }
`;

const TitleRow = styled.div`
   display: flex;

   @media screen and (max-width: ${MobileBreakpoint}) {
      flex-direction: column-reverse;
   }
`;

const NPFThankYouProps = {
   tradeShow: PropTypes.array,
};

export const NPFThankYou = props => {
   const navigate = useNavigate();

   const api = new Api();
   api.setup();

   useEffect(() => {
      setUUID(sessionStorage.getItem("npf-number"));
      setIgnore(false);
   }, []);

   useEffect(() => {
      setNPFEmail(sessionStorage.getItem("npf-email"));
      setAlreadySubmitted(sessionStorage.getItem("npf-already-submitted"));
      if (!sessionStorage.getItem("npf-item")) navigate(NPF_URL);
      else {
         setCheckoutItem(JSON.parse(sessionStorage.getItem("npf-item")));
      }
   }, [navigate]);

   const [checkoutItem, setCheckoutItem] = useState();
   const [npfEmail, setNPFEmail] = useState();
   const [alreadySubmitted, setAlreadySubmitted] = useState(false);

   const [jwtQuery, setJwtQuery] = useState();
   const [ignore, setIgnore] = useState(true);
   const [uuid, setUUID] = useState();

   const getJwtQuery = async uuid => {
      return await api.getJwtQuery(uuid);
   };

   const handleGoogleApiLoad = useCallback(() => {
      if (jwtQuery != null) {
         window.gapi.savetoandroidpay.render("androidPayContainer", {
            jwt: jwtQuery.jwt,
         });
      }
   }, [jwtQuery]);

   useEffect(() => {
      if (isAndroid && jwtQuery?.jwt) {
         loadScript("https://apis.google.com/js/platform.js", handleGoogleApiLoad);
      }
   }, [handleGoogleApiLoad, jwtQuery]);

   useEffect(() => {
      if (!ignore) {
         getJwtQuery(uuid).then(resp => {
            setJwtQuery(resp.data);
         });
         setIgnore(true);
      }
   }, [ignore]);

   function createAndroidPayContainer() {
      if (isAndroid) return <div id="androidPayContainer" className="google-pay" />;
      return null;
   }

   if (props.tradeShow[0]["contest-flag"]) {
      return (
         <Container>
            <ContentContainer className="full">
               <FeatureContainer>
                  <TitleRow>
                     <div>
                        {alreadySubmitted && <h2>Looks like you've already placed an order.</h2>}
                        {!alreadySubmitted && (
                           <>
                              <h2>Congrats, you’ve successfully placed an order.</h2>
                              <ItemContainer className="flex-row">
                                 <img
                                    src={checkoutItem?.source}
                                    alt={checkoutItem?.alt}
                                    className="npf-item-image-small"
                                 />
                                 <div className="flex-column" style={{ paddingLeft: "15px" }}>
                                    <span>
                                       <b>Order Details:</b>
                                    </span>
                                    <span>
                                       <b>Item:</b> {checkoutItem?.title}
                                    </span>
                                    <span>
                                       <b>QTY:</b> 1
                                    </span>
                                 </div>
                              </ItemContainer>
                           </>
                        )}
                     </div>
                     <img src={NPFLogo} alt="Logo for the National Postal Forum." className="npf-logo" />
                  </TitleRow>
                  <h4 style={{ marginBottom: "30px" }} className="content-width">
                     Your giveaway item has been delivered to the Smart Locker located at the Smart Locker Booth #923.
                  </h4>
                  <p style={{ marginBottom: "30px" }} className="content-width">
                     To retrieve your item from the Smart Locker, use the QR code and follow the instructions below:
                  </p>
                  <hr style={{ border: "1px solid #333366", margin: "0 0 30px", maxWidth: "780px" }} />
                  <p style={{ marginBottom: "30px" }} className="content-width">
                     *Please note, the QR code below has also been sent to <a href={`mailto:${npfEmail}`}>{npfEmail}</a>
                     . You can also keep this browser open to scan the QR code at the time of pick-up.
                  </p>
                  <img
                     className="qr-code"
                     src={process.env.REACT_APP_API_URL + "/media/" + uuid + "/qr"}
                     alt="QR code"
                  />
                  <div className="google-pay">
                     {isIOS && !isTablet ? (
                        <div className="wallet-image">
                           <a href={process.env.REACT_APP_API_URL + "/wallet/" + uuid}>
                              <img src={AppleWalletImage} alt="Apple Pay" />
                           </a>
                        </div>
                     ) : (
                        createAndroidPayContainer()
                     )}
                  </div>
                  <b className="access-code">Access Code: 6C89P1</b>
                  <ul className="numbered-list">
                     <li>
                        <span>Visit the Smart Locker kiosk at the Smart Locker booth located on the NPF floor.</span>
                     </li>
                     <li>
                        <span>At the locker kiosk, tap the screen to begin.</span>
                     </li>
                     <li>
                        <span>
                           Scan the QR code (above) or manually enter the access code to open the locker compartment.
                        </span>
                     </li>
                     <li>
                        <span>
                           Once you scan the QR code or enter the access code, the designated locker compartment will
                           automatically open.
                        </span>
                     </li>
                     <li>
                        <span>Retrieve your giveaway item from the designated locker compartment.</span>
                     </li>
                  </ul>
               </FeatureContainer>
            </ContentContainer>
         </Container>
      );
   } else return <Navigate replace to={"/"} />;
};

NPFThankYou.propTypes = NPFThankYouProps;

export default NPFThankYou;
