import styled from "styled-components";
import mapPin from "../../static/img/map-pin.png";
import { useState } from "react";
import PropTypes from "prop-types";

const MapListContainer = styled.div`
   display: flex;
   min-width: 38%;
   max-height: 826px;
   flex-direction: column;
   background: field;
   gap: 2px;
   overflow-y: scroll;

   @media screen and (max-width: 960px) {
      width: 250px;
   }

   /* Hide Scrollbar for Chrome, Safari and Opera */
   ::-webkit-scrollbar {
      display: none;
   }

   /* Hide scrollbar for IE, Edge and Firefox */
   -ms-overflow-style: none; /* IE and Edge */
   scrollbar-width: none; /* Firefox */
`;

const MapListBlock = styled.div`
   display: flex;
   flex-direction: column;
   background: field;
   padding: 10px;
   border-left: 0px solid #333366;
   border-bottom: 2px solid #d3d3d3;
   transition: border-left 150ms ease-in-out;

   :hover {
      border-left: 7px solid #333366;

      .upper-inner {
         margin-right: 7px;
      }
   }

   button {
      border: none;
      border-radius: 3px;
      background: #333366;
      color: white;
      padding: 5px;
      margin-top: 5px;
      max-width: 150px;
   }

   button:hover {
      cursor: pointer;
      background: #d3d3d3;
      color: #333366;
   }
`;

const MapListBlockUpper = styled.div`
   display: flex;
   margin-bottom: 10px;

   img {
      height: 35px;
   }

   p {
      margin: 0 0 0 10px;
   }

   span {
      margin-left: auto;
      min-width: 70px;
   }

   .map-pin {
      background-image: url(${mapPin});
      height: 35px;
      width: 32.08px;
      min-width: 32.08px;
      margin: 0;
      text-align: center;
      color: white;
      padding-top: 2px;
   }

   .upper-inner {
      margin-right: 0px;
      background: transparent;
   }
`;

const MapListBlockUpperInner = styled.div`
   display: flex;
   flex-direction: column;
   margin-right: 5px;
`;

const MapListBlockLower = styled.div`
   display: flex;
   flex-direction: column;
   margin-left: 42.8px;
   margin-top: 3px;
   border-top: 2px solid #d3d3d3;
   padding-top: 10px;

   p {
      margin: 0;
   }
`;

const RetailHoursContainer = styled.div`
   .hours-title-open img {
      transition: rotate 200ms ease-in-out;
      rotate: 180deg;
   }

   .hours-title-closed img {
      transition: rotate 200ms ease-in-out;
      rotate: 0deg;
   }

   .hours-content-open {
      max-height: 1000px;
      overflow: hidden;
      transition: max-height 300ms ease-in-out;
   }

   .hours-content-closed {
      max-height: 0;
      overflow: hidden;
      transition: max-height 300ms ease-in-out;
   }
`;

const MapListEmpty = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
   margin: auto;
   background-color: #f7f7f7;
   justify-content: center;

   h2 {
      color: #595959;
      margin: auto;
   }
`;

const RetailHoursTitle = styled.div`
   display: flex;
   cursor: pointer;

   p {
      color: #3573b1;
   }

   img {
      height: 10px;
      margin: auto 5px auto auto;
   }
`;

const RetailHoursContent = styled.div``;

const RetailHoursHolderProps = {
   data: PropTypes.object,
};

const RetailHoursHolder = props => {
   const [open, setOpen] = useState(false);

   if (props.data.WINDOWSSERVICE) {
      return (
         <RetailHoursContainer>
            <RetailHoursTitle
               className={open ? "hours-title-open" : "hours-title-closed"}
               onClick={() => {
                  setOpen(!open);
               }}
            >
               <p>{(open ? "Hide" : "Show") + " Post Office™ Retail Window Hours"}</p>
            </RetailHoursTitle>
            <RetailHoursContent className={open ? "hours-content-open" : "hours-content-closed"}>
               <p>{props.data.WINDOWSSERVICE[0]}</p>
               <p>{props.data.WINDOWSSERVICE[1]}</p>
               <p>{props.data.WINDOWSSERVICE[2]}</p>
               <p>{props.data.WINDOWSSERVICE[3]}</p>
               <p>{props.data.WINDOWSSERVICE[4]}</p>
               <p>{props.data.WINDOWSSERVICE[5]}</p>
               <p>{props.data.WINDOWSSERVICE[6]}</p>
            </RetailHoursContent>
         </RetailHoursContainer>
      );
   } else {
      return (
         <RetailHoursContainer>
            <p>Post Office™ Retail Window Hours Not Available</p>
         </RetailHoursContainer>
      );
   }
};

RetailHoursHolder.propTypes = RetailHoursHolderProps;

export const MapListHolder = props => {
   const mapLocations = props.mapLocations;

   const createBlock = () => {
      if (mapLocations.data[0] !== undefined) {
         return (
            <MapListContainer>
               {mapLocations.data.map((location, index) => {
                  return (
                     <MapListBlock key={location.facilityID}>
                        <MapListBlockUpper>
                           <p className="map-pin">{index + 1}</p>
                           <MapListBlockUpperInner className="upper-inner">
                              <p>{location.LocationName}</p>
                              <p>{`${location.Address2} ${location.City}, ${location.State} ${location.ZIP5}`}</p>
                           </MapListBlockUpperInner>
                           <span>{`${Number(location.GeoData.Distance).toFixed(2)} mi`}</span>
                        </MapListBlockUpper>
                        <MapListBlockLower>
                           <b>24-hour access, 7 Days a week</b>
                           <RetailHoursHolder data={location.Hours} />
                        </MapListBlockLower>
                        <button>Coming Soon</button>
                     </MapListBlock>
                  );
               })}
            </MapListContainer>
         );
      } else {
         return (
            <MapListContainer>
               <MapListEmpty>
                  <h2>No Lockers Found</h2>
               </MapListEmpty>
            </MapListContainer>
         );
      }
   };

   return createBlock();
};

export default MapListHolder;
