import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import mapPin from "../../static/img/map-pin.png";
import greenMapPin from "../../static/img/green-map-pin.png";
import redMapPin from "../../static/img/red-map-pin.png";
import grayMapPin from "../../static/img/gray-map-pin.png";
import uspsMapPin from "../../static/img/usps-map-pin.png";
import PropTypes from "prop-types";

const containerStyle = {
   height: "826px",
   width: "62%",
};

const mobileContainerStyle = {
   height: "600px",
   width: "100%",
};

const mapPinAssign = location => {
   if (location.lockerStatus === "Operational") {
      return greenMapPin;
   } else if (location.lockerStatus === "Not Configured") {
      return uspsMapPin;
   } else if (location.lockerStatus === "Not Operational") {
      return redMapPin;
   } else if (location.lockerStatus == ("In Maintenance" || "Coming Soon")) {
      return grayMapPin;
   } else return mapPin;
};

const CSVGoogleMapsProps = {
   mapLocations: PropTypes.object,
   mobile: PropTypes.bool,
   mapCenter: PropTypes.object,
   zoom: PropTypes.number,
   noStatus: PropTypes.bool,
   noCount: PropTypes.bool,
};

export const CSVGoogleMaps = props => {
   const { mapLocations, mobile, mapCenter, zoom, noStatus, noCount } = props;

   const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
   });

   const [map, setMap] = React.useState(null);

   const onLoad = React.useCallback(function callback(map) {
      setMap(map);
   }, []);

   const onUnmount = React.useCallback(function callback(map) {
      setMap(null);
   }, []);

   if (isLoaded) {
      if (mapLocations.data !== null) {
         return (
            <GoogleMap
               mapContainerStyle={mobile ? mobileContainerStyle : containerStyle}
               center={mapCenter}
               zoom={zoom}
               onLoad={onLoad}
               onUnmount={onUnmount}
            >
               {mapLocations.data.lockers?.map((location, index) => {
                  return (
                     <MarkerF
                        icon={!noStatus ? mapPinAssign(location) : mapPinAssign("")}
                        key={location?.facilityID}
                        position={{
                           lat: parseFloat(Number(location.locationLatitude)),
                           lng: parseFloat(Number(location.locationLongitude)),
                        }}
                        label={{
                           color: "white",
                           fontSize: "12px",
                           fontWeight: "normal",
                           text: !noCount ? (index + 1).toString() : " ",
                        }}
                     />
                  );
               })}
               {/*<MarkerF position={{ lat: mapLocations.data.sourceLatitude, lng: mapLocations.data.sourceLongitude }} /> */}
            </GoogleMap>
         );
      } else
         return (
            <GoogleMap
               mapContainerStyle={mobile ? mobileContainerStyle : containerStyle}
               center={mapCenter}
               zoom={zoom}
               onLoad={onLoad}
               onUnmount={onUnmount}
            />
         );
   } else return <></>;
};

CSVGoogleMaps.propTypes = CSVGoogleMapsProps;

export default CSVGoogleMaps;
