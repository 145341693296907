import styled from "styled-components";
import greenMapPin from "../../static/img/green-map-pin.png";
import redMapPin from "../../static/img/red-map-pin.png";
import grayMapPin from "../../static/img/gray-map-pin.png";
import uspsMapPin from "../../static/img/usps-map-pin.png";
import mapPin from "../../static/img/map-pin.png";
import { toUpper } from "ramda";
import { useRef, useState } from "react";
import searchIcon from "../../static/img/Search-Icon.PNG";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { Loading } from "./Loading";
import { Api } from "../../services/Api";
import { AddressChecker } from "../utils";
import { Button } from "@informed-innovations/components";
import Tooltip from "./Tooltip";
import infoTooltip from "../../static/img/info.svg";
import PropTypes from "prop-types";

const MapListContainer = styled.div`
   display: flex;
   min-width: 38%;
   max-height: 826px;
   flex-direction: column;
   background: field;
   gap: 2px;
   overflow-y: scroll;
   box-shadow: 0px 3px 6px #00000029;

   @media screen and (max-width: 960px) {
      width: 100%;
      max-height: 600px;
   }

   /* Hide Scrollbar for Chrome, Safari and Opera */

   ::-webkit-scrollbar {
      display: none;
   }

   /* Hide scrollbar for IE, Edge and Firefox */
   -ms-overflow-style: none; /* IE and Edge */
   scrollbar-width: none; /* Firefox */
`;

const SearchContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding: 10px;
   border-bottom: 2px solid #d3d3d3;
   position: sticky;
   top: 0;
   background-color: field;
   z-index: 1;

   .exit-button {
      width: 26px;
      height: 26px;
      margin-left: auto;
      cursor: pointer;
   }

   .location-input-inner {
      border: 1px solid black;
      border-radius: 5px;
      display: flex;
      overflow: hidden;
      background-color: #ffffff;
   }

   .location-text-input {
      padding: 12px 10px;
      border: none;
      width: calc(100% - 50px);
      outline: none;
   }

   .locating-crosshair {
      margin: auto 10px auto auto;
      cursor: pointer;
      max-width: 20px;
   }
`;

const MapListBlock = styled.div`
   display: flex;
   flex-direction: column;
   background: field;
   padding: 10px;
   border-left: 0 solid #333366;
   border-bottom: 2px solid #d3d3d3;
   transition: border-left 150ms ease-in-out;

   :hover {
      border-left: 7px solid #333366;

      .upper-inner {
         margin-right: 7px;
      }
   }

   button {
      border: none;
      border-radius: 3px;
      background: #333366;
      color: white;
      padding: 5px;
      margin-top: 5px;
      max-width: 150px;
   }

   button:hover {
      cursor: pointer;
      background: #d3d3d3;
      color: #333366;
   }

   .ship-here-button {
      margin-left: 42.8px;
   }

   @media screen and (max-width: 960px) {
      .ship-here-button {
         margin: auto;
         max-width: 720px;
         width: 100%;
      }
   }
`;

const MapListBlockUpper = styled.div`
   display: flex;
   width: 100%;
   margin-top: 10px;
   margin-bottom: 10px;

   img {
      height: 35px;
   }

   p {
      margin: 0 0 0 10px;
   }

   span {
      margin-left: auto;
   }

   .map-pin {
      height: 35px;
      width: 35px;
      min-width: 32.08px;
      margin: 0;
      text-align: center;
      color: white;
      padding-top: 2px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
   }

   b {
      margin-left: 10px;
   }

   .operational {
      background-image: url(${greenMapPin});
   }

   b.operational {
      color: #218748;
      background-image: none;
   }

   .maintenance {
      background-image: url(${grayMapPin});
   }

   b.maintenance {
      color: #595959;
      background-image: none;
   }

   .not-configured {
      background-image: url(${uspsMapPin});
   }

   b.not-configured {
      color: #336;
      background-image: none;
   }

   .not-operational {
      background-image: url(${redMapPin});
   }

   b.not-operational {
      color: #e71921;
      background-image: none;
   }

   .default-pin {
      background-image: url(${mapPin});
   }

   .upper-inner {
      margin-right: 0;
      background: transparent;
   }
`;

const MapListBlockUpperInner = styled.div`
   display: flex;
   flex-direction: column;
   margin-right: 5px;
   width: 100%;
`;

const MapListBlockLower = styled.div`
   display: flex;
   flex-direction: column;
   margin-left: 42.8px;
   margin-top: 3px;
   border-top: 2px solid #d3d3d3;
   padding-top: 10px;

   p {
      margin: 0;
   }
`;

const MapListBlockNameStatus = styled.div`
   display: flex;

   p {
      margin-right: 15px;
   }

   span {
      position: relative;
      right: 12px;
      font-size: 16px;
   }

   @media screen and (max-width: 1185px) {
      p {
         margin-right: 5px;
      }

      span {
         right: 0;
      }
   }
`;

const ServiceListContainer = styled.div`
   ul {
      columns: 2;
      column-gap: 15px;
      padding-left: 0;
      display: grid;
      width: fit-content;
      grid-template-columns: auto auto;
      list-style-position: inside;
   }

   @media screen and (max-width: 960px) {
      ul {
         grid-template-columns: auto;
      }

      span {
         position: relative;
         right: 0;
      }
   }

   span {
      position: relative;
      right: 10px;
   }

   li.operational::marker {
      color: green;
   }

   li.maintenance::marker {
      color: #336;
   }

   li.not-configured::marker {
      color: #336;
   }

   li.not-operational::marker {
      color: green;
   }
`;

const MapListEmpty = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
   margin: auto;
   background-color: #f7f7f7;
   justify-content: center;

   h2 {
      color: #595959;
      margin: auto;
   }
`;

const ReserveButtonContainer = styled.div`
   display: flex;

   .reserve-tooltip {
      width: 20px;
      height: 20px;
   }
`;

const ReserveTooltip = () => {
   return (
      <>
         <h4>Reserve This Locker</h4>
         <p>
            In the case that a merchant does not provide the option to ship to a Smart Locker, you can personally
            reserve a Smart Locker, enabling you to ship and receive packages to this designated locker location.
         </p>
      </>
   );
};

const ServiceListHolderProps = {
   data: PropTypes.object,
};

const ServiceListHolder = props => {
   return (
      <ServiceListContainer>
         <ul>
            {props.data.serviceRedelivery ? (
               <li>
                  <span>Redelivery</span>
               </li>
            ) : null}
            {props.data.serviceDirectShip ? (
               <li>
                  <span>Direct-to-locker delivery</span>
               </li>
            ) : null}
            {props.data.servicePOBoxOverflow ? (
               <li>
                  <span>Oversize PO Box packages</span>
               </li>
            ) : null}
            {props.data.serviceLabelPrinting ? (
               <li>
                  <span>LabelPrinting</span>
               </li>
            ) : null}
            <li>
               <span>
                  Returns - <strong>Coming Soon</strong>
               </span>
            </li>
         </ul>
      </ServiceListContainer>
   );
};

ServiceListHolder.propTypes = ServiceListHolderProps;

export const MapListHolder = ({
   mapLocations,
   paginationTab,
   lockersPerPage,
   onShipHereClick,
   noCount,
   noStatus,
   enableSearch,
   setLockerListRaiseFunction,
   centerChangeRaiseFunction,
}) => {
   const api = new Api();
   api.setup();

   const searchFormRef = useRef();

   const [locationErrorState, setLocationErrorState] = useState(false);
   const [loadingData, setLoadingData] = useState(false);
   const radiusInput = 20;
   const [noResultsError, setNoResultsError] = useState(false);

   const handleLocationData = data => {
      if (data !== undefined) {
         if (data.response.data.sourceLatitude === 0 && data.response.data.lockers === null) {
            // Location does not exist.
            setLockerListRaiseFunction(data.response);
            setLoadingData(false);
         } else if (data.response.data.lockers === null) {
            // Valid location but no lockers exist in area.
            setLockerListRaiseFunction(data.response);
            setLoadingData(false);
            setNoResultsError(true);
         } else {
            // Search comes back and finds results.
            setLockerListRaiseFunction(data.response);
            setLoadingData(false);
            centerChangeRaiseFunction();
         }
      } else {
         setLoadingData(false);
      }
   };

   const getLocationsByZip = async (postalCode, radius) => {
      const result = await api.postGetCSVLocationsByZip(postalCode, radius);
      handleLocationData(result);
   };

   const getLocationsByCityState = async (cityName, stateName, radius) => {
      const result = await api.postGetCSVLocationsByCityState(cityName, stateName, radius);
      handleLocationData(result);
   };

   const handleShipHereClick = selectedLockerData => {
      window.sessionStorage.setItem("selectedReservationLocker", JSON.stringify(selectedLockerData));
      onShipHereClick();
   };

   const handleLockerSearch = () => {
      setNoResultsError(false);
      if (!locationErrorState) setLocationErrorState(true);
      setLoadingData(true);
      const postalCode = AddressChecker(formValues.location).zip;
      const cityName = AddressChecker(formValues.location).city;
      const stateName = AddressChecker(formValues.location).state;

      if (postalCode) {
         getLocationsByZip(postalCode, radiusInput);
      } else getLocationsByCityState(cityName, stateName, radiusInput);
   };

   const formSchema = z.object({
      location: z
         .string()
         .regex(/^\d{5}$|([A-Za-z]+(?: [A-Za-z]+)*)?, ([A-Za-z]{2})$/, {
            message: 'Invalid Format: Use either "New York, NY" for City, State or "20190" for ZIP Code',
         })
         .trim(),
   });

   const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
   } = useForm({
      mode: "all",
      resolver: zodResolver(formSchema),
   });

   const formValues = watch();

   function mapPinClassName(location) {
      if (location.lockerStatus === "Operational") {
         return "map-pin operational";
      } else if (location.lockerStatus === "Not Configured") {
         return "map-pin not-configured";
      } else if (location.lockerStatus === "Not Operational") {
         return "map-pin not-operational";
      } else if (location.lockerStatus === "In Maintenance" || "Coming Soon") {
         return "map-pin maintenance";
      } else {
         return "map-pin";
      }
   }

   function lockerStatusClassName(location) {
      if (location.lockerStatus === "Operational") {
         return "operational";
      } else if (location.lockerStatus === "Not Configured") {
         return "not-configured";
      } else if (location.lockerStatus === "Not Operational") {
         return "not-operational";
      } else if (location.lockerStatus === "Maintenance") {
         return "maintenance";
      } else {
         return null;
      }
   }

   const createBlock = () => {
      if (mapLocations?.data.lockers !== null) {
         return (
            <MapListContainer>
               {enableSearch && (
                  <SearchContainer>
                     <h2>Find a Smart Locker Near You</h2>
                     <form id="map-search" ref={searchFormRef} onSubmit={handleSubmit(handleLockerSearch)}>
                        <div className="location-input-inner">
                           <input
                              type="text"
                              placeholder="City and State, or ZIP Code™"
                              className="location-text-input"
                              id="location-text-input"
                              {...register("location")}
                           />
                           <img
                              src={searchIcon}
                              alt="locating crosshair"
                              className="locating-crosshair"
                              onClick={handleSubmit(handleLockerSearch)}
                              onKeyDown={handleSubmit(handleLockerSearch)}
                           />
                        </div>
                        <div style={{ color: "red", marginTop: "5px" }}>
                           {locationErrorState && errors.location?.message}
                        </div>
                        {noResultsError && (
                           <p style={{ color: "red" }}>
                              There do not appear to be any lockers in this area yet. See Locker Location for more
                              details.
                           </p>
                        )}
                     </form>
                  </SearchContainer>
               )}
               {loadingData && <Loading />}
               {!loadingData &&
                  mapLocations.data.lockers?.map((location, index) => {
                     if (index <= paginationTab * lockersPerPage - 1 && index >= (paginationTab - 1) * lockersPerPage) {
                        return (
                           <MapListBlock key={location.facilityID}>
                              <MapListBlockUpper>
                                 <p className={!noStatus ? mapPinClassName(location) : "map-pin default-pin"}>
                                    {!noCount && index + 1}
                                 </p>
                                 <MapListBlockUpperInner className="upper-inner">
                                    <b className={!noStatus ? lockerStatusClassName(location) : ""}>
                                       {toUpper(location.lockerStatus)}
                                    </b>
                                    <MapListBlockNameStatus>
                                       <p>{location.locationName}</p>
                                       <span
                                          style={{
                                             right: 0 + "px",
                                             fontWeight: 600,
                                             minWidth: "fit-content",
                                          }}
                                       >{`${Number(location.distance).toFixed(2)} mi`}</span>
                                    </MapListBlockNameStatus>
                                    <p>{`${location.locationAddress1} ${location.locationCity}, ${location.locationState} ${location.locationZIP5}`}</p>
                                 </MapListBlockUpperInner>
                              </MapListBlockUpper>
                              <MapListBlockLower>
                                 <b>Services offered:</b>
                                 <ServiceListHolder
                                    data={{
                                       serviceDirectShip: location.serviceDirectShip,
                                       serviceLabelPrinting: location.serviceLabelPrinting,
                                       servicePOBoxOverflow: location.servicePOBoxOverflow,
                                       servicePrepaidReturns: location.servicePrepaidReturns,
                                       serviceRedelivery: location.serviceRedelivery,
                                    }}
                                 />
                              </MapListBlockLower>
                              {process.env.REACT_APP_GUEST_RESERVATION === "true" && (
                                 <ReserveButtonContainer>
                                    <Button
                                       primary
                                       onClick={() =>
                                          handleShipHereClick({
                                             facilityId: location.facilityID,
                                             locationName: location.locationName,
                                             deliveryAddress: location.deliveryAddress1,
                                             deliveryAddress2: location.deliveryAddress2,
                                             deliveryCity: location.deliveryCity,
                                             deliveryState: location.deliveryState,
                                             deliveryPoint: location.deliveryPoint,
                                             deliveryZIP4: location.deliveryZIP4,
                                             deliveryZIP5: location.deliveryZIP5,
                                             locationAddress: location.locationAddress1,
                                             locationAddress2: location.locationAddress2,
                                             locationCity: location.locationCity,
                                             locationState: location.locationState,
                                             locationZIP4: location.locationZIP4,
                                             locationZIP5: location.locationZIP5,
                                          })
                                       }
                                       className="ship-here-button"
                                       lockerData={location}
                                    >
                                       Reserve this Locker
                                    </Button>
                                    <Tooltip text={<ReserveTooltip />} style={{ margin: "auto auto auto 10px" }}>
                                       <img src={infoTooltip} alt="Info Button" className="reserve-tooltip" />
                                    </Tooltip>
                                 </ReserveButtonContainer>
                              )}
                           </MapListBlock>
                        );
                     } else return <></>;
                  })}
            </MapListContainer>
         );
      } else {
         return (
            <MapListContainer>
               {enableSearch && (
                  <SearchContainer>
                     <h2>Find a Smart Locker Near You</h2>
                     <form id="map-search" ref={searchFormRef} onSubmit={handleSubmit(handleLockerSearch)}>
                        <div className="location-input-inner">
                           <input
                              type="text"
                              placeholder="City and State, or ZIP Code™"
                              className="location-text-input"
                              id="location-text-input"
                              {...register("location")}
                           />
                           <img
                              src={searchIcon}
                              alt="locating crosshair"
                              className="locating-crosshair"
                              onClick={handleSubmit(handleLockerSearch)}
                              onKeyDown={handleSubmit(handleLockerSearch)}
                           />
                        </div>
                        <div style={{ color: "red", marginTop: "5px" }}>
                           {locationErrorState && errors.location?.message}
                        </div>
                        {noResultsError && (
                           <p style={{ color: "red" }}>
                              There do not appear to be any lockers in this area yet. See Locker Location for more
                              details.
                           </p>
                        )}
                     </form>
                  </SearchContainer>
               )}
               <MapListEmpty>
                  <h2>No Lockers Found</h2>
               </MapListEmpty>
            </MapListContainer>
         );
      }
   };

   return createBlock();
};

export default MapListHolder;
