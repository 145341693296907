export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080";

export const AUTH_URL = process.env.REACT_APP_AUTH_URL
   ? process.env.REACT_APP_AUTH_URL
   : "https://dev-auth.uspsinnovation.tech";

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : "web_app";

export const SITE_URL = process.env.REACT_APP_SITE_URL
   ? process.env.REACT_APP_SITE_URL
   : "https://dev.informeddeliveryreminders.com";

export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID ? process.env.REACT_APP_GA_MEASUREMENT_ID : "";

export const MobileBreakpoint = "959.99px";

export const DesktopBreakpoint = "960px";
