import { useEffect } from "react";
import { useLocation } from "react-router";
import PropTypes from "prop-types";

const ScrollToTopProps = {
   children: PropTypes.node,
};

export const ScrollToTop = props => {
   const { children } = props;

   const location = useLocation();
   useEffect(() => {
      if (!location.hash) {
         window.scrollTo(0, 0);
      }
   }, [location]);

   return <>{children}</>;
};

ScrollToTop.propTypes = ScrollToTopProps;
