import USPSLanyard from "../../../static/img/USPS-Lanyard.png";
import USPSSocks from "../../../static/img/USPS-Socks.png";
import USPSLanyardBig from "../../../static/img/USPS-Lanyard-Big.jpg";
import USPSSocksBig from "../../../static/img/USPS-Socks-Big.jpg";

export const Items = [
   {
      id: 1111,
      source: USPSLanyard,
      sourceBig: USPSLanyardBig,
      alt: "USPS Smart Locker Lanyard",
      title: "USPS Smart Locker Lanyard",
      price: "FREE",
   },
   {
      id: 1112,
      source: USPSSocks,
      sourceBig: USPSSocksBig,
      alt: "USPS Smart Locker Socks",
      title: "USPS Smart Locker Socks",
      price: "FREE",
   },
];
