import styled from "styled-components";

export const InputContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: 60px;

   input {
      max-width: 530px;
      padding: 7px 10px;
   }

   .resend-code {
      color: #3573b1;
      text-decoration: underline;
      border: none;
      padding: 0;
      background: none;
      margin-right: 20px;
   }

   .resend-code:hover {
      text-decoration: none;
      cursor: pointer;
   }

   .resend-code:disabled {
      color: #dddddd;
   }

   .red-border-error {
      border-color: red;
   }
`;

export const ButtonContainer = styled.div`
   display: flex;

   .usps-primary-button {
      max-width: 170px;
      width: 100%;
      background: #333366;
      color: white;
      font-weight: bold;
      padding: 12px 10px;
      border: none;
      border-radius: 3px;
   }

   .usps-primary-button:hover {
      background: #d3d3d3;
      color: #343366;
   }

   .usps-secondary-button {
      max-width: 170px;
      width: 100%;
      border: 1px solid #333366;
      border-radius: 3px;
      background: white;
      color: #333366;
      font-weight: bold;
      padding: 12px 10px;
   }

   .usps-secondary-button:hover {
      background: rgb(237, 237, 237);
   }

   .submit-button-active {
      padding: 12px 10px;
      border: none;
      border-radius: 3px;
      min-width: 100px;
      width: 100%;
      max-width: 170px;
      background: #343366;
      color: white;
      font-weight: bold;
   }

   .submit-button-active:hover {
      background: #d3d3d3;
      color: #343366;
   }
`;
