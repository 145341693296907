import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import LogInternalLink from "../../common/components/LogInternalLink";
import { Modal } from "../../common/components/Modal";
import {
   Container,
   ContentContainer,
   HeroContainer,
   HeroContentContainer,
   HeroImage,
   HeroInnerContainer,
} from "../../common/components/WebLayoutComponents";
import { Api } from "../../services/Api";
import mobileHero from "../../static/img/locker-open-with-boxes-mobile-min.jpg";

const UpcomingContainer = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   width: 100%;
   text-align: left;
   gap: 20px 0;
   margin-top: 20px;

   @media screen and (max-width: 960px) {
      margin: 0 auto;
   }
`;

const ShowContainer = styled.div`
   width: 50%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   word-break: break-all;
   white-space: pre-wrap;
   white-space: -moz-pre-wrap;
   white-space: -o-pre-wrap;

   .show-link {
      text-decoration: underline;
   }

   .show-link:hover {
      color: #336;
      text-decoration: none;
   }

   p {
      margin: 0;
   }

   @media screen and (max-width: 960px) {
      width: 100%;
      align-items: center;

      .show-link {
         text-align: center;
      }
   }
`;

export const ContestClosed = () => {
   const api = new Api();
   api.setup();

   const [shows, setShows] = useState();
   const [getShowsError, setGetShowsError] = useState(false);

   const getAllTradeShows = useCallback(async () => {
      return await api.getAllTradeShows();
   }, []);

   useEffect(() => {
      (async () => {
         setShows(await getAllTradeShows());
      })();
   }, [getAllTradeShows]);

   useEffect(() => {
      if (!shows) {
         setGetShowsError(true);
      }
   }, [shows]);

   return (
      <Container>
         <Modal modalActive={getShowsError} closeFunction={() => setGetShowsError(false)}>
            <h1>Hmm...</h1>
            <p>There was an error while fetching trade shows, please try again later.</p>
         </Modal>
         <HeroContainer className="landing">
            <HeroInnerContainer>
               <HeroImage
                  src={mobileHero}
                  alt="USPS Smart Locker with dozens of package compartments; a screen, scanner, and keypad; and an open compartment with Priotiry Mail Packages in front of it."
               />
               <HeroContentContainer>
                  <h1>Sorry we missed you! You can catch us at upcoming conventions!</h1>
                  <p className="hero-width">
                     Please refer to the following section for a list of upcoming trade shows in your vicinity.
                     Additionally, ensure to see if lockers are available in your area.
                  </p>
                  <LogInternalLink primary outline="white" to="/">
                     Learn More
                  </LogInternalLink>
               </HeroContentContainer>
            </HeroInnerContainer>
         </HeroContainer>
         <ContentContainer className="medium-mb">
            <h2>Upcoming Shows</h2>
            {shows && (
               <UpcomingContainer>
                  {shows.data?.map(show => (
                     <ShowContainer key={show["show-id"]}>
                        <strong>{show["show-name"]}</strong>
                        <a href={show["url"]} className="show-link">
                           {show["url"]}
                        </a>
                        <p>
                           {show["start-date"].substring(5, 7) +
                              "/" +
                              show["start-date"].substring(8) +
                              "/" +
                              show["start-date"].substring(0, 4) +
                              " - " +
                              show["end-date"].substring(5, 7) +
                              "/" +
                              show["end-date"].substring(8) +
                              "/" +
                              show["end-date"].substring(0, 4)}
                        </p>
                        <p>{show["city"] + ", " + show["state"]}</p>
                     </ShowContainer>
                  ))}
               </UpcomingContainer>
            )}
            {!shows && <h4>Couldn't find any upcoming shows...</h4>}
         </ContentContainer>
      </Container>
   );
};

export default ContestClosed;
