import { Container } from "react-bootstrap";
import styled from "styled-components";
import { LOCALIZATION_COMMON } from "../../config/localization";

const Heading = styled.div`
   padding: 150px 0;
   text-align: center;
`;

export const PageNotFound = props => {
   return (
      <div>
         <Container>
            <Heading>
               <h2>{LOCALIZATION_COMMON.PAGE_NOT_FOUND}</h2>
            </Heading>
         </Container>
      </div>
   );
};
