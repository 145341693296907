import styled from "styled-components";
import { Button, Theme } from "@informed-innovations/components";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CSVGoogleMaps from "../../common/components/CSVGoogleMaps";
import { Api } from "../../services/Api";
import { Modal } from "../../common/components/Modal";
import ListMobileIcon from "../../static/img/list-mobile-icon.PNG";
import MapMobileIcon from "../../static/img/map-mobile-icon.PNG";
import USPSLogo from "../../static/img/LOGO-USPS.png";
import Geocode from "react-geocode";
import {
   Container,
   ContentContainer,
   GreyContentContainer,
   GreyContentContainerInner,
} from "../../common/components/WebLayoutComponents";
import IconDelete from "../../static/img/Icon-Delete.svg";
import { Pagination } from "@mui/material";
import { AddressChecker } from "../../common/utils";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import LockerRedirectMapListHolder from "./LockerRedirectListHolder";

const SearchHeroContainer = styled.div`
   display: flex;
   gap: 30px;

   h2,
   p {
      text-align: left;
   }

   h2 {
      margin: 0;
   }

   p {
      margin-bottom: 30px;
   }
   
   img {
      height: 100%;
      margin-top: 15px;
      object-fit: contain;
   }
      
   @media screen and (max-width: 960px) {
      flex-direction: column;
   }
`;

const GreySearchContainer = styled(GreyContentContainerInner)`
   margin: 0 auto;
   @media screen and (max-width: 1185px) {
      padding: 40px 0;
   }
`;

const MapContainer = styled.div`
   margin-top: 20px;
   display: flex;
`;

const MobileRow = styled.div`
   display: flex;
   width: 100%;
   justify-content: space-around;

   .active {
      padding-bottom: 2px;
      border-bottom: 4px solid ${Theme.colors.red};
   }
`;

const MobileMapTab = styled.div`
   display: flex;
   margin: 40px 20px;
   cursor: pointer;

   img {
      height: 20px;
      padding-right: 10px;
   }
`;

const MapHeader = styled.div`
   display: flex;

   @media screen and (max-width: 960px) {
      flex-direction: column;
   }
`;

const LockerRedirectProps = {
   lockerList: PropTypes.object,
};

export const LockerRedirect = props => {
   const formSubmitRef = useRef();
   const [searchParams] = useSearchParams();

   const origin = searchParams.get("origin");
   const radius = searchParams.get("radius") ?? 20;
   const zip5 = searchParams.get("zip5") ?? "";
   const [initialSearchDone, setInitialSearchDone] = useState(false);
   const [loadingFromLink, setLoadingFromLink] = useState(false);
   const [json, setJson] = useState();
   const [mapShown, setMapShown] = useState(false);

   const [mapCenter, setMapCenter] = useState();
   const [centerChange, setCenterChange] = useState(false);
   const [submitError, setSubmitError] = useState(false);
   const [invalidLocationError, setInvalidLocationError] = useState(false);

   const [screenSize, setScreenSize] = useState(getCurrentDimension());
   const [mobileMapView, setMobileMapView] = useState("list");

   const [paginationTab, setPaginationTab] = useState(1);
   const lockersPerPage = 10;
   const [numberOfLockers, setNumberOfLockers] = useState();

   function getCurrentDimension() {
      return {
         width: window.innerWidth,
         height: window.innerHeight,
      };
   }

   useEffect(() => {
      const updateDimension = () => {
         setScreenSize(getCurrentDimension());
      };
      window.addEventListener("resize", updateDimension);

      return () => {
         window.removeEventListener("resize", updateDimension);
      };
   }, [screenSize]);

   const changeMapCenter = useCallback(() => {
      if (json.data.lockers !== null) {
         const Lati = parseFloat(Number(json.data.lockers[0].locationLatitude));
         const Long = parseFloat(Number(json.data.lockers[0].locationLongitude));
         setMapCenter({ lat: Lati, lng: Long });
      }
      setCenterChange(false);
      setPaginationTab(1);
      setNumberOfLockers(json.data.lockers.length);
   }, [json]);

   const handleLocationData = data => {
      if (data !== undefined) {
         if (data.response.data.sourceLatitude === 0 && data.response.data.lockers === null) {
            setJson(data.response);
            setInvalidLocationError(true);
         } else if (data.response.data.lockers === null) {
            setJson(data.response);
         } else {
            setJson(data.response);
            setMapShown(true);
            setCenterChange(true);
         }
      } else {
         setSubmitError(true);
      }
   };


   const getLocations = useCallback(() => {
      const api = new Api();
      api.setup();
      const postalCode = AddressChecker(zip5).zip;

      const getLocationsByZip = async (postalCode, radius) => {
         const result = await api.postGetCSVLocationsByZip(postalCode, radius);
         handleLocationData(result);
      };

      if (postalCode) {
         getLocationsByZip(zip5, radius);
      }

   }, [radius, zip5])

   useEffect(() => {
      if (origin && zip5 && !initialSearchDone) {
         setInitialSearchDone(true);
         getLocations();
      } else if (zip5 && !origin && !initialSearchDone) {
         setInitialSearchDone(true);
      }

   }, [getLocations, origin, zip5, radius, setInitialSearchDone, initialSearchDone])

   useEffect(() => {
      if (centerChange && json) {
         changeMapCenter();
      }
   }, [json, centerChange, changeMapCenter]);

   useEffect(() => {
      if (loadingFromLink) {
         formSubmitRef.current.click();
         document.getElementById("location-text-input").scrollIntoView({ behavior: "smooth" });
         setLoadingFromLink(false);
      }
   }, [loadingFromLink]);

   Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

   const clearSearch = () => {
      setMapShown(false);
   };

   const handlePaginationChange = (event, number) => {
      setPaginationTab(number);
   };

   const selectLockerLocation = (location) => {
      window.opener.postMessage(location, origin);
      window.close();
   };

   if (!zip5 || !origin) {
      return <>N/A</>;
   }

   return (
      <Container>
         <Helmet>
            <title>Smart Lockers Locations | USPS</title>
            <meta
               name="description"
               content="Search for free, secure, self-service USPS Smart Locker locations at select Post Offices across the U.S."
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@USPS" />
            <meta name="twitter:title" content="Smart Lockers Locations | USPS" />
            <meta
               name="twitter:description"
               content="Search for free, secure, self-service USPS Smart Locker locations at select Post Offices across the U.S."
            />
            <meta
               name="twitter:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />

            <meta property="og:url" content="https://www.uspssmartpackagelockers.com/" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Smart Lockers Locations | USPS" />
            <meta
               property="og:description"
               content="Search for free, secure, self-service USPS Smart Locker locations at select Post Offices across the U.S."
            />
            <meta
               property="og:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />
         </Helmet>
         <Modal modalActive={submitError} closeFunction={() => setSubmitError(false)}>
            <h1>Hmm...</h1>
            <p>Looks like something went wrong, please try again later.</p>
            <button onClick={() => setSubmitError(false)}>Close</button>
         </Modal>
         <Modal modalActive={invalidLocationError} closeFunction={() => setInvalidLocationError(false)}>
            <h1>Can't Find Location</h1>
            <p>
               Please check what was submitted and try again. Only include either "City, State" or "Zip" in search bar.
            </p>
            <button onClick={() => setInvalidLocationError(false)}>Close</button>
         </Modal>
         <GreyContentContainer>
            <GreySearchContainer>
               <SearchHeroContainer>
                  <img src={USPSLogo} alt="USPS Logo" />
                  <div>
                     <h2>Find USPS Smart Locker Locations</h2>
                     <p>
                        The U.S. Postal Service<sup>&reg;</sup> offers Smart Locker Locations at select Post Offices
                        <sup>&trade;</sup>.
                     </p></div>
               </SearchHeroContainer>
            </GreySearchContainer>
         </GreyContentContainer>
         <ContentContainer className="full" style={{ marginBottom: 40 + "px" }}>
            {/* If screen is smaller than 960 pixels show tabs to toggle between the map and list */}
            {json && mapShown && screenSize.width <= 960 && (
               <MobileRow>
                  <MobileMapTab
                     className={mobileMapView === "list" ? "active" : ""}
                     onClick={() => setMobileMapView("list")}
                  >
                     <img src={ListMobileIcon} alt="locker lookup list icon" />
                     <b>List</b>
                  </MobileMapTab>
                  <MobileMapTab
                     className={mobileMapView === "map" ? "active" : ""}
                     onClick={() => setMobileMapView("map")}
                  >
                     <img src={MapMobileIcon} alt="locker lookup map icon" />
                     <b>Map</b>
                  </MobileMapTab>
               </MobileRow>
            )}
            {json && mapCenter && mapShown && screenSize.width > 960 && (
               <>
                  <MapHeader>
                     {json.data.lockers !== null && (
                        <>
                           <Pagination
                              count={Math.ceil(numberOfLockers / lockersPerPage)}
                              page={paginationTab}
                              onChange={handlePaginationChange}
                              showFirstButton
                              showLastButton
                              style={{ margin: "auto 0" }}
                           />
                           <p style={{ margin: "auto auto auto 20px" }}>
                              Showing Results{" "}
                              {(paginationTab - 1) * 10 +
                                 1 +
                                 " - " +
                                 (numberOfLockers > paginationTab * lockersPerPage
                                    ? paginationTab * lockersPerPage
                                    : numberOfLockers)}{" "}
                              of {numberOfLockers}
                           </p>
                        </>
                     )}
                     {json.data.lockers === null && (
                        <>
                           <Pagination count={0} showFirstButton showLastButton disabled style={{ margin: "auto 0" }} />
                           <p style={{ margin: "auto auto auto 20px" }}>No Results Found</p>
                        </>
                     )}
                  </MapHeader>
                  <MapContainer>
                     <LockerRedirectMapListHolder
                        id="map-list"
                        mapLocations={json}
                        paginationTab={paginationTab}
                        lockersPerPage={lockersPerPage}
                        onShipHereClick={selectLockerLocation}
                     />
                     <CSVGoogleMaps id="google-map" mapLocations={json} mapCenter={mapCenter} zoom={10} />
                  </MapContainer>
               </>
            )}
            {json && mapCenter && mapShown && screenSize.width <= 960 && mobileMapView === "list" && (
               <>
                  <MapHeader>
                     {json.data.lockers !== null && (
                        <>
                           <Pagination
                              count={Math.ceil(numberOfLockers / lockersPerPage)}
                              page={paginationTab}
                              onChange={handlePaginationChange}
                              showFirstButton
                              showLastButton
                              style={{ margin: "auto auto 10px auto" }}
                           />
                           <p style={{ margin: "auto auto 10px auto" }}>
                              Showing Results{" "}
                              {(paginationTab - 1) * lockersPerPage +
                                 1 +
                                 " - " +
                                 (numberOfLockers > paginationTab * lockersPerPage
                                    ? paginationTab * lockersPerPage
                                    : numberOfLockers)}{" "}
                              of {numberOfLockers}
                           </p>
                        </>
                     )}
                     {json.data.lockers === null && (
                        <>
                           <Pagination
                              count={0}
                              showFirstButton
                              showLastButton
                              disabled
                              style={{ margin: "auto auto 10px auto" }}
                           />
                           <p style={{ margin: "auto auto 10px auto" }}>No Results Found</p>
                        </>
                     )}
                  </MapHeader>
                  <MapContainer>
                     <LockerRedirectMapListHolder
                        id="map-list"
                        mapLocations={json}
                        paginationTab={paginationTab}
                        lockersPerPage={lockersPerPage}
                        onShipHereClick={selectLockerLocation}
                     />
                  </MapContainer>
               </>
            )}
            {json && mapCenter && mapShown && screenSize.width <= 960 && mobileMapView === "map" && (
               <>
                  <MapHeader>
                     <Button secondary onClick={clearSearch} style={{ width: "100%" }}>
                        <img
                           src={IconDelete}
                           alt="x-icon"
                           style={{ width: 12 + "px", height: 12 + "px", margin: "auto 10px 2px auto" }}
                        />{" "}
                        Clear Search
                     </Button>
                  </MapHeader>
                  <MapContainer>
                     <CSVGoogleMaps id="google-map" mobile mapLocations={json} mapCenter={mapCenter} zoom={10} />
                  </MapContainer>
               </>
            )}
         </ContentContainer>
      </Container>
   );
};

LockerRedirect.propTypes = LockerRedirectProps;

export default LockerRedirect;
