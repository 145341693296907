import { useEffect, useState } from "react";
import { Navigate } from "react-router";
import styled from "styled-components";
import { Loading } from "../../common/components/Loading";
import LogExternalLink from "../../common/components/LogExternalLink";
import LogInternalLink from "../../common/components/LogInternalLink";
import { Modal } from "../../common/components/Modal";
import {
   Col,
   Container,
   ContentContainer,
   FeatureContainer,
   HeroContainer,
   HeroContentContainer,
   HeroImage,
   HeroInnerContainer,
   Row,
} from "../../common/components/WebLayoutComponents";
import { NPF_TC_URL } from "../../config/pages";
import { Api } from "../../services/Api";
import mobileHero from "../../static/img/locker-open-with-boxes-mobile-min.jpg";
import RepeatEntry from "./RepeatEntry";
import ThankYou from "./ThankYou";
import PropTypes from "prop-types";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const FormContainer = styled.div`
   display: flex;
   max-width: 43%;

   @media screen and (max-width: 960px) {
      flex-direction: column;
      max-width: 100%;
   }
`;

const FormInputContainer = styled.div`
   display: flex;

   @media screen and (max-width: 960px) {
      flex-direction: column;
      gap: 20px;
   }
`;

const TextBoxHolder = styled.div`
   display: flex;
   flex-direction: column;
   width: 50%;
   min-width: fit-content;

   @media screen and (max-width: 960px) {
      padding: 0;
      width: 100%;
      min-width: 100%;

      .form-text-input {
         width: 100%;
      }
   }

   p {
      margin: 0;
   }

   .form-text-input {
      padding: 12px 10px;
      min-width: 100%;
      border-radius: 5px;
      border-width: 1px;
   }

   .form-input-invalid {
      color: red;
   }

   .form-input-valid {
      display: none;
      height: 18px;
   }

   .submit-button {
      padding: 12px 10px;
      border: none;
      border-radius: 3px;
      min-width: 300px;
      max-width: 300px;
      margin: auto;
      background: white;
      color: #333366;
      font-weight: bold;
   }

   .submit-button:disabled {
      background: #ededed;
      color: #d3d3d3;
   }

   .submit-button:hover {
      background: #d3d3d3;
      color: #343366;
   }

   .submit-button:hover:disabled {
      background: #ededed;
      color: #d3d3d3;
   }
`;

const FormTextContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin: 20px auto;
   max-width: 600px;
   text-align: justify;

   @media screen and (max-width: 960px) {
      max-width: 350px;
      text-align: center;
   }

   .contest-checkbox {
      width: 26px;
      height: 26px;
      margin-right: 10px;
   }

   a {
      color: lightgrey;
      text-decoration: underline;
   }

   a:hover {
      text-decoration: none;
   }

   NavLink {
      border: none;
   }

   p {
      margin: 0 auto;
   }

   p:not(:last-child) {
      margin-bottom: 20px;
   }
`;

const CheckboxContainer = styled.div`
   display: flex;
`;

const ContestPageProps = {
   tradeShow: PropTypes.array,
};

export const ContestPage = props => {
   const [termsCheckbox, setTermsCheckbox] = useState(false);
   const [submitDisabled, setSubmitDisabled] = useState(false);
   const [submitError, setSubmitError] = useState(false);
   const [repeatEntry, setRepeatEntry] = useState(false);
   const [formValid, setFormValid] = useState(false);
   const [repeatFormValid, setRepeatFormValid] = useState(false);
   const [uuid, setUUID] = useState();

   const [showId, setShowId] = useState(localStorage.getItem("show_id"));
   const pathNameUUID = window.location.pathname.substring(14);

   const formSchema = z
      .object({
         email: z
            .string()
            .min(1, { message: "Email is required." })
            .email({ message: "Must be a valid email." })
            .trim(),
         confirmEmail: z
            .string()
            .min(1, { message: "Email Confirmation is required." })
            .email({ message: "Must be a valid email." })
            .trim(),
      })
      .refine(data => data.email === data.confirmEmail, { message: "Passwords do not match.", path: ["confirmEmail"] });

   const {
      register,
      handleSubmit,
      watch,
      formState: { errors, isValid },
   } = useForm({
      mode: "all",
      resolver: zodResolver(formSchema),
   });

   const formValues = watch();

   useEffect(() => {
      setShowId(localStorage.getItem("show_id"));
   }, [showId]);

   const api = new Api();
   api.setup();

   const resetState = () => {
      setSubmitDisabled(false);
      document.getElementById("contest-page-form").reset();
   };

   const emailPost = async (email, showId) => {
      return await api.postContestPageEmail(email, showId);
   };

   const handleFormSubmit = e => {
      e.preventDefault();
      setSubmitDisabled(true);

      emailPost(formValues.email, props.tradeShow[0]["show-id"])
         .then(resp => {
            if (resp.data.status === 5) {
               setRepeatFormValid(true);
               setUUID(resp.data.uuid);
               resetState();
            } else if (resp.data.status === 0) {
               setFormValid(true);
               setUUID(resp.data.uuid);
               resetState();
            } else {
               setSubmitError(true);
               setSubmitDisabled(false);
            }
         })
         .catch(error => {
            console.log(error);
            setSubmitError(true);
            setSubmitDisabled(false);
         });
   };

   function returnMainContestPage() {
      if (props.tradeShow[0]["contest-flag"]) {
         return (
            <Container>
               <Modal modalActive={submitError} closeFunction={() => setSubmitError(false)}>
                  <h1>Hmm...</h1>
                  <p>Looks like something went wrong, please try again.</p>
                  <button onClick={() => setSubmitError(false)}>Close</button>
               </Modal>
               <Modal modalActive={repeatEntry} closeFunction={() => setRepeatEntry(false)}>
                  <h1>Already Submitted</h1>
                  <p>This contest only allows one entry per email.</p>
                  <button onClick={() => setRepeatEntry(false)}>Close</button>
               </Modal>
               <HeroContainer className="landing">
                  <HeroInnerContainer>
                     <HeroImage
                        src={mobileHero}
                        alt="USPS Smart Locker with dozens of package compartments; a screen, scanner, and keypad; and an open compartment with Priotiry Mail Packages in front of it."
                     />
                     <HeroContentContainer>
                        <h1>{props.tradeShow[0]["show-name"]} Contest Entry Form</h1>
                        <FormContainer>
                           <form id="contest-page-form" onSubmit={handleSubmit(handleFormSubmit)}>
                              <FormInputContainer>
                                 <TextBoxHolder style={{ marginRight: "20px" }}>
                                    <p>*Enter Business Email Address</p>
                                    <input
                                       className="form-text-input"
                                       {...register("email")}
                                       placeholder="Enter Business Email Address"
                                    />
                                    <p className="form-input-invalid">{errors.email?.message}</p>
                                 </TextBoxHolder>
                                 <TextBoxHolder>
                                    <p>*Confirm Business Email Address</p>
                                    <input
                                       className="form-text-input"
                                       {...register("confirmEmail")}
                                       placeholder="Confirm Business Email Address"
                                    />
                                    <p className="form-input-invalid">{errors.confirmEmail?.message}</p>
                                    {/* <p
                                       className={
                                          errors.confirmEmail?.message || formValues.email !== formValues.confirmEmail
                                             ? "form-input-invalid"
                                             : "form-input-valid"
                                       }
                                    >
                                       Email does not match or is invalid
                                    </p> */}
                                 </TextBoxHolder>
                              </FormInputContainer>
                              <Col>
                                 <FormTextContainer>
                                    <p>
                                       <strong>Privacy Notice: </strong>
                                       For information regarding our privacy policies, visit{" "}
                                       <LogExternalLink href="https://about.usps.com/who/legal/privacy-policy/full-privacy-policy.htm">
                                          usps.com/privacypolicy
                                       </LogExternalLink>
                                       .
                                    </p>
                                    <CheckboxContainer>
                                       <input
                                          type="checkbox"
                                          className="contest-checkbox"
                                          onChange={() => setTermsCheckbox(!termsCheckbox)}
                                       />
                                       <p>
                                          I agree with the{" "}
                                          <LogInternalLink alternate to={NPF_TC_URL} target="_blank">
                                             terms and conditions
                                          </LogInternalLink>{" "}
                                          of the {props.tradeShow[0]["show-name"]} Smart Locker contest and to receiving
                                          an email with a QR code to see if I am a winner.
                                       </p>
                                    </CheckboxContainer>
                                 </FormTextContainer>
                                 <TextBoxHolder>
                                    <input
                                       className="submit-button"
                                       type="submit"
                                       value={submitDisabled ? "Please Wait..." : "Submit"}
                                       disabled={
                                          !isValid || formValues.email !== formValues.confirmEmail || !termsCheckbox
                                       }
                                    />
                                 </TextBoxHolder>
                              </Col>
                           </form>
                        </FormContainer>
                     </HeroContentContainer>
                  </HeroInnerContainer>
               </HeroContainer>
               <ContentContainer className="medium-pb">
                  <Row>
                     <Col>
                        <FeatureContainer>
                           <h2>How it Works</h2>
                           <p>
                              The U.S. Postal Service (USPS) is unveiling its Smart Lockers to cities across the U.S. to
                              provide customers with a safe, convenient, and secure alternative option for receiving
                              packages.
                           </p>

                           <p>
                              USPS<sup>&reg;</sup> Smart Lockers allow customers to securely receive valuable packages
                              without needing to be home, using a secure QR code for access at any time.
                           </p>
                        </FeatureContainer>
                     </Col>
                     <Col>
                        <FeatureContainer>
                           <h2>Benefits</h2>
                           <ul>
                              <li>
                                 <strong>Convenience</strong>: USPS Smart Lockers enable you to receive packages at any
                                 time, without the hassle of needing to be home for delivery. Easily retrieve packages
                                 from the locker at your own convenience, 24/7.
                              </li>
                              <li>
                                 <strong>Security</strong>: USPS Smart Lockers provide secure, theft-proof delivery of
                                 packages via a QR code that only the intended package recipient can access.
                              </li>
                              <li>
                                 <strong>Reliability</strong>: Enjoy peace of mind with convenient and risk-free
                                 delivery of your USPS packages, eliminating the need for rescheduling and avoiding any
                                 missed delivery risks.
                              </li>
                              <li>
                                 <strong>Stay Informed</strong>: Track your packages by signing up for{" "}
                                 <LogExternalLink href="https://informeddelivery.usps.com">
                                    Informed Delivery<sup>&reg;</sup>
                                 </LogExternalLink>{" "}
                                 feature!
                              </li>
                           </ul>
                        </FeatureContainer>
                     </Col>
                  </Row>
               </ContentContainer>
            </Container>
         );
      } else return <Navigate replace to={"/"} />;
   }

   if (pathNameUUID) {
      return <RepeatEntry uuid={pathNameUUID} />;
   } else if (formValid && uuid) {
      return <ThankYou uuid={uuid} />;
   } else if (repeatFormValid && uuid) {
      return <RepeatEntry uuid={uuid} />;
   } else if (formValid || repeatFormValid) {
      return <Loading />;
   } else {
      return returnMainContestPage();
   }
};

ContestPage.propTypes = ContestPageProps;

export default ContestPage;
