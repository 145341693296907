import styled from "styled-components";
import chevron from "../../static/img/chevron.PNG";
import { useState } from "react";
import PropTypes from "prop-types";

const AccordionContainer = styled.div`
   display: flex;
   flex-direction: column;
   border-bottom: 2px solid grey;
   padding-bottom: 20px;
   margin-bottom: 20px;

   @media screen and (max-width: 960px) {
      width: auto;
      min-width: 310px;
   }

   .answer-shown {
      max-height: 9000px;
      transition: max-height 300ms ease-in-out;
      overflow: hidden;
   }

   .answer-hidden {
      max-height: 0;
      overflow: hidden;
      transition: max-height 300ms ease-in-out;
   }
`;

const AccordionTitle = styled.div`
   display: flex;
   cursor: pointer;

   h4 {
      color: #336;
      margin: 0 10px 0 0;
   }

   img {
      height: 13px;
      margin: auto 10px auto auto;
   }

   .chevron-up {
      rotate: 180deg;
      transition: rotate 200ms ease-in-out;
   }

   .chevron-down {
      transition: rotate 200ms ease-in-out;
      rotate: 0deg;
   }
`;

const AccordionContent = styled.div`
   display: flex;
   flex-direction: column;

   p {
      margin: 20px 0 0 0;
   }

   a:link,
   a:visited,
   a:focus {
      text-decoration: underline;
      font-weight: bold;
   }

   a:hover {
      text-decoration: none;
   }
`;

const AccordionProps = {
   style: PropTypes.object,
   titleStyle: PropTypes.object,
   title: PropTypes.string,
   children: PropTypes.node,
};

export const Accordion = props => {
   const [open, setOpen] = useState(false);

   const handleAccordionClick = () => {
      setOpen(!open);
   };

   return (
      <AccordionContainer style={props.style}>
         <AccordionTitle onClick={handleAccordionClick} style={props.titleStyle}>
            <props.titleType>{props.title}</props.titleType>
            <img src={chevron} alt="Dropdown Chevron" className={open ? "chevron-up" : "chevron-down"} />
         </AccordionTitle>
         <AccordionContent className={open ? "answer-shown" : "answer-hidden"}>{props.children}</AccordionContent>
      </AccordionContainer>
   );
};

Accordion.propTypes = AccordionProps;

export default Accordion;
