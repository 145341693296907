import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Theme } from "@informed-innovations/components";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router";
import styled from "styled-components";
import { z } from "zod";
import CSVGoogleMaps from "../../common/components/CSVGoogleMaps";
import NewMapListHolder from "../../common/components/CSVMapListHolder";
import { Modal } from "../../common/components/Modal";
import { Container, ContentContainer, FlexColumn, FlexRow } from "../../common/components/WebLayoutComponents";
import { MobileBreakpoint } from "../../config/constants";
import { NPF_URL } from "../../config/pages";
import { Api } from "../../services/Api";
import ListMobileIcon from "../../static/img/list-mobile-icon.PNG";
import MapMobileIcon from "../../static/img/map-mobile-icon.PNG";

const NPFCartPageProps = {
   tradeShow: PropTypes.array,
};

const PageContainer = styled(ContentContainer)`
   max-width: 1365px;

   .submit-button-active {
      border: none;
      background: #343366;
      color: white;
      cursor: pointer;
   }

   .submit-button-active:hover {
      background: #d3d3d3;
      color: #343366;
   }

   .submit-button {
      border: none;
      background: #d3d3d3;
      color: #343366;
      cursor: auto;
   }
`;

const CartContainer = styled.div`
   background-color: #f7f7f7;
   padding: 20px;
   width: clamp(200px, 374px, 100%);
   display: flex;
   flex-direction: column;
   margin-left: auto;

   @media screen and (max-width: ${MobileBreakpoint}) {
      margin-bottom: 30px;
      margin-left: 0;
   }
`;

const FormContainer = styled.div`
   display: flex;
   margin-top: -30px;

   form {
      width: 100%;
   }

   @media screen and (max-width: ${MobileBreakpoint}) {
      margin-top: 0;
   }
`;

const InputContainer = styled.div`
   display: flex;
   flex-direction: column;

   input {
      width: 100%;
      margin-bottom: 30px;
   }

   .form-input {
      max-width: 290px;
      width: clamp(100px, 290px, 290px);
      margin-bottom: 0;
   }

   .error-message {
      color: red;
      margin-bottom: 20px;
   }

   @media screen and (max-width: ${MobileBreakpoint}) {
      width: 100%;

      .form-input {
         width: clamp(100px, 100%, 100%);
         max-width: 100%;
      }
   }
`;

const OrderContainer = styled(FlexRow)`
   margin-top: 26px;
`;

const OrderListContainer = styled(FlexColumn)`
   margin-right: 30px;
   max-width: 930px;
   width: 100%;
`;

const OrderGrid = styled.div`
   display: grid;
   grid-template-columns: 65% 1fr 1fr 1fr;

   .grid-header {
      margin-top: auto;
      margin-bottom: 1rem;
   }

   .cart-title {
      font-size: 36px;
   }

   .quantity {
      width: 64px;
      height: 38px;
   }
`;

const MapContainer = styled.div`
   margin-top: 20px;
   display: flex;
`;

const MobileRow = styled.div`
   display: flex;
   width: 100%;
   justify-content: space-around;

   .active {
      padding-bottom: 2px;
      border-bottom: 4px solid ${Theme.colors.red};
   }
`;

const MobileMapTab = styled.div`
   display: flex;
   margin: 40px 20px;
   cursor: pointer;

   img {
      height: 20px;
      padding-right: 10px;
   }
`;

const NPFCartPage = props => {
   const navigate = useNavigate();
   const api = new Api();
   api.setup();

   const initialGetAllLockers = async () => {
      const response = await api.postGetCSVLocationsByCityState("Lebanon", "Kansas", 2000);
      setMapLocationsJson(response.response);
   };

   useEffect(() => {
      if (!sessionStorage.getItem("npf-item")) navigate(NPF_URL);
      else setCheckoutItem(JSON.parse(sessionStorage.getItem("npf-item")));
   }, [navigate]);

   useEffect(() => {
      initialGetAllLockers();
      setMapListJson({
         data: {
            lockers: [
               {
                  lockerStatus: "Operational",
                  locationName: "NPF 2024 BOOTH #923",
                  distance: 0,
                  locationAddress1: "100 S CAPITOL AVE",
                  locationCity: "INDIANAPOLIS",
                  locationState: "IN",
                  locationZIP5: "46225",
                  serviceDirectShip: true,
                  serviceLabelPrinting: true,
                  servicePOBoxOverflow: true,
                  servicePrepaidReturns: true,
                  serviceRedelivery: true,
               },
            ],
         },
      });
   }, []);

   const [mapLocationsJson, setMapLocationsJson] = useState();
   const [mapListJson, setMapListJson] = useState();
   const [mapCenter, setMapCenter] = useState({ lat: 39.82, lng: -98.57 });
   const [mobileMapView, setMobileMapView] = useState("map");
   const [checkoutItem, setCheckoutItem] = useState();
   const [submitError, setSubmitError] = useState(false);
   const [repeatEntry, setRepeatEntry] = useState(false);
   const [screenSize, setScreenSize] = useState(getCurrentDimension());
   const [zoom, setZoom] = useState(4);
   const [mobileZoom, setMobileZoom] = useState(3);
   const [centerChange, setCenterChange] = useState(false);
   const [showCount, setShowCount] = useState(false);

   const changeMapCenter = useCallback(() => {
      if (mapLocationsJson?.data.lockers !== null) {
         const Lati = parseFloat(Number(mapLocationsJson?.data.lockers[0].locationLatitude));
         const Long = parseFloat(Number(mapLocationsJson?.data.lockers[0].locationLongitude));
         setMapCenter({ lat: Lati, lng: Long });
         setPageZoom(10);
      }
      setCenterChange(false);
      setShowCount(true);
   }, [mapLocationsJson]);

   useEffect(() => {
      if (centerChange && mapLocationsJson) {
         changeMapCenter();
      }
   }, [mapLocationsJson, centerChange, changeMapCenter]);

   const setPageZoom = number => {
      setZoom(number);
      setMobileZoom(number);
   };

   const mapListSet = data => {
      setMapListJson(data);
      setMapLocationsJson(data);
   };

   const mapCenterChange = () => {
      setCenterChange(true);
   };

   function getCurrentDimension() {
      return {
         width: window.innerWidth,
         height: window.innerHeight,
      };
   }

   useEffect(() => {
      const updateDimension = () => {
         setScreenSize(getCurrentDimension());
      };
      window.addEventListener("resize", updateDimension);

      return () => {
         window.removeEventListener("resize", updateDimension);
      };
   }, [screenSize]);

   const formSchema = z.object({
      email: z
         .string()
         .min(1, { message: "Email is required." })
         .email({ message: "Must be a valid email." })
         .trim(),
   });

   const {
      register,
      handleSubmit,
      watch,
      formState: { errors, isValid },
   } = useForm({
      mode: "all",
      resolver: zodResolver(formSchema),
   });

   const watchEmail = watch("email");

   const emailPost = async (email, showId) => {
      return await api.postContestPageEmail(email, showId);
   };

   const handleOrderClick = () => {
      sessionStorage.setItem("npf-email", watchEmail);
      emailPost(watchEmail, props.tradeShow[0]["show-id"])
         .then(resp => {
            if (resp.data.status === 5) {
               // goes to thank you page if email has already been submitted.
               sessionStorage.setItem("npf-already-submitted", true);
               sessionStorage.setItem("npf-number", resp?.data.uuid);
               navigate("/npf2024/thank-you");
            } else if (resp.data.status === 0) {
               // goes to default thank you page
               sessionStorage.removeItem("npf-already-submitted");
               sessionStorage.setItem("npf-number", resp?.data.uuid);
               navigate("/npf2024/thank-you");
            } else {
               setSubmitError(true);
            }
         })
         .catch(error => {
            console.log(error);
            setSubmitError(true);
         });
   };

   if (props.tradeShow[0]["contest-flag"]) {
      return (
         <Container>
            <Modal modalActive={submitError} closeFunction={() => setSubmitError(false)}>
               <h1>Hmm...</h1>
               <p>Looks like something went wrong, please try again.</p>
               <button onClick={() => setSubmitError(false)}>Close</button>
            </Modal>
            <Modal modalActive={repeatEntry} closeFunction={() => setRepeatEntry(false)}>
               <h1>Already Submitted</h1>
               <p>This contest only allows one entry per email.</p>
               <button onClick={() => setRepeatEntry(false)}>Close</button>
            </Modal>
            <PageContainer className="medium-pb full">
               {screenSize.width > Number(MobileBreakpoint.substring(0, -2)) && (
                  <OrderContainer className="desktop-only-flex">
                     <OrderListContainer>
                        <OrderGrid>
                           <p className="cart-title">Shopping Cart</p>
                           <h4 className="grid-header">
                              <b>Price</b>
                           </h4>
                           <h4 className="grid-header">
                              <b>Qty</b>
                           </h4>
                           <h4 className="grid-header">
                              <b>Subtotal</b>
                           </h4>
                        </OrderGrid>
                        <hr style={{ border: "1px solid #333366", margin: "0 0 30px" }} />
                        <OrderGrid>
                           <div style={{ display: "flex", width: "100%" }}>
                              <img
                                 src={checkoutItem?.source}
                                 alt={checkoutItem?.alt}
                                 style={{ width: "178px", height: "147px" }}
                              />
                              <p style={{ marginLeft: "20px" }}>{checkoutItem?.title}</p>
                           </div>
                           <h3>{checkoutItem?.price}</h3>
                           <input type="number" className="quantity" defaultValue={1} disabled />
                           <h3>{checkoutItem?.price}</h3>
                        </OrderGrid>
                        <p style={{ marginTop: "20px" }} className="desktop-only-block">
                           To proceed with checkout, please enter your information below.
                        </p>
                     </OrderListContainer>
                     <CartContainer style={{ marginTop: "30px", height: "fit-content" }}>
                        <p style={{ fontSize: "30px" }}>Order Summary</p>
                        <span style={{ fontSize: "21px", marginBottom: "20px" }}>1 item in Cart</span>
                        <div className="flex-row">
                           <img src={checkoutItem?.source} alt={checkoutItem?.alt} style={{ maxWidth: "35%" }} />
                           <div className="flex-column" style={{ paddingLeft: "15px" }}>
                              <span>{checkoutItem?.title}</span>
                              <span>Qty: 1</span>
                              <span>{checkoutItem?.price}</span>
                           </div>
                        </div>
                        <Button
                           style={{ width: "100%", marginTop: "20px" }}
                           className={
                              isValid ? "submit-button-active desktop-only-block" : "submit-button desktop-only-block"
                           }
                           form="show-email-form"
                        >
                           Place Order
                        </Button>
                     </CartContainer>
                  </OrderContainer>
               )}

               <p style={{ fontSize: "36px", marginTop: "26px" }} className="mobile-only-block">
                  Shopping Cart
               </p>
               <CartContainer className="mobile-only-flex">
                  <p style={{ fontSize: "30px" }}>Order Summary</p>
                  <span style={{ fontSize: "21px", marginBottom: "20px" }}>1 item in Cart</span>
                  <div className="flex-row">
                     <img src={checkoutItem?.source} alt={checkoutItem?.alt} style={{ maxWidth: "35%" }} />
                     <div className="flex-column" style={{ paddingLeft: "15px" }}>
                        <span>{checkoutItem?.title}</span>
                        <span>Qty: 1</span>
                        <span>{checkoutItem?.price}</span>
                     </div>
                  </div>
               </CartContainer>
               <p style={{ marginBottom: "30px" }} className="mobile-only-block">
                  To proceed with checkout, please enter your information below.
               </p>
               <FormContainer>
                  <form id="show-email-form" onSubmit={handleSubmit(handleOrderClick)}>
                     <InputContainer>
                        <label>
                           <span style={{ color: "red" }}>*</span>Email
                        </label>
                        <input type="text" className="form-input" {...register("email")} />
                        <div className="error-message">{errors.email?.message}</div>
                     </InputContainer>
                  </form>
               </FormContainer>
               <Button
                  style={{ width: "100%" }}
                  className={
                     isValid
                        ? "submit-button-active medium-mb mobile-only-block"
                        : "submit-button medium-mb mobile-only-block"
                  }
                  form="show-email-form"
               >
                  Place Order
               </Button>
               {mapLocationsJson && (
                  <>
                     <p style={{ fontSize: "36px" }}>Curious about our locker network?</p>
                     {mapLocationsJson && screenSize.width > 960 && !showCount && (
                        <MapContainer>
                           <NewMapListHolder
                              id="map-list"
                              mapLocations={mapListJson}
                              paginationTab={1}
                              lockersPerPage={1000}
                              noStatus
                              noCount
                              enableSearch
                              setLockerListRaiseFunction={mapListSet}
                              centerChangeRaiseFunction={mapCenterChange}
                           />
                           <CSVGoogleMaps
                              id="google-map"
                              mapLocations={mapLocationsJson}
                              mapCenter={mapCenter}
                              zoom={zoom}
                              noStatus
                              noCount
                           />
                        </MapContainer>
                     )}
                     {mapLocationsJson && screenSize.width > 960 && showCount && (
                        <MapContainer>
                           <NewMapListHolder
                              id="map-list"
                              mapLocations={mapListJson}
                              paginationTab={1}
                              lockersPerPage={1000}
                              noStatus
                              enableSearch
                              setLockerListRaiseFunction={mapListSet}
                              centerChangeRaiseFunction={mapCenterChange}
                           />
                           <CSVGoogleMaps
                              id="google-map"
                              mapLocations={mapLocationsJson}
                              mapCenter={mapCenter}
                              zoom={zoom}
                              noStatus
                           />
                        </MapContainer>
                     )}
                     {mapLocationsJson && screenSize.width <= 960 && (
                        <MobileRow>
                           <MobileMapTab
                              className={mobileMapView === "list" ? "active" : ""}
                              onClick={() => setMobileMapView("list")}
                           >
                              <img src={ListMobileIcon} alt="locker lookup list icon" />
                              <b>List</b>
                           </MobileMapTab>
                           <MobileMapTab
                              className={mobileMapView === "map" ? "active" : ""}
                              onClick={() => setMobileMapView("map")}
                           >
                              <img src={MapMobileIcon} alt="locker lookup map icon" />
                              <b>Map</b>
                           </MobileMapTab>
                        </MobileRow>
                     )}

                     {mapLocationsJson && screenSize.width <= 960 && mobileMapView === "list" && !showCount && (
                        <MapContainer>
                           <NewMapListHolder
                              id="map-list"
                              mapLocations={mapListJson}
                              paginationTab={1}
                              lockersPerPage={1000}
                              noStatus
                              noCount
                              enableSearch
                              setLockerListRaiseFunction={mapListSet}
                              centerChangeRaiseFunction={mapCenterChange}
                           />
                        </MapContainer>
                     )}
                     {mapLocationsJson && screenSize.width <= 960 && mobileMapView === "list" && showCount && (
                        <MapContainer>
                           <NewMapListHolder
                              id="map-list"
                              mapLocations={mapListJson}
                              paginationTab={1}
                              lockersPerPage={1000}
                              noStatus
                              enableSearch
                              setLockerListRaiseFunction={mapListSet}
                              centerChangeRaiseFunction={mapCenterChange}
                           />
                        </MapContainer>
                     )}
                     {mapLocationsJson && screenSize.width <= 960 && mobileMapView === "map" && !showCount && (
                        <MapContainer>
                           <CSVGoogleMaps
                              id="google-map"
                              mapLocations={mapLocationsJson}
                              mapCenter={mapCenter}
                              zoom={mobileZoom}
                              mobile
                              noCount
                              noStatus
                           />
                        </MapContainer>
                     )}
                     {mapLocationsJson && screenSize.width <= 960 && mobileMapView === "map" && showCount && (
                        <MapContainer>
                           <CSVGoogleMaps
                              id="google-map"
                              mapLocations={mapLocationsJson}
                              mapCenter={mapCenter}
                              zoom={mobileZoom}
                              mobile
                              noStatus
                           />
                        </MapContainer>
                     )}
                  </>
               )}
            </PageContainer>
         </Container>
      );
   } else return <Navigate replace to={"/"} />;
};

NPFCartPage.propTypes = NPFCartPageProps;

export default NPFCartPage;
