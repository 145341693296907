import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { informedDeliveryRemindersApi } from "../services/Api";

const rootReducer = combineReducers({
   [informedDeliveryRemindersApi.reducerPath]: informedDeliveryRemindersApi.reducer,
});

const preloadedState = {};

export const store = configureStore({
   reducer: rootReducer,
   middleware: getDefaultMiddleware => getDefaultMiddleware().concat(informedDeliveryRemindersApi.middleware),
   preloadedState: preloadedState,
});

store.subscribe(() => {
   // TODO add items to do here when store updates - such as updating local storage
   // store.getState().my.info
});

setupListeners(store.dispatch);
