import styled from "styled-components";

const FootnoteContainer = styled.div`
   display: flex;
   flex-direction: column;

   ul {
      list-style-type: decimal;
   }
`;

function Footnotes() {
   return (
      <FootnoteContainer>
         <p id="footnote1">
            <sup>1</sup>QR code is a trademark of Denso Wave, Inc.
         </p>
      </FootnoteContainer>
   );
}

export default Footnotes;
