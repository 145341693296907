import styled from "styled-components";
import { Button, Theme } from "@informed-innovations/components";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as Chevron } from "../../static/img/chevron.svg";
import CSVGoogleMaps from "../../common/components/CSVGoogleMaps";
import { Api } from "../../services/Api";
import CSVMapListHolder from "../../common/components/CSVMapListHolder";
import { Modal } from "../../common/components/Modal";
import ListMobileIcon from "../../static/img/list-mobile-icon.PNG";
import MapMobileIcon from "../../static/img/map-mobile-icon.PNG";
import Geocode from "react-geocode";
import crosshair from "../../static/img/crosshair.PNG";
import {
   Container,
   ContentContainer,
   FeatureContainer,
   GreyContentContainer,
   GreyContentContainerInner,
} from "../../common/components/WebLayoutComponents";
import LockerSearchWithFilter from "./LockerSearchWithFilter";
import IconDelete from "../../static/img/Icon-Delete.svg";
import AlphabetSearch from "./AlphabetSearch";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router";
import { AddressChecker } from "../../common/utils";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const SearchHeroContainer = styled.div`
   h2,
   p {
      text-align: center;
   }

   h2 {
      margin: 0;
   }

   p {
      margin-bottom: 30px;
   }
`;

const FormContainer = styled.div`
   display: flex;
   flex-direction: column;

   @media screen and (max-width: 960px) {
      flex-direction: column;
   }
`;

const FormInnerContainer = styled.div`
   display: flex;
   margin: 0 auto;
   max-width: 933px;

   color: ${Theme.colors.black};
   font-size: 16px;

   > * {
      margin: 0;

      &:not(:last-child) {
         padding-right: 25px;
      }
   }

   @media screen and (max-width: 960px) {
      flex-direction: column;
      gap: 20px;
      line-height: 2;
      justify-content: center;
      > * {
         max-width: 100%;

         &:not(:last-child) {
            padding-right: 0;
         }
      }
   }

   #location-input-container {
      max-width: 725px;
      width: 100%;

      @media screen and (max-width: 960px) {
         max-width: none;
      }
   }

   #search-radius-container {
      max-width: 209px;
      width: 100%;

      @media screen and (max-width: 960px) {
         max-width: none;
      }
   }
`;

const FormInputContainer = styled.div`
   display: flex;
   flex-direction: column;

   @media screen and (max-width: 960px) {
      padding: 0;
   }

   p {
      margin: 0;
      text-align: left;
   }

   .search-radius-dropdown {
      list-style: none;
   }

   .location-input-inner {
      border: 1px solid black;
      border-radius: 5px;
      display: flex;
      overflow: hidden;
      background-color: #ffffff;
   }

   .location-text-input {
      padding: 12px 10px;
      border: none;
      width: calc(100% - 50px);
      outline: none;
   }

   .locating-crosshair {
      margin: auto 10px auto auto;
      cursor: pointer;
   }

   .search-radius-input {
      padding: 12px 10px;
      min-width: 150px;
      width: 100%;
      max-width: 209px;
      border-radius: 5px;
      border-width: 1px;
      background-color: field;
      text-align: left;
      display: flex;

      @media screen and (max-width: 960px) {
         max-width: none;
      }
   }

   .form-input-invalid {
      color: red;
      max-width: 100%;
   }

   .form-input-invalid span {
      color: #3573b1;
      text-decoration: underline;
      cursor: pointer;
   }

   .form-input-invalid span:hover {
      text-decoration: none;
   }

   .form-input-valid {
      display: none;
      height: 18px;
   }

   .svg-holder {
      margin-left: auto;
      transition: opacity 150ms ease-in-out;
      opacity: 1;
   }

   .svg-holder svg {
      height: 20px;
      width: 20px;
      transform: rotate(90deg);
   }

   .input-dropdown-active {
      opacity: 0;
   }

   .search-radius-dropdown {
      opacity: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      position: absolute;
      margin-top: 73.6px;
      justify-content: left;
      background: field;
      height: 175px;
      min-width: 150px;
      width: 100%;
      max-width: 209px;
      overflow-y: scroll;
      pointer-events: none;
      transform: translateY(-10px);
      transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
      z-index: 1;

      @media screen and (max-width: 960px) {
         position: relative;
         display: none;
      }
   }

   .search-radius-dropdown li {
      padding: 12px 0 12px 10px;
      cursor: pointer;
   }

   .search-radius-dropdown li:hover {
      background: #343366;
      color: white;
   }

   .dropdown-active {
      display: flex;
      opacity: 1;
      pointer-events: auto;

      @media screen and (max-width: 960px) {
         position: relative;
         margin-top: 0;
      }
   }

   .submit-button-active {
      padding: 12px 10px;
      margin: 30px auto 0;
      border: none;
      border-radius: 3px;
      min-width: 100px;
      width: 100%;
      max-width: 310px;
      background: #343366;
      color: white;
      font-weight: bold;
   }

   .submit-button-active:hover {
      background: #d3d3d3;
      color: #343366;
   }

   .submit-button {
      padding: 12px 10px;
      margin: 30px auto 0;
      border: none;
      border-radius: 3px;
      min-width: 100px;
      width: 100%;
      max-width: 310px;
      background: #d3d3d3;
      color: #343366;
      font-weight: bold;
   }

   .usps-button {
      border: none;
      background: #343366;
      color: white;
      padding: 12px 10px;
      margin: 24px 0 auto 0;
      min-width: 100px;
      font-weight: bold;
      border-radius: 3px;

      @media screen and (max-width: 960px) {
         min-width: 100%;
         margin: 0 auto;
      }
   }

   .usps-button:hover {
      background: #d3d3d3;
      color: #343366;
   }
`;

const GreySearchContainer = styled(GreyContentContainerInner)`
   @media screen and (max-width: 1185px) {
      padding: 40px 0;
   }
`;

const MapContainer = styled.div`
   margin-top: 20px;
   display: flex;
`;

const MobileRow = styled.div`
   display: flex;
   width: 100%;
   justify-content: space-around;

   .active {
      padding-bottom: 2px;
      border-bottom: 4px solid ${Theme.colors.red};
   }
`;

const MobileMapTab = styled.div`
   display: flex;
   margin: 40px 20px;
   cursor: pointer;

   img {
      height: 20px;
      padding-right: 10px;
   }
`;

const MapHeader = styled.div`
   display: flex;

   @media screen and (max-width: 960px) {
      flex-direction: column;
   }
`;

const CSVLockerLookupProps = {
   lockerList: PropTypes.object,
};

export const CSVLockerLookup = props => {
   const api = new Api();
   api.setup();

   useEffect(() => {
      const savedLockerLookupState = JSON.parse(sessionStorage.getItem("savedLockerLookupState"));
      if (savedLockerLookupState) {
         setLocationInput(savedLockerLookupState.locationInput);
         setRadiusInput(savedLockerLookupState.radiusInput);
         setJson(savedLockerLookupState.json);
         setMapShown(savedLockerLookupState.mapShown);
         setMapCenter(savedLockerLookupState.mapCenter);
         setMobileMapView(savedLockerLookupState.mobileMapView);
         setPaginationTab(savedLockerLookupState.paginationTab);
         setNumberOfLockers(savedLockerLookupState.numberOfLockers);
         sessionStorage.removeItem("savedLockerLookupState");
      }
   }, []);

   const navigate = useNavigate();
   const formRef = useRef();
   const formSubmitRef = useRef();

   const [locationInput, setLocationInput] = useState("");
   const [radiusInput, setRadiusInput] = useState(20);
   const [radiusDropdownActive, setRadiusDropdownActive] = useState(false);
   const [loadingData, setLoadingData] = useState(false);
   const [loadingFromLink, setLoadingFromLink] = useState(false);
   const [json, setJson] = useState();
   const [locationInputErrorState, setLocationInputErrorState] = useState(false);
   const [mapShown, setMapShown] = useState(false);

   const [mapCenter, setMapCenter] = useState();
   const [centerChange, setCenterChange] = useState(false);
   const [submitError, setSubmitError] = useState(false);
   const [invalidLocationError, setInvalidLocationError] = useState(false);
   const [noResultsError, setNoResultsError] = useState(false);

   const [screenSize, setScreenSize] = useState(getCurrentDimension());
   const [mobileMapView, setMobileMapView] = useState("list");

   const [paginationTab, setPaginationTab] = useState(1);
   const lockersPerPage = 10;
   const [numberOfLockers, setNumberOfLockers] = useState();
   const [shipHereModalOpen, setShipHereModalOpen] = useState(false);

   function getCurrentDimension() {
      return {
         width: window.innerWidth,
         height: window.innerHeight,
      };
   }

   useEffect(() => {
      const updateDimension = () => {
         setScreenSize(getCurrentDimension());
      };
      window.addEventListener("resize", updateDimension);

      return () => {
         window.removeEventListener("resize", updateDimension);
      };
   }, [screenSize]);

   const handleLocationInputChange = e => {
      setLocationInput(e.target.value);
      setNoResultsError(false);
      if (locationInputErrorState) {
         setLocationInputErrorState(false);
      }
   };

   const handleRadiusInputChange = val => {
      setRadiusInput(val);
      setRadiusDropdownActive(false);
      setNoResultsError(false);
      setLocationInputErrorState(false);
   };

   const changeMapCenter = useCallback(() => {
      if (json.data.lockers !== null) {
         const Lati = parseFloat(Number(json.data.lockers[0].locationLatitude));
         const Long = parseFloat(Number(json.data.lockers[0].locationLongitude));
         setMapCenter({ lat: Lati, lng: Long });
      }
      setCenterChange(false);
      setPaginationTab(1);
      setNumberOfLockers(json.data.lockers.length);
   }, [json]);

   const handleLocationData = data => {
      if (data !== undefined) {
         if (data.response.data.sourceLatitude === 0 && data.response.data.lockers === null) {
            setJson(data.response);
            setLoadingData(false);
            setInvalidLocationError(true);
         } else if (data.response.data.lockers === null) {
            setJson(data.response);
            setLoadingData(false);
            setNoResultsError(true);
         } else {
            setJson(data.response);
            setMapShown(true);
            setLoadingData(false);
            setCenterChange(true);
         }
      } else {
         setLoadingData(false);
         setSubmitError(true);
      }
   };

   const getLocationsByZip = async (postalCode, radius) => {
      const result = await api.postGetCSVLocationsByZip(postalCode, radius);
      handleLocationData(result);
   };

   const getLocationsByCityState = async (cityName, stateName, radius) => {
      const result = await api.postGetCSVLocationsByCityState(cityName, stateName, radius);
      handleLocationData(result);
   };

   const onSubmit = e => {
      e.preventDefault();
      setNoResultsError(false);
      setLocationInputErrorState(false);

      if (
         !(
            /([A-Za-z]+(?: [A-Za-z]+)*)?, ([A-Za-z]{2})$/.test(locationInput) ||
            /^\d{5}$/.test(locationInput) ||
            radiusInput === 0
         )
      ) {
         setLocationInputErrorState(true);
      } else if (!(/([A-Za-z]+(?: [A-Za-z]+)*)?, ([A-Za-z]{2})$/.test(locationInput) || radiusInput !== 0)) {
         setLocationInputErrorState(true);
      } else {
         setLoadingData(true);

         const postalCode = AddressChecker(locationInput).zip;
         const cityName = AddressChecker(locationInput).city;
         const stateName = AddressChecker(locationInput).state;

         if (postalCode) {
            getLocationsByZip(locationInput, radiusInput);
         } else getLocationsByCityState(cityName, stateName, radiusInput);
      }
   };

   const handleLockerListClick = citystate => {
      setLocationInput(citystate);
      setLoadingFromLink(true);
      setNoResultsError(false);
   };

   useEffect(() => {
      if (centerChange && json) {
         changeMapCenter();
      }
   }, [json, centerChange, changeMapCenter]);

   useEffect(() => {
      if (loadingFromLink) {
         formSubmitRef.current.click();
         document.getElementById("location-text-input").scrollIntoView({ behavior: "smooth" });
         setLoadingFromLink(false);
      }
   }, [loadingFromLink]);

   useEffect(() => {
      if (locationInput === "") {
         setLocationInputErrorState(false);
      }
   }, [locationInput]);

   Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

   const handleUseLocationClick = () => {
      navigator.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
         const pos = { lat, lng };
         Geocode.fromLatLng(pos.lat, pos.lng).then(resp => {
            let citystate = resp.results[0].address_components[2].long_name + ", ";
            for (const element of resp.results[0].address_components) {
               if (element.types[0] === "administrative_area_level_1") {
                  citystate += element.short_name;
                  break;
               }
            }
            setLocationInput(citystate);
            setLoadingFromLink(true);
            setNoResultsError(false);
         });
      });
   };

   const clearSearch = () => {
      setMapShown(false);
      setLocationInput("");
      setNoResultsError(false);
      setLocationInputErrorState(false);
   };

   const handlePaginationChange = (event, number) => {
      setPaginationTab(number);
   };

   const handleProceedGuestClick = () => {
      setShipHereModalOpen(false);
      sessionStorage.setItem(
         "savedLockerLookupState",
         JSON.stringify({
            locationInput: locationInput,
            radiusInput: radiusInput,
            json: json,
            mapShown: mapShown,
            mapCenter: mapCenter,
            mobileMapView: mobileMapView,
            paginationTab: paginationTab,
            numberOfLockers: numberOfLockers,
         })
      );
      navigate("/guest-reservation/email");
   };

   return (
      <Container>
         <Helmet>
            <title>Smart Lockers Locations | USPS</title>
            <meta
               name="description"
               content="Search for free, secure, self-service USPS Smart Locker locations at select Post Offices across the U.S."
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@USPS" />
            <meta name="twitter:title" content="Smart Lockers Locations | USPS" />
            <meta
               name="twitter:description"
               content="Search for free, secure, self-service USPS Smart Locker locations at select Post Offices across the U.S."
            />
            <meta
               name="twitter:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />

            <meta property="og:url" content="https://www.uspssmartpackagelockers.com/" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Smart Lockers Locations | USPS" />
            <meta
               property="og:description"
               content="Search for free, secure, self-service USPS Smart Locker locations at select Post Offices across the U.S."
            />
            <meta
               property="og:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />
         </Helmet>
         <Modal modalActive={submitError} closeFunction={() => setSubmitError(false)}>
            <h1>Hmm...</h1>
            <p>Looks like something went wrong, please try again later.</p>
            <button onClick={() => setSubmitError(false)}>Close</button>
         </Modal>
         <Modal modalActive={invalidLocationError} closeFunction={() => setInvalidLocationError(false)}>
            <h1>Can't Find Location</h1>
            <p>
               Please check what was submitted and try again. Only include either "City, State" or "Zip" in search bar.
            </p>
            <button onClick={() => setInvalidLocationError(false)}>Close</button>
         </Modal>
         <Modal modalActive={shipHereModalOpen} closeFunction={() => setShipHereModalOpen(false)}>
            <img
               src={IconDelete}
               alt="exit button"
               className="pointer"
               style={{ width: 20 + "px", height: 20 + "px", float: "right" }}
               onClick={() => setShipHereModalOpen(false)}
               onKeyDown={() => setShipHereModalOpen(false)}
            />
            <h1>Reserve a USPS Smart Locker</h1>
            <div style={{ display: "flex" }}>
               <div style={{ margin: "40px" }}>
                  <h1 style={{ marginBottom: "30px" }}>Returning Customer</h1>
                  <Button primary>Coming Soon</Button>
               </div>
               <div style={{ borderLeft: "1px solid #707070", height: "200px" }}></div>
               <div style={{ margin: "40px" }}>
                  <h1 style={{ marginBottom: "30px" }}>Continue as Guest</h1>
                  <Button secondary onClick={handleProceedGuestClick}>
                     Proceed as Guest
                  </Button>
               </div>
            </div>
         </Modal>
         <GreyContentContainer>
            <GreySearchContainer>
               <SearchHeroContainer>
                  <h2>Find USPS Smart Locker Locations</h2>
                  <p>
                     The U.S. Postal Service<sup>&reg;</sup> offers Smart Locker Locations at select Post Offices
                     <sup>&trade;</sup>. Smart Lockers are being rolled out across the country in the coming months.
                  </p>
                  <FormContainer>
                     <form id="locker-lookup-form" onSubmit={onSubmit} ref={formRef}>
                        <FormInnerContainer>
                           <FormInputContainer id="location-input-container">
                              <p>Find a Locker Location</p>
                              <div className="location-input-inner">
                                 <input
                                    className="location-text-input"
                                    id="location-text-input"
                                    type="text"
                                    placeholder="City and State, or ZIP Code™"
                                    onChange={handleLocationInputChange}
                                    value={locationInput}
                                 />
                                 <img
                                    src={crosshair}
                                    alt="locating crosshair"
                                    className="locating-crosshair"
                                    onClick={handleUseLocationClick}
                                    onKeyDown={handleUseLocationClick}
                                 />
                              </div>
                              <p
                                 className={
                                    /([A-Za-z]+(?: [A-Za-z]+)*)?, ([A-Za-z]{2})$/.test(locationInput) ||
                                    /^\d{5}$/.test(locationInput) ||
                                    !locationInputErrorState ||
                                    radiusInput === 0
                                       ? "form-input-valid"
                                       : "form-input-invalid"
                                 }
                              >
                                 Invalid Format: Use either "New York, NY" for City, State or "20190" for ZIP Code
                                 <sup>&trade;</sup>
                              </p>
                              <p
                                 className={
                                    /([A-Za-z]+(?: [A-Za-z]+)*)?, ([A-Za-z]{2})$/.test(locationInput) ||
                                    !locationInputErrorState ||
                                    radiusInput !== 0
                                       ? "form-input-valid"
                                       : "form-input-invalid"
                                 }
                              >
                                 Invalid Format: When searching with radius "City Only", use format "New York, NY" for
                                 City, State
                              </p>
                              <p className={noResultsError ? "form-input-invalid" : "form-input-valid"}>
                                 There are no lockers in this area yet, please clear your search and check the list
                                 below for nearby lockers.
                              </p>
                           </FormInputContainer>
                           <FormInputContainer id="search-radius-container">
                              <p>Within</p>
                              <button
                                 type="button"
                                 className="search-radius-input"
                                 onClick={() => setRadiusDropdownActive(!radiusDropdownActive)}
                              >
                                 {radiusInput === 1 ? radiusInput + " Mile" : radiusInput + " Miles"}
                                 <div
                                    className={radiusDropdownActive ? "input-dropdown-active svg-holder" : "svg-holder"}
                                 >
                                    <Chevron />
                                 </div>
                              </button>
                              <ul
                                 className={
                                    radiusDropdownActive
                                       ? "dropdown-active search-radius-dropdown"
                                       : "search-radius-dropdown"
                                 }
                              >
                                 {/* <li onClick={() => handleRadiusInputChange(0)}>City Only</li> */}
                                 <li
                                    onClick={() => handleRadiusInputChange(1)}
                                    onKeyDown={() => handleRadiusInputChange(1)}
                                 >
                                    1 Mile
                                 </li>
                                 <li
                                    onClick={() => handleRadiusInputChange(10)}
                                    onKeyDown={() => handleRadiusInputChange(10)}
                                 >
                                    10 Miles
                                 </li>
                                 <li
                                    onClick={() => handleRadiusInputChange(20)}
                                    onKeyDown={() => handleRadiusInputChange(20)}
                                 >
                                    20 Miles
                                 </li>
                                 <li
                                    onClick={() => handleRadiusInputChange(25)}
                                    onKeyDown={() => handleRadiusInputChange(25)}
                                 >
                                    25 Miles
                                 </li>
                                 <li
                                    onClick={() => handleRadiusInputChange(50)}
                                    onKeyDown={() => handleRadiusInputChange(50)}
                                 >
                                    50 Miles
                                 </li>
                                 <li
                                    onClick={() => handleRadiusInputChange(100)}
                                    onKeyDown={() => handleRadiusInputChange(100)}
                                 >
                                    100 Miles
                                 </li>
                              </ul>
                           </FormInputContainer>
                        </FormInnerContainer>
                        <FormInputContainer>
                           <input
                              className="submit-button-active"
                              type="submit"
                              form="locker-lookup-form"
                              ref={formSubmitRef}
                              id="submit-button"
                              disabled={loadingData}
                              value={loadingData ? "Searching..." : "Search"}
                           />
                        </FormInputContainer>
                     </form>
                  </FormContainer>
               </SearchHeroContainer>
            </GreySearchContainer>
         </GreyContentContainer>
         <ContentContainer className="full" style={{ marginBottom: 40 + "px" }}>
            {!mapShown && (
               <FeatureContainer>
                  {locationInput === "" && <AlphabetSearch lockerList={props.lockerList} />}
                  <LockerSearchWithFilter
                     lockers={props.lockerList}
                     onCityClick={handleLockerListClick}
                     locationInput={locationInput}
                  />
               </FeatureContainer>
            )}
            {/* If screen is smaller than 960 pixels show tabs to toggle between the map and list */}
            {json && mapShown && screenSize.width <= 960 && (
               <MobileRow>
                  <MobileMapTab
                     className={mobileMapView === "list" ? "active" : ""}
                     onClick={() => setMobileMapView("list")}
                  >
                     <img src={ListMobileIcon} alt="locker lookup list icon" />
                     <b>List</b>
                  </MobileMapTab>
                  <MobileMapTab
                     className={mobileMapView === "map" ? "active" : ""}
                     onClick={() => setMobileMapView("map")}
                  >
                     <img src={MapMobileIcon} alt="locker lookup map icon" />
                     <b>Map</b>
                  </MobileMapTab>
               </MobileRow>
            )}
            {json && mapCenter && mapShown && screenSize.width > 960 && (
               <>
                  <MapHeader>
                     {json.data.lockers !== null && (
                        <>
                           <Pagination
                              count={Math.ceil(numberOfLockers / lockersPerPage)}
                              page={paginationTab}
                              onChange={handlePaginationChange}
                              showFirstButton
                              showLastButton
                              style={{ margin: "auto 0" }}
                           />
                           <p style={{ margin: "auto auto auto 20px" }}>
                              Showing Results{" "}
                              {(paginationTab - 1) * 10 +
                                 1 +
                                 " - " +
                                 (numberOfLockers > paginationTab * lockersPerPage
                                    ? paginationTab * lockersPerPage
                                    : numberOfLockers)}{" "}
                              of {numberOfLockers}
                           </p>
                        </>
                     )}
                     {json.data.lockers === null && (
                        <>
                           <Pagination count={0} showFirstButton showLastButton disabled style={{ margin: "auto 0" }} />
                           <p style={{ margin: "auto auto auto 20px" }}>No Results Found</p>
                        </>
                     )}
                     <Button secondary onClick={clearSearch} style={{ marginLeft: "auto" }}>
                        <img
                           src={IconDelete}
                           alt="x-icon"
                           style={{
                              width: 12 + "px",
                              height: 12 + "px",
                              margin: "auto 10px 2px auto",
                           }}
                        />{" "}
                        Clear Search
                     </Button>
                  </MapHeader>
                  <MapContainer>
                     <CSVMapListHolder
                        id="map-list"
                        mapLocations={json}
                        paginationTab={paginationTab}
                        lockersPerPage={lockersPerPage}
                        // Uncomment code when modal should show after ship here click
                        // onShipHereClick={() => setShipHereModalOpen(true)}
                        onShipHereClick={handleProceedGuestClick}
                     />
                     <CSVGoogleMaps id="google-map" mapLocations={json} mapCenter={mapCenter} zoom={10} />
                  </MapContainer>
               </>
            )}
            {json && mapCenter && mapShown && screenSize.width <= 960 && mobileMapView === "list" && (
               <>
                  <MapHeader>
                     {json.data.lockers !== null && (
                        <>
                           <Pagination
                              count={Math.ceil(numberOfLockers / lockersPerPage)}
                              page={paginationTab}
                              onChange={handlePaginationChange}
                              showFirstButton
                              showLastButton
                              style={{ margin: "auto auto 10px auto" }}
                           />
                           <p style={{ margin: "auto auto 10px auto" }}>
                              Showing Results{" "}
                              {(paginationTab - 1) * lockersPerPage +
                                 1 +
                                 " - " +
                                 (numberOfLockers > paginationTab * lockersPerPage
                                    ? paginationTab * lockersPerPage
                                    : numberOfLockers)}{" "}
                              of {numberOfLockers}
                           </p>
                        </>
                     )}
                     {json.data.lockers === null && (
                        <>
                           <Pagination
                              count={0}
                              showFirstButton
                              showLastButton
                              disabled
                              style={{ margin: "auto auto 10px auto" }}
                           />
                           <p style={{ margin: "auto auto 10px auto" }}>No Results Found</p>
                        </>
                     )}
                     <Button secondary onClick={clearSearch} style={{ width: "100%" }}>
                        <img
                           src={IconDelete}
                           alt="x-icon"
                           style={{ width: 12 + "px", height: 12 + "px", margin: "auto 10px 2px auto" }}
                        />{" "}
                        Clear Search
                     </Button>
                  </MapHeader>
                  <MapContainer>
                     <CSVMapListHolder
                        id="map-list"
                        mapLocations={json}
                        paginationTab={paginationTab}
                        lockersPerPage={lockersPerPage}
                        // Uncomment code when modal should show after ship here click
                        // onShipHereClick={() => setShipHereModalOpen(true)}
                        onShipHereClick={handleProceedGuestClick}
                     />
                  </MapContainer>
               </>
            )}
            {json && mapCenter && mapShown && screenSize.width <= 960 && mobileMapView === "map" && (
               <>
                  <MapHeader>
                     <Button secondary onClick={clearSearch} style={{ width: "100%" }}>
                        <img
                           src={IconDelete}
                           alt="x-icon"
                           style={{ width: 12 + "px", height: 12 + "px", margin: "auto 10px 2px auto" }}
                        />{" "}
                        Clear Search
                     </Button>
                  </MapHeader>
                  <MapContainer>
                     <CSVGoogleMaps id="google-map" mobile mapLocations={json} mapCenter={mapCenter} zoom={10} />
                  </MapContainer>
               </>
            )}
         </ContentContainer>
      </Container>
   );
};

CSVLockerLookup.propTypes = CSVLockerLookupProps;

export default CSVLockerLookup;
