import styled from "styled-components";
import PropTypes from "prop-types";

const BackdropContainer = styled.div`
   &.backdrop-active {
      position: fixed;
      width: 100%;
      height: 150%;
      background: #333366;
      z-index: 500;
      display: flex;
      opacity: 0.5;
      transition: opacity 150ms ease-in-out;
      margin-top: -200px;
   }

   &.backdrop {
      display: flex;
      pointer-events: none;
      position: fixed;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1;
      opacity: 0;
   }
`;

const BackdropProps = {
   backdropActive: PropTypes.bool,
   closeFunction: PropTypes.func,
};

export const Backdrop = ({ backdropActive, closeFunction }) => {
   return <BackdropContainer className={backdropActive ? "backdrop-active" : "backdrop"} onClick={closeFunction} />;
};

Backdrop.propTypes = BackdropProps;
