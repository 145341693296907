import { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const TooltipContainer = styled.div`
   position: relative;

   .tooltip {
      position: absolute;
      transform: translate(-45%, calc(-100% - 30px));
      border: 1px solid #e0e0e0;
      z-index: 1;
      min-width: 200px;
      color: black;
      background-color: white;
      opacity: 1;
      padding: 10px;
   }

   h1 {
      font-size: 32px;
   }

   h2 {
      font-size: 28px;
   }

   h3 {
      font-size: 22px;
   }

   h4 {
      font-size: 18px;
   }

   p {
      font-size: 12px;
   }
`;

const TooltipProps = {
   text: PropTypes.node,
   children: PropTypes.node,
   style: PropTypes.object,
};

const Tooltip = ({ text, children, style }) => {
   const [isVisible, setIsVisible] = useState(false);

   return (
      <TooltipContainer onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} style={style}>
         {children}
         {isVisible && <div className="tooltip">{text}</div>}
      </TooltipContainer>
   );
};

Tooltip.propTypes = TooltipProps;

export default Tooltip;
