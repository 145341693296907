import { cityStateCheck, postalCodeCheck } from "./MapUtils";
import { States } from "countries-states-cities-service";

export const loadScript = (url, callback) => {
   let script = document.createElement("script");
   script.type = "text/javascript";

   if (script.readyState) {
      script.onreadystatechange = () => {
         if (script.readyState === "loaded" || script.readyState === "complete") {
            script.onreadystatechange = null;
            callback();
         }
      };
   } else {
      script.onload = () => {
         callback();
      };
   }
   script.src = url;
   document.getElementsByTagName("head")[0].appendChild(script);
};

export const hex2rgba = (hex, alpha = 1) => {
   const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
   return `rgba(${r},${g},${b},${alpha})`;
};

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const capitalizeWords = str => {
   // Split the string into words, capitalize the first letter of each word, and join them back
   return str
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
};

export const dataURLtoFile = (dataurl, filename) => {
   let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
   while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
   }
   return new File([u8arr], filename, { type: mime });
};

export const featureFlagCheck = (obj, key, value) =>
   obj && obj.length > 0 ? obj.filter(v => v[key] === value) : [{ flagValue: false }];

export const tradeShowCheck = (obj, key, value) =>
   obj && obj.length > 0 ? obj.filter(v => v[key] === value) : undefined;

export const rgbStringToHex = rgbString =>
   "#" +
   rgbString
      .slice(4, -1)
      .split(",")
      .map(x => (+x).toString(16).padStart(2, "0"))
      .join("");

/**
 * Hex has to be 3 chars long with hash: '#fff"
 */
export const shortHexToLong = hex => {
   if (hex.charAt(0) !== "#" && hex.length !== 3 && hex.length !== 4) {
      return "#" + hex;
   }

   if (hex.charAt(0) === "#" && hex.length !== 4 && hex.length !== 5) {
      return hex;
   }

   let startIdx = 1;
   let endIdx = 4;
   if (hex.charAt(0) !== "#" && (hex.length === 3 || hex.length === 4)) {
      startIdx = 0;
      endIdx = 3;
   }

   let longHex = "#";
   for (let i = startIdx; i < endIdx; i++) {
      longHex += hex.charAt(i) + hex.charAt(i);
   }

   return longHex.toLowerCase();
};

const constructColor = hexString => {
   let hex = colorToLongHex(hexString);
   /* Get the RGB values to calculate the Hue. */
   const r = parseInt(hex.substring(0, 2), 16) / 255;
   const g = parseInt(hex.substring(2, 4), 16) / 255;
   const b = parseInt(hex.substring(4, 6), 16) / 255;

   /* Getting the Max and Min values for Chroma. */
   const max = Math.max(r, g, b);
   const min = Math.min(r, g, b);

   /* Variables for HSV value of hex color. */
   let chr = max - min;
   let hue = 0;
   let val = max;
   let sat = 0;

   if (val > 0) {
      /* Calculate Saturation only if Value isn't 0. */
      sat = chr / val;
      if (sat > 0) {
         if (r === max) {
            hue = 60 * ((g - min - (b - min)) / chr);
            if (hue < 0) {
               hue += 360;
            }
         } else if (g === max) {
            hue = 120 + 60 * ((b - min - (r - min)) / chr);
         } else if (b === max) {
            hue = 240 + 60 * ((r - min - (g - min)) / chr);
         }
      }
   }
   return { hue, hex: hexString, r: r * 255, g: g * 255, b: b * 255 };
};

export const sortColorsByHue = colors => {
   return colors
      .map(color => constructColor(color))
      .sort((a, b) => {
         return a.hue - b.hue;
      })
      .map(color => color.hex);
};

export const colorToLongHex = color => {
   let hex = color;
   if (color.startsWith("rgb")) {
      hex = rgbStringToHex(color);
      hex = hex.replace(/#/g, "");
   }
   hex = shortHexToLong(hex);
   hex = hex.replace(/#/g, "");

   return hex;
};

/**
 * 1 - 2 perceptible through close observation
 * 2 - 10 - perceptible at a glance
 * 11 - 49 - colors are more ismilar than opposite
 * 100 - colors are exact opposite} color
 * @returns delta
 */
export const hexColorDelta = (color1, color2) => {
   if (!color1 || !color2) return 0;

   let hex1 = constructColor(color1);
   let hex2 = constructColor(color2);

   const lab1 = rgb2lab([hex1.r, hex1.g, hex1.b]);
   const lab2 = rgb2lab([hex2.r, hex2.g, hex2.b]);

   // 0 = opposite
   // 1 = same
   return deltaE(lab1, lab2);
};

const rgb2lab = rgb => {
   let r = rgb[0] / 255,
      g = rgb[1] / 255,
      b = rgb[2] / 255,
      x,
      y,
      z;

   r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
   g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
   b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

   x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
   y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
   z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

   x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
   y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
   z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;

   return [116 * y - 16, 500 * (x - y), 200 * (y - z)];
};

// calculate the perceptual distance between colors in CIELAB
// <= 1 - not perceptible
// 1 - 2 perceptible through close observation
// 2 - 10 - perceptible at a glance
// 11 - 49 - colors are more ismilar than opposite
// 100 - colors are exact opposite
const deltaE = (labA, labB) => {
   const deltaL = labA[0] - labB[0];
   const deltaA = labA[1] - labB[1];
   const deltaB = labA[2] - labB[2];
   const c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
   const c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
   const deltaC = c1 - c2;
   let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
   deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
   const sc = 1.0 + 0.045 * c1;
   const sh = 1.0 + 0.015 * c1;
   const deltaLKlsl = deltaL / 1.0;
   const deltaCkcsc = deltaC / sc;
   const deltaHkhsh = deltaH / sh;
   const i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
   return i < 0 ? 0 : Math.sqrt(i);
};

function objectFillToken(object, colors, colorMap) {
   if (typeof object.fill !== "object") {
      if (!Array.isArray(colorMap[object.fillToken])) {
         object.fill = colors[colorMap[object.fillToken]];
      } else {
         object.fill = colors[colorMap[object.fillToken][0]];
      }
   } else {
      const fill = JSON.parse(JSON.stringify(object.fill));

      if (!Array.isArray(colorMap[object.fillToken])) {
         fill.colorStops[0].color = colors[colorMap[object.fillToken]];
         fill.colorStops[1].color = colors[colorMap[object.fillToken]];
      } else {
         fill.colorStops[0].color = colors[colorMap[object.fillToken][0]];
         fill.colorStops[1].color = colors[colorMap[object.fillToken][1]];
      }

      object.fill = fill;
   }
}

export const updateColorMap = (object, colors, colorMap) => {
   if (Array.isArray(object)) {
      object.forEach(obj => updateColorMap(obj, colors, colorMap));
   } else {
      if (object.fillToken) {
         objectFillToken(object, colors, colorMap);
      }
      if (object.strokeToken) {
         object.stroke = colors[colorMap[object.strokeToken]];
      }

      if (object.objects) {
         updateColorMap(object.objects, colors, colorMap);
      }
   }
};

export const convertArrayToObject = (array, key) => {
   const initialValue = {};

   return array.reduce((obj, item) => {
      return {
         ...obj,
         [item[key]]: item,
      };
   }, initialValue);
};

export const getStateTwoDigitCode = stateFullName => {
   return stateListFullToCode[stateFullName];
};

export const getStateFullName = stateTwoDigitCode => {
   return stateListCodetoFull[stateTwoDigitCode];
};

const stateListFullToCode = {
   Arizona: "AZ",
   Alabama: "AL",
   Alaska: "AK",
   Arkansas: "AR",
   California: "CA",
   Colorado: "CO",
   Connecticut: "CT",
   Delaware: "DE",
   "District Of Columbia": "DC",
   Florida: "FL",
   Georgia: "GA",
   Hawaii: "HI",
   Idaho: "ID",
   Illinois: "IL",
   Indiana: "IN",
   Iowa: "IA",
   Kansas: "KS",
   Kentucky: "KY",
   Louisiana: "LA",
   Maine: "ME",
   Maryland: "MD",
   Massachusetts: "MA",
   Michigan: "MI",
   Minnesota: "MN",
   Mississippi: "MS",
   Missouri: "MO",
   Montana: "MT",
   Nebraska: "NE",
   Nevada: "NV",
   "New Hampshire": "NH",
   "New Jersey": "NJ",
   "New Mexico": "NM",
   "New York": "NY",
   "North Carolina": "NC",
   "North Dakota": "ND",
   Ohio: "OH",
   Oklahoma: "OK",
   Oregon: "OR",
   Pennsylvania: "PA",
   "Puerto Rico": "PR",
   "Rhode Island": "RI",
   "South Carolina": "SC",
   "South Dakota": "SD",
   Tennessee: "TN",
   Texas: "TX",
   Utah: "UT",
   Vermont: "VT",
   Virginia: "VA",
   Washington: "WA",
   "West Virginia": "WV",
   Wisconsin: "WI",
   Wyoming: "WY",
};

const stateListCodetoFull = {
   AZ: "Arizona",
   AL: "Alabama",
   AK: "Alaska",
   AR: "Arkansas",
   CA: "California",
   CO: "Colorado",
   CT: "Connecticut",
   DC: "District Of Columbia",
   DE: "Delaware",
   FL: "Florida",
   GA: "Georgia",
   HI: "Hawaii",
   ID: "Idaho",
   IL: "Illinois",
   IN: "Indiana",
   IA: "Iowa",
   KS: "Kansas",
   KY: "Kentucky",
   LA: "Louisiana",
   ME: "Maine",
   MD: "Maryland",
   MA: "Massachusetts",
   MI: "Michigan",
   MN: "Minnesota",
   MS: "Mississippi",
   MO: "Missouri",
   MT: "Montana",
   NE: "Nebraska",
   NV: "Nevada",
   NH: "New Hampshire",
   NJ: "New Jersey",
   NM: "New Mexico",
   NY: "New York",
   NC: "North Carolina",
   ND: "North Dakota",
   OH: "Ohio",
   OK: "Oklahoma",
   OR: "Oregon",
   PA: "Pennsylvania",
   PR: "Puerto Rico",
   RI: "Rhode Island",
   SC: "South Carolina",
   SD: "South Dakota",
   TN: "Tennessee",
   TX: "Texas",
   UT: "Utah",
   VT: "Vermont",
   VA: "Virginia",
   WA: "Washington",
   WV: "West Virginia",
   WI: "Wisconsin",
   WY: "Wyoming",
};

export const alphabet = [
   "A",
   "B",
   "C",
   "D",
   "E",
   "F",
   "G",
   "H",
   "I",
   "J",
   "K",
   "L",
   "M",
   "N",
   "O",
   "P",
   "Q",
   "R",
   "S",
   "T",
   "U",
   "V",
   "W",
   "X",
   "Y",
   "Z",
];

let states = States.getStates({
   filters: {
      country_code: "US",
   },
});

export const AddressChecker = address => {
   let addressComponents;
   let cityState;

   if (cityStateCheck(address)) {
      addressComponents = address.split(",").map(component => component.replace(/,$/, "").trim());
   } else if (postalCodeCheck(address)) {
      addressComponents = address;
   } else {
      addressComponents = address.split(/ (?![^,]*,)/).map(component => component.replace(/,$/, "").trim());
   }

   if (postalCodeCheck(address)) {
      if (/^\d{5}$/.test(addressComponents)) {
         return { zip: addressComponents };
      }
   } else if (cityStateCheck(address)) {
      if (addressComponents.length >= 2) {
         states.forEach(state => {
            Object.values(state).filter(it => {
               cityState = { city: addressComponents[0], state: addressComponents[1] };

               if (!new RegExp(`\\b${addressComponents[1]}\\b`, "i").test(it)) {
                  return undefined;
               }

               return cityState;
            });
         });
      }

      return cityState;
   } else {
      return { zip: "", city: "", state: "" };
   }
};
