import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import mapPin from "../../static/img/map-pin.png";
import PropTypes from "prop-types";

const containerStyle = {
   height: "826px",
   width: "65%",
};

const mobileContainerStyle = {
   height: "600px",
   width: "100%",
};

const GoogleMapsProps = {
   mapLocations: PropTypes.object,
   mobile: PropTypes.bool,
   mapCenter: PropTypes.object,
};

export const GoogleMaps = props => {
   const { mapLocations, mobile, mapCenter } = props;

   const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
   });

   const [map, setMap] = React.useState(null);

   const onLoad = React.useCallback(function callback(map) {
      map.setZoom(10);

      setMap(map);
   }, []);

   const onUnmount = React.useCallback(function callback(map) {
      setMap(null);
   }, []);
   if (mapLocations.data[0] !== undefined) {
      return isLoaded ? (
         <GoogleMap
            mapContainerStyle={mobile ? mobileContainerStyle : containerStyle}
            center={mapCenter}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
         >
            {mapLocations.data.map((location, index) => {
               return (
                  <MarkerF
                     icon={mapPin}
                     key={location.facilityID}
                     position={{
                        lat: parseFloat(Number(location.GeoData.Latitude)),
                        lng: parseFloat(Number(location.GeoData.Longitude)),
                     }}
                     label={{ color: "white", fontSize: "12px", fontWeight: "normal", text: (index + 1).toString() }}
                  />
               );
            })}
         </GoogleMap>
      ) : (
         <></>
      );
   } else {
      return isLoaded ? (
         <GoogleMap
            mapContainerStyle={mobile ? mobileContainerStyle : containerStyle}
            center={mapCenter}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
         />
      ) : (
         <></>
      );
   }
};

GoogleMaps.propTypes = GoogleMapsProps;

export default GoogleMaps;
