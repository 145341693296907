import { Api } from "../../services/Api";
import { Button } from "@informed-innovations/components";
import PropTypes from "prop-types";

const LogExternalLinkProps = {
   target: PropTypes.string,
   button: PropTypes.bool,
   href: PropTypes.string,
   children: PropTypes.node,
};

export const LogExternalLink = props => {
   const { target, button, href, children } = props;

   const api = new Api();
   api.setup();

   const handleClick = async e => {
      e.preventDefault();
      const pathName = window.location.pathname.substring(1);
      const utm_source = pathName === "" ? "lockers-main" : pathName;
      const utm_campaign = href;
      await api.postDynamicLogAnalytics(utm_source, "click", utm_campaign);
      if (target) {
         window.open(href, target);
      } else window.location.href = href;
   };

   if (button) {
      return (
         <Button href={href} onClick={handleClick} {...props}>
            {children}
         </Button>
      );
   } else {
      return (
         <a href={href} onClick={handleClick} {...props}>
            {children}
         </a>
      );
   }
};

LogExternalLink.propTypes = LogExternalLinkProps;

export default LogExternalLink;
