import { forwardRef } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { Theme } from "@informed-innovations/components";
import { Api } from "../../../services/Api";
import {
   BUSINESS_PAGE_URL,
   BUSINESS_TAB_NAME,
   CUSTOMER_PAGE_URL,
   CUSTOMER_TAB_NAME,
   FAQ_PAGE_URL,
   LOCKER_LOCATION_PAGE_URL,
   MERCHANT_PAGE_URL,
   MERCHANT_TAB_NAME,
} from "../../../config/pages";
import PropTypes from "prop-types";

const Links = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
   gap: 16px;
   margin-left: auto;

   ${props =>
      props.active &&
      css`
         span {
            padding-bottom: 2px;
            border-bottom: 4px solid ${Theme.colors.red};
         }
      `}
`;

const SubNavContainer = styled.span`
   display: block;
   border-bottom: ${props => (props.active ? `4px solid ${Theme.colors.red}` : "4px solid transparent")};

   &:hover {
      border-bottom: 4px solid ${Theme.colors.red};
   }
`;

const SubNavTitle = styled.h5`
   margin-top: 15px;
   margin-bottom: 10px;
`;

const NavLinkStyle = css`
   position: relative;
   text-align: center;
   align-self: center;
   text-decoration: none;
   cursor: pointer;
   font-weight: bold;
   color: ${Theme.colors.blue};
   border-bottom: 4px solid transparent;

   &:not(:last-child) {
      &::after {
         content: " ";
         border-right: 1px solid ${Theme.colors.gray[600]};
         transform: rotate(20deg);
         position: absolute;
         right: -8px;
         top: 40%;
         height: 30%;
      }
   }
`;

const SubNavExternalLink = styled.a`
   ${NavLinkStyle}
`;
const SubNavLink = styled(Link)`
   ${NavLinkStyle}
`;

const SubNavProps = {
   active: PropTypes.bool,
   children: PropTypes.node,
   to: PropTypes.string,
   href: PropTypes.string,
};

const SubNav = ({ active, children, ...props }) => {
   const api = new Api();
   api.setup();

   const logAnalytics = async () => {
      const pathName = window.location.pathname.substring(1);
      const utm_source = pathName === "" ? "lockers-main" : pathName;
      const campaignPathName = props.to.substring(1);
      const utm_campaign = campaignPathName === "" ? "lockers-main" : campaignPathName;
      const utm_show_id = Number(localStorage.getItem("show_id"));
      return await api.postDynamicLogAnalytics(utm_source, "navbar-click", utm_campaign, utm_show_id);
   };

   if (props.href) {
      return (
         <SubNavExternalLink {...props}>
            <SubNavContainer active={active}>
               <SubNavTitle>{children}</SubNavTitle>
            </SubNavContainer>
         </SubNavExternalLink>
      );
   }

   return (
      <SubNavLink {...props} onClick={() => logAnalytics()}>
         <SubNavContainer active={active}>
            <SubNavTitle>{children}</SubNavTitle>
         </SubNavContainer>
      </SubNavLink>
   );
};

SubNav.propTypes = SubNavProps;

const Desktop = styled.div`
   display: none;
   padding: 10px;
   max-width: 1400px;
   margin: 0 auto;
   @media only screen and (min-width: 960px) {
      display: flex;
   }
`;

const Responsive = styled.div`
   display: none;
   @media only screen and (max-width: 959.99px) {
      display: block;
   }
`;

const Container = styled.div`
   background: white;
   z-index: 15;
   left: 0;
   right: 0;
   top: 0;
   margin: 0;
   overflow: hidden;
   border-bottom: 1px solid #dfdfdf;

   * {
      font-size: 16px;
   }

   a {
      display: block;
      color: ${Theme.colors.blue};
      text-align: center;
      text-decoration: none;
      padding: 14px 16px;

      @media only screen and (max-width: 530px) {
         padding: 14px 10px;
      }
   }
   ${Responsive} {
      a {
         display: flex;
         text-align: left;
         padding: 0 10px;
      }
   }
   width: 100%;
`;

const USPSNavBarProps = {
   hideNavTabs: PropTypes.bool,
   psFlag: PropTypes.string,
};

export const USPSNavBar = forwardRef(({ hideNavTabs, ...props }, ref) => {
   const location = useLocation();

   return (
      <Container {...props} ref={ref}>
         <Desktop>
            {!hideNavTabs && (
               <Links>
                  <SubNav to="/" active={location.pathname === "/"}>
                     Smart Lockers Home
                  </SubNav>
                  <SubNav to={CUSTOMER_PAGE_URL} active={location.pathname === CUSTOMER_PAGE_URL}>
                     {CUSTOMER_TAB_NAME}
                  </SubNav>
                  {props.psFlag === "true" ? (
                     <></>
                  ) : (
                     <SubNav to={MERCHANT_PAGE_URL} active={location.pathname === MERCHANT_PAGE_URL}>
                        {MERCHANT_TAB_NAME}
                     </SubNav>
                  )}
                  <SubNav to={BUSINESS_PAGE_URL} active={location.pathname === BUSINESS_PAGE_URL}>
                     {BUSINESS_TAB_NAME}
                  </SubNav>
                  <SubNav to={LOCKER_LOCATION_PAGE_URL} active={location.pathname === LOCKER_LOCATION_PAGE_URL}>
                     Locker Locations
                  </SubNav>
                  <SubNav to={FAQ_PAGE_URL} active={location.pathname === FAQ_PAGE_URL}>
                     FAQs
                  </SubNav>
                  {/*{props?.tradeShow && props?.tradeShow.length !== 0 && props?.tradeShow[0]["contest-flag"] && (*/}
                  {/*   <SubNav to="/npf2024" active={location.pathname.includes("npf2024")}>*/}
                  {/*      {props.tradeShow[0]["show-name"]}*/}
                  {/*   </SubNav>*/}
                  {/*)}*/}
               </Links>
            )}
         </Desktop>
         <Responsive></Responsive>
      </Container>
   );
});

USPSNavBar.propTypes = USPSNavBarProps;
