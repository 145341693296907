import { Button, Theme } from "@informed-innovations/components";
import { States } from "countries-states-cities-service";
import { useCallback, useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
import styled from "styled-components";
import { GoogleMaps } from "../../common/components/GoogleMaps";
import MapListHolder from "../../common/components/MapListHolder";
import { Modal } from "../../common/components/Modal";
import {
   Col,
   Container,
   ContentContainer,
   FeatureContainer,
   HeroContainer,
   HeroContentContainer,
   HeroImage,
   HeroInnerContainer,
} from "../../common/components/WebLayoutComponents";
import { Api } from "../../services/Api";
import chevron from "../../static/img/chevron.PNG";
import { ReactComponent as Chevron } from "../../static/img/chevron.svg";
import crosshair from "../../static/img/crosshair.PNG";
import ListMobileIcon from "../../static/img/list-mobile-icon.PNG";
import mobileHero from "../../static/img/locker-lookup-mobile-min.png";
import MapMobileIcon from "../../static/img/map-mobile-icon.PNG";

const FormContainer = styled.div`
   display: flex;
   flex-direction: column;

   @media screen and (max-width: 960px) {
      flex-direction: column;
   }
`;

const FormInnerContainer = styled.div`
   display: flex;
   gap: 25px;
   color: ${Theme.colors.black};
   font-size: 16px;

   > * {
      margin: 0;
      max-width: 50%;
      &:not(:last-child) {
         padding-right: 25px;
      }
   }

   @media screen and (max-width: 960px) {
      flex-direction: column;
      gap: 20px;
      line-height: 2;
      justify-content: center;
      > * {
         max-width: 100%;
         &:not(:last-child) {
            padding-right: 0;
         }
      }
   }

   #location-input-container {
      width: 475px;

      @media screen and (max-width: 960px) {
         width: 100%;
      }
   }
`;

const FormInputContainer = styled.div`
   display: flex;
   flex-direction: column;

   @media screen and (max-width: 960px) {
      padding: 0;
   }

   p {
      margin: 0;
   }

   .search-radius-dropdown {
      list-style: none;
   }

   .location-input-inner {
      border: 1px solid black;
      border-radius: 5px;
      display: flex;
      overflow: hidden;
   }

   .location-text-input {
      padding: 12px 10px;
      border: none;
      width: calc(100% - 50px);
      outline: none;
   }

   .locating-crosshair {
      margin: auto 10px auto auto;
      cursor: pointer;
   }

   .search-radius-input {
      padding: 12px 10px;
      min-width: 150px;
      border-radius: 5px;
      border-width: 1px;
      background-color: field;
      text-align: left;
      display: flex;
   }

   .form-input-invalid {
      color: red;
      max-width: 100%;
   }

   .form-input-invalid span {
      color: #3573b1;
      text-decoration: underline;
      cursor: pointer;
   }

   .form-input-invalid span:hover {
      text-decoration: none;
   }

   .form-input-valid {
      display: none;
      height: 18px;
   }

   .svg-holder {
      margin-left: auto;
      transition: opacity 150ms ease-in-out;
      opacity: 1;
   }

   .svg-holder svg {
      height: 20px;
      width: 20px;
      transform: rotate(90deg);
   }

   .input-dropdown-active {
      opacity: 0;
   }

   .search-radius-dropdown {
      opacity: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      position: absolute;
      margin-top: 73.6px;
      justify-content: left;
      background: field;
      height: 175px;
      min-width: 150px;
      overflow-y: scroll;
      pointer-events: none;
      transform: translateY(-10px);
      transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
      z-index: 1;

      @media screen and (max-width: 960px) {
         position: relative;
         display: none;
      }
   }

   .search-radius-dropdown li {
      padding: 12px 0 12px 10px;
      cursor: pointer;
   }

   .search-radius-dropdown li:hover {
      background: #343366;
      color: white;
   }

   .dropdown-active {
      display: flex;
      opacity: 1;
      pointer-events: auto;

      @media screen and (max-width: 960px) {
         position: relative;
         margin-top: 0;
      }
   }

   .submit-button-active {
      padding: 12px 10px;
      margin: 24px 0 auto 0;
      border: none;
      border-radius: 3px;
      min-width: 100px;
      background: #343366;
      color: white;
      font-weight: bold;
   }

   .submit-button-active:hover {
      background: #d3d3d3;
      color: #343366;
   }

   .submit-button {
      padding: 12px 10px;
      margin-top: 24px;
      margin-bottom: auto;
      border: none;
      border-radius: 3px;
      min-width: 100px;
      background: #d3d3d3;
      color: #343366;
      font-weight: bold;
   }

   .usps-button {
      border: none;
      background: #343366;
      color: white;
      padding: 12px 10px;
      margin: 24px 0 auto 0;
      min-width: 100px;
      font-weight: bold;
      border-radius: 3px;

      @media screen and (max-width: 960px) {
         min-width: 100%;
         margin: 0 auto;
      }
   }

   .usps-button:hover {
      background: #d3d3d3;
      color: #343366;
   }
`;

const MapContainer = styled.div`
   margin-top: 20px;
   display: flex;
`;

const MobileRow = styled.div`
   display: flex;
   width: 100%;
   justify-content: space-around;

   .active {
      padding-bottom: 2px;
      border-bottom: 4px solid ${Theme.colors.red};
   }
`;

const MobileMapTab = styled.div`
   display: flex;
   margin: 40px 20px;
   cursor: pointer;

   img {
      height: 20px;
      padding-right: 10px;
   }
`;

const AccordianHolder = styled.div`
   .full {
      width: 100%;
      border-top: 2px solid grey;
      padding-top: 20px;
   }
`;

const LockerList = styled.div`
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   max-height: 400px;
   margin: 10px 0 20px 0;

   @media screen and (max-width: 960px) {
      max-height: 900px;
   }

   @media screen and (max-width: 670px) {
      max-height: 1200px;
   }

   h4 {
      margin-top: 10px;
   }

   a {
      color: #3573b1;
      font-size: 15px;
   }

   span {
      cursor: pointer;
      color: #3573b1;
      margin-right: auto;
   }

   span:hover {
      text-decoration: underline;
   }
`;

const AccordianContainer = styled.div`
   display: flex;
   flex-direction: column;
   border-bottom: 2px solid grey;
   padding-bottom: 20px;
   margin-bottom: 20px;
   width: 596px;

   @media screen and (max-width: 960px) {
      width: auto;
      min-width: 310px;
   }

   .answer-shown {
      max-height: 2000px;
      transition: max-height 300ms ease-in-out;
      overflow: hidden;
   }

   .answer-hidden {
      max-height: 0;
      overflow: hidden;
      transition: max-height 300ms ease-in-out;
   }
`;

const AccordianTitle = styled.div`
   display: flex;
   cursor: pointer;

   h4 {
      color: #336;
      margin: 0 10px 0 0;
   }

   img {
      height: 13px;
      margin: auto 10px auto auto;
   }

   .chevron-up {
      rotate: 180deg;
      transition: rotate 200ms ease-in-out;
   }

   .chevron-down {
      transition: rotate 200ms ease-in-out;
      rotate: 0deg;
   }
`;

const AccordianContent = styled.div`
   display: flex;
   flex-direction: column;

   p {
      margin: 20px 0 0 0;
   }

   a:link,
   a:visited,
   a:focus {
      text-decoration: underline;
      font-weight: bold;
   }

   a:hover {
      text-decoration: none;
   }
`;

export const LockerLookup = () => {
   const api = new Api();
   api.setup();

   const formRef = useRef();
   const formSubmitRef = useRef();

   const [locationInput, setLocationInput] = useState("");
   const [radiusInput, setRadiusInput] = useState(20);
   const [radiusDropdownActive, setRadiusDropdownActive] = useState(false);
   const [loadingData, setLoadingData] = useState(false);
   const [loadingFromLink, setLoadingFromLink] = useState(false);
   const [json, setJson] = useState();
   const [locationInputErrorState, setLocationInputErrorState] = useState(false);
   const [mapRendered, setMapRendered] = useState(false); // will only render map on first working search

   let states = States.getStates({
      filters: {
         country_code: "US",
      },
   });

   const cityStateCheck = str => /^[, a-zA-Z]+$/.test(str);
   const postalCodeCheck = str => /^\d{5}$/.test(str);

   const [mapCenter, setMapCenter] = useState();
   const [centerChange, setCenterChange] = useState(false);
   const [submitError, setSubmitError] = useState(false);
   const [invalidLocationError, setInvalidLocationError] = useState(false);
   const [noResultsError, setNoResultsError] = useState(false);

   const [screenSize, setScreenSize] = useState(getCurrentDimension());
   const [mobileMapView, setMobileMapView] = useState("list");

   function getCurrentDimension() {
      return {
         width: window.innerWidth,
         height: window.innerHeight,
      };
   }

   useEffect(() => {
      const updateDimension = () => {
         setScreenSize(getCurrentDimension());
      };
      window.addEventListener("resize", updateDimension);

      return () => {
         window.removeEventListener("resize", updateDimension);
      };
   }, [screenSize]);

   const handleLocationInputChange = e => {
      setLocationInput(e.target.value);
      setNoResultsError(false);
      if (!locationInputErrorState) {
         setLocationInputErrorState(true);
      }
   };

   const handleRadiusInputChange = e => {
      setRadiusInput(e);
      setRadiusDropdownActive(false);
   };

   const changeMapCenter = useCallback(() => {
      if (json.data[0] !== undefined) {
         const Lati = parseFloat(Number(json.data[0].GeoData.Latitude));
         const Long = parseFloat(Number(json.data[0].GeoData.Longitude));
         setMapCenter({ lat: Lati, lng: Long });
         setCenterChange(false);
      }
   }, [json]);

   const AddressChecker = address => {
      let addressComponents;
      let cityState;

      if (cityStateCheck(address)) {
         addressComponents = address.split(",").map(component => component.replace(/,$/, "").trim());
      } else if (postalCodeCheck(address)) {
         addressComponents = address;
      } else {
         addressComponents = address.split(/ (?![^,]*,)/).map(component => component.replace(/,$/, "").trim());
      }

      if (postalCodeCheck(address)) {
         if (/^\d{5}$/.test(addressComponents)) {
            return { zip: addressComponents };
         }
      } else if (cityStateCheck(address)) {
         if (addressComponents.length >= 2) {
            states.forEach(state => {
               Object.values(state).filter(it => {
                  cityState = { city: addressComponents[0], state: addressComponents[1] };

                  if (!new RegExp(`\\b${addressComponents[1]}\\b`, "i").test(it)) {
                     return undefined;
                  }

                  return cityState;
               });
            });
         }

         return cityState;
      } else {
         return { zip: "", city: "", state: "" };
      }
   };

   const handleLocationData = data => {
      if (data !== undefined) {
         if (data.response.data.Number === "800412df") {
            // if no results in the area the API returns with this code
            setJson(data.response);
            setLoadingData(false);
            setNoResultsError(true);
         } else if (data.response.data.Number === "800412fd") {
            // if location in searchbox isn't a valid location API returns with this code
            setJson(data.response);
            setLoadingData(false);
            setInvalidLocationError(true);
         } else {
            setJson(data.response);
            setMapRendered(true);
            setLoadingData(false);
            setCenterChange(true);
         }
      } else {
         setLoadingData(false);
         setSubmitError(true);
      }
   };

   const getLocationsByZip = async (postalCode, radius) => {
      const result = await api.postGetLocationsByZip(postalCode, radius);
      handleLocationData(result);
   };

   const getLocationsByCityState = async (cityName, stateName, radius) => {
      const result = await api.postGetLocationsByCityState(cityName, stateName, radius);
      handleLocationData(result);
   };

   const handleSubmit = async e => {
      e.preventDefault();
      setLocationInputErrorState(false);

      if (locationInput) {
         setLoadingData(true);

         const postalCode = AddressChecker(locationInput).zip;
         const cityName = AddressChecker(locationInput).city;
         const stateName = AddressChecker(locationInput).state;

         if (postalCode) {
            getLocationsByZip(locationInput, radiusInput);
         } else getLocationsByCityState(cityName, stateName, radiusInput);
      }
   };

   const handleLockerListClick = citystate => {
      setLocationInput(citystate);
      setLoadingFromLink(true);
      setNoResultsError(false);
   };

   const handleNoLockerErrorClick = () => {
      setLockerListOpen(true);
      document.getElementById("locker-list").scrollIntoView({ behavior: "smooth" });
   };

   useEffect(() => {
      if (centerChange && json) {
         changeMapCenter();
      }
   }, [json, centerChange, changeMapCenter]);

   useEffect(() => {
      if (loadingFromLink) {
         formSubmitRef.current.click();
         document.getElementById("location-text-input").scrollIntoView({ behavior: "smooth" });
         setLoadingFromLink(false);
      }
   }, [loadingFromLink]);

   useEffect(() => {
      if (locationInput === "") {
         setLocationInputErrorState(false);
      }
   }, [locationInput]);

   const [lockerListOpen, setLockerListOpen] = useState(false);

   Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

   const handleUseLocationClick = () => {
      navigator.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
         const pos = { lat, lng };
         Geocode.fromLatLng(pos.lat, pos.lng).then(resp => {
            let citystate = resp.results[0].address_components[2].long_name + ", ";
            for (const components of resp.results[0].address_components) {
               if (components.types[0] === "administrative_area_level_1") {
                  citystate += components.short_name;
                  break;
               }
            }
            setLocationInput(citystate);
            setLoadingFromLink(true);
            setNoResultsError(false);
         });
      });
   };

   return (
      <Container>
         <Modal modalActive={submitError} closeFunction={() => setSubmitError(false)}>
            <h1>Hmm...</h1>
            <p>Looks like something went wrong, please try again later.</p>
            <button onClick={() => setSubmitError(false)}>Close</button>
         </Modal>
         <Modal modalActive={invalidLocationError} closeFunction={() => setInvalidLocationError(false)}>
            <h1>Invalid Location</h1>
            <p>
               Please check what was submitted and try again. Only include either "City, State" or "Zip" in search bar.
            </p>
            <button onClick={() => setInvalidLocationError(false)}>Close</button>
         </Modal>
         <HeroContainer className="locker-lookup">
            <HeroInnerContainer>
               <HeroImage src={mobileHero} alt="Customer Hero Image" />
               <HeroContentContainer>
                  <h1>Find a USPS Smart Locker Near You</h1>
                  <p>
                     USPS<sup>&reg;</sup> Smart Locker ensures reliable delivery of your merchandise, so you never have
                     to worry about stolen or lost packages again!
                  </p>
                  <br />
                  <p>
                     Send your packages to a USPS Smart Locker for added convenience - simply select a location during
                     your online checkout process.
                  </p>
                  <Button
                     primary
                     outline="white"
                     onClick={() =>
                        document.getElementById("location-text-input").scrollIntoView({ behavior: "smooth" })
                     }
                  >
                     Find a Locker Location
                  </Button>
               </HeroContentContainer>
            </HeroInnerContainer>
         </HeroContainer>
         <ContentContainer className="full" style={{ marginBottom: 40 + "px" }}>
            <Col>
               <FeatureContainer id="locker-list">
                  <h2>Find USPS Smart Locker Locations</h2>
                  <p>
                     The U.S. Postal Service offers Smart Locker Locations at select Post Offices<sup>&trade;</sup>.
                     Smart Lockers are being rolled out across the country in the coming months.
                  </p>
                  <AccordianHolder>
                     <AccordianContainer className="full">
                        <AccordianTitle onClick={() => setLockerListOpen(!lockerListOpen)}>
                           <h4>Current List of Active Smart Lockers</h4>
                           <img
                              src={chevron}
                              alt="Dropdown Chevron"
                              className={lockerListOpen ? "chevron-up" : "chevron-down"}
                           />
                        </AccordianTitle>
                        <AccordianContent className={lockerListOpen ? "answer-shown" : "answer-hidden"}>
                           <LockerList>
                              <h4>Arkansas</h4>
                              <span
                                 onClick={() => handleLockerListClick("Fayetteville, AR")}
                                 onKeyDown={() => handleLockerListClick("Fayetteville, AR")}
                              >
                                 Fayetteville
                              </span>
                              <h4>California</h4>
                              <span
                                 onClick={() => handleLockerListClick("Bell Gardens, CA")}
                                 onKeyDown={() => handleLockerListClick("Bell Gardens, CA")}
                              >
                                 Bell Gardens
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Bellflower, CA")}
                                 onKeyDown={() => handleLockerListClick("Bellflower, CA")}
                              >
                                 Bellflower
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Carson, CA")}
                                 onKeyDown={() => handleLockerListClick("Carson, CA")}
                              >
                                 Carson
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Gardena, CA")}
                                 onKeyDown={() => handleLockerListClick("Gardena, CA")}
                              >
                                 Gardena
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Los Angeles, CA")}
                                 onKeyDown={() => handleLockerListClick("Los Angeles, CA")}
                              >
                                 Los Angeles
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Redlands, CA")}
                                 onKeyDown={() => handleLockerListClick("Redlands, CA")}
                              >
                                 Redlands
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Placerville, CA")}
                                 onKeyDown={() => handleLockerListClick("Placerville, CA")}
                              >
                                 Placerville
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Venice, CA")}
                                 onKeyDown={() => handleLockerListClick("Venice, CA")}
                              >
                                 Venice
                              </span>
                              <h4>Delaware</h4>
                              <span
                                 onClick={() => handleLockerListClick("Newark, DE")}
                                 onKeyDown={() => handleLockerListClick("Newark, DE")}
                              >
                                 Newark
                              </span>
                              <h4>Florida</h4>
                              <span
                                 onClick={() => handleLockerListClick("Fort Lauderdale, FL")}
                                 onKeyDown={() => handleLockerListClick("Fort Lauderdale, FL")}
                              >
                                 Fort Lauderdale
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Miami, FL")}
                                 onKeyDown={() => handleLockerListClick("Miami, FL")}
                              >
                                 Miami
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Opa Locka, FL")}
                                 onKeyDown={() => handleLockerListClick("Opa Locka, FL")}
                              >
                                 Opa Locka
                              </span>
                              <h4>Georgia</h4>
                              <span
                                 onClick={() => handleLockerListClick("Athens, GA")}
                                 onKeyDown={() => handleLockerListClick("Athens, GA")}
                              >
                                 Athens
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Atlanta, GA")}
                                 onKeyDown={() => handleLockerListClick("Atlanta, GA")}
                              >
                                 Atlanta
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Columbus, GA")}
                                 onKeyDown={() => handleLockerListClick("Columbus, GA")}
                              >
                                 Columbus
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Decatur, GA")}
                                 onKeyDown={() => handleLockerListClick("Decatur, GA")}
                              >
                                 Decatur
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Savannah, GA")}
                                 onKeyDown={() => handleLockerListClick("Savannah, GA")}
                              >
                                 Savannah
                              </span>
                              <h4>Indiana</h4>
                              <span
                                 onClick={() => handleLockerListClick("Fishers, IN")}
                                 onKeyDown={() => handleLockerListClick("Fishers, IN")}
                              >
                                 Fishers
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Greenwood, IN")}
                                 onKeyDown={() => handleLockerListClick("Greenwood, IN")}
                              >
                                 Greenwood
                              </span>
                              <h4>Kentucky</h4>
                              <span
                                 onClick={() => handleLockerListClick("Lexington, KY")}
                                 onKeyDown={() => handleLockerListClick("Lexington, KY")}
                              >
                                 Lexington
                              </span>
                              <h4>Louisiana</h4>
                              <span
                                 onClick={() => handleLockerListClick("Lake Charles, LA")}
                                 onKeyDown={() => handleLockerListClick("Lake Charles, LA")}
                              >
                                 Lake Charles
                              </span>
                              <h4>Massachusetts</h4>
                              <span
                                 onClick={() => handleLockerListClick("Pittsfield, MA")}
                                 onKeyDown={() => handleLockerListClick("Pittsfield, MA")}
                              >
                                 Pittsfield
                              </span>
                              <h4>Minnesota</h4>
                              <span
                                 onClick={() => handleLockerListClick("Rochester, MN")}
                                 onKeyDown={() => handleLockerListClick("Rochester, MN")}
                              >
                                 Rochester
                              </span>
                              <h4>Mississippi</h4>
                              <span
                                 onClick={() => handleLockerListClick("Brandon, MS")}
                                 onKeyDown={() => handleLockerListClick("Brandon, MS")}
                              >
                                 Brandon
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Gulfport, MS")}
                                 onKeyDown={() => handleLockerListClick("Gulfport, MS")}
                              >
                                 Gulfport
                              </span>
                              <h4>North Carolina</h4>
                              <span
                                 onClick={() => handleLockerListClick("Boone, NC")}
                                 onKeyDown={() => handleLockerListClick("Boone, NC")}
                              >
                                 Boone
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Charlotte, NC")}
                                 onKeyDown={() => handleLockerListClick("Charlotte, NC")}
                              >
                                 Charlotte
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Concord, NC")}
                                 onKeyDown={() => handleLockerListClick("Concord, NC")}
                              >
                                 Concord
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Hickory, NC")}
                                 onKeyDown={() => handleLockerListClick("Hickory, NC")}
                              >
                                 Hickory
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("New Bern, NC")}
                                 onKeyDown={() => handleLockerListClick("New Bern, NC")}
                              >
                                 New Bern
                              </span>
                              <h4>South Carolina</h4>
                              <span
                                 onClick={() => handleLockerListClick("Columbia, SC")}
                                 onKeyDown={() => handleLockerListClick("Columbia, SC")}
                              >
                                 Columbia
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Conway, SC")}
                                 onKeyDown={() => handleLockerListClick("Conway, SC")}
                              >
                                 Conway
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Greenville, SC")}
                                 onKeyDown={() => handleLockerListClick("Greenville, SC")}
                              >
                                 Greenville
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Greer, SC")}
                                 onKeyDown={() => handleLockerListClick("Greer, SC")}
                              >
                                 Greer
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Lexington, SC")}
                                 onKeyDown={() => handleLockerListClick("Lexington, SC")}
                              >
                                 Lexington
                              </span>
                              <h4>New Jersey</h4>
                              <span
                                 onClick={() => handleLockerListClick("Lakewood, NJ")}
                                 onKeyDown={() => handleLockerListClick("Lakewood, NJ")}
                              >
                                 Lakewood
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Princeton, NJ")}
                                 onKeyDown={() => handleLockerListClick("Princeton, NJ")}
                              >
                                 Princeton
                              </span>
                              <h4>New York</h4>
                              <span
                                 onClick={() => handleLockerListClick("Brooklyn, NY")}
                                 onKeyDown={() => handleLockerListClick("Brooklyn, NY")}
                              >
                                 Brooklyn
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Merrick, NY")}
                                 onKeyDown={() => handleLockerListClick("Merrick, NY")}
                              >
                                 Merrick
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Newburgh, NY")}
                                 onKeyDown={() => handleLockerListClick("Newburgh, NY")}
                              >
                                 Newburgh
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Staten Island, NY")}
                                 onKeyDown={() => handleLockerListClick("Staten Island, NY")}
                              >
                                 Staten Island
                              </span>
                              <h4>Tennessee</h4>
                              <span
                                 onClick={() => handleLockerListClick("Chattanooga, TN")}
                                 onKeyDown={() => handleLockerListClick("Chattanooga, TN")}
                              >
                                 Chattanooga
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Nashville, TN")}
                                 onKeyDown={() => handleLockerListClick("Nashville, TN")}
                              >
                                 Nashville
                              </span>
                              <h4>Texas</h4>
                              <span
                                 onClick={() => handleLockerListClick("Addison, TX")}
                                 onKeyDown={() => handleLockerListClick("Addison, TX")}
                              >
                                 Addison
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("College Station, TX")}
                                 onKeyDown={() => handleLockerListClick("College Station, TX")}
                              >
                                 College Station
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("McAllen, TX")}
                                 onKeyDown={() => handleLockerListClick("McAllen, TX")}
                              >
                                 McAllen
                              </span>
                              <h4>Virginia</h4>
                              <span
                                 onClick={() => handleLockerListClick("Alexandria, VA")}
                                 onKeyDown={() => handleLockerListClick("Alexandria, VA")}
                              >
                                 Alexandria
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Annandale, VA")}
                                 onKeyDown={() => handleLockerListClick("Annandale, VA")}
                              >
                                 Annandale
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Ashburn, VA")}
                                 onKeyDown={() => handleLockerListClick("Ashburn, VA")}
                              >
                                 Ashburn
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Charlottesville, VA")}
                                 onKeyDown={() => handleLockerListClick("Charlottesville, VA")}
                              >
                                 Charlottesville
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Fairfax, VA")}
                                 onKeyDown={() => handleLockerListClick("Fairfax, VA")}
                              >
                                 Fairfax
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Herndon, VA")}
                                 onKeyDown={() => handleLockerListClick("Herndon, VA")}
                              >
                                 Herndon
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("McLean, VA")}
                                 onKeyDown={() => handleLockerListClick("McLean, VA")}
                              >
                                 McLean
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Reston, VA")}
                                 onKeyDown={() => handleLockerListClick("Reston, VA")}
                              >
                                 Reston
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Sterling, VA")}
                                 onKeyDown={() => handleLockerListClick("Sterling, VA")}
                              >
                                 Sterling
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Vienna, VA")}
                                 onKeyDown={() => handleLockerListClick("Vienna, VA")}
                              >
                                 Vienna
                              </span>
                              <span
                                 onClick={() => handleLockerListClick("Williamsburg, VA")}
                                 onKeyDown={() => handleLockerListClick("Williamsburg, VA")}
                              >
                                 Williamsburg
                              </span>
                              <h4>Washington</h4>
                              <span
                                 onClick={() => handleLockerListClick("Bellingham, WA")}
                                 onKeyDown={() => handleLockerListClick("Bellingham, WA")}
                              >
                                 Bellingham
                              </span>
                           </LockerList>
                        </AccordianContent>
                     </AccordianContainer>
                  </AccordianHolder>
               </FeatureContainer>
            </Col>
            <Col>
               <FormContainer>
                  <form id="locker-lookup-form" onSubmit={handleSubmit} ref={formRef}>
                     <p>*Required Field</p>
                     <FormInnerContainer>
                        <FormInputContainer id="location-input-container">
                           <p>*Find a location</p>
                           <div className="location-input-inner">
                              <input
                                 className="location-text-input"
                                 id="location-text-input"
                                 type="text"
                                 placeholder="City and State, or ZIP Code™"
                                 onChange={handleLocationInputChange}
                                 value={locationInput}
                              />
                              <img
                                 src={crosshair}
                                 alt="locating crosshair"
                                 className="locating-crosshair"
                                 onClick={handleUseLocationClick}
                                 onKeyDown={handleUseLocationClick}
                              />
                           </div>
                           <p
                              className={
                                 /([A-Za-z]+(?: [A-Za-z]+)*)?, ([A-Za-z]{2})$/.test(locationInput) ||
                                 /^\d{5}$/.test(locationInput) ||
                                 !locationInputErrorState
                                    ? "form-input-valid"
                                    : "form-input-invalid"
                              }
                           >
                              Invalid Format: Use either "New York, NY" for City, State or "90210" for ZIP Code
                              <sup>&trade;</sup>
                           </p>
                           <p className={noResultsError ? "form-input-invalid" : "form-input-valid"}>
                              There are no lockers in this area yet, please see the{" "}
                              <span onClick={handleNoLockerErrorClick}>Current List of Active Smart Lockers</span>.
                           </p>
                        </FormInputContainer>
                        <FormInputContainer>
                           <p>Within</p>
                           <button
                              type="button"
                              className="search-radius-input"
                              onClick={() => setRadiusDropdownActive(!radiusDropdownActive)}
                           >
                              {radiusInput} Miles
                              <div className={radiusDropdownActive ? "input-dropdown-active svg-holder" : "svg-holder"}>
                                 <Chevron />
                              </div>
                           </button>
                           <ul
                              className={
                                 radiusDropdownActive
                                    ? "dropdown-active search-radius-dropdown"
                                    : "search-radius-dropdown"
                              }
                           >
                              <li
                                 onClick={() => handleRadiusInputChange(1)}
                                 onKeyDown={() => handleRadiusInputChange(1)}
                              >
                                 1 Mile
                              </li>
                              <li
                                 onClick={() => handleRadiusInputChange(10)}
                                 onKeyDown={() => handleRadiusInputChange(10)}
                              >
                                 10 Miles
                              </li>
                              <li
                                 onClick={() => handleRadiusInputChange(20)}
                                 onKeyDown={() => handleRadiusInputChange(20)}
                              >
                                 20 Miles
                              </li>
                              <li
                                 onClick={() => handleRadiusInputChange(25)}
                                 onKeyDown={() => handleRadiusInputChange(25)}
                              >
                                 25 Miles
                              </li>
                              <li
                                 onClick={() => handleRadiusInputChange(50)}
                                 onKeyDown={() => handleRadiusInputChange(50)}
                              >
                                 50 Miles
                              </li>
                              <li
                                 onClick={() => handleRadiusInputChange(100)}
                                 onKeyDown={() => handleRadiusInputChange(100)}
                              >
                                 100 Miles
                              </li>
                           </ul>
                        </FormInputContainer>
                        <FormInputContainer>
                           <input
                              className={
                                 !(
                                    /([A-Za-z]+(?: [A-Za-z]+)*)?, ([A-Za-z]{2})$/.test(locationInput) ||
                                    /^\d{5}$/.test(locationInput)
                                 ) || loadingData
                                    ? "submit-button"
                                    : "submit-button-active"
                              }
                              type="submit"
                              ref={formSubmitRef}
                              id="submit-button"
                              value={loadingData ? "Searching..." : "Search"}
                              disabled={
                                 !(
                                    /([A-Za-z]+(?: [A-Za-z]+)*)?, ([A-Za-z]{2})$/.test(locationInput) ||
                                    /^\d{5}$/.test(locationInput)
                                 ) || loadingData
                              }
                           />
                        </FormInputContainer>
                     </FormInnerContainer>
                  </form>
                  {json && screenSize.width <= 960 && (
                     <MobileRow>
                        <MobileMapTab
                           className={mobileMapView === "list" ? "active" : ""}
                           onClick={() => setMobileMapView("list")}
                        >
                           <img src={ListMobileIcon} alt="locker lookup list icon" />
                           <b>List</b>
                        </MobileMapTab>
                        <MobileMapTab
                           className={mobileMapView === "map" ? "active" : ""}
                           onClick={() => setMobileMapView("map")}
                        >
                           <img src={MapMobileIcon} alt="locker lookup map icon" />
                           <b>Map</b>
                        </MobileMapTab>
                     </MobileRow>
                  )}
                  {json && mapCenter && mapRendered && screenSize.width > 960 && (
                     <MapContainer>
                        <MapListHolder id="map-list" mapLocations={json} />
                        <GoogleMaps id="google-map" mapLocations={json} mapCenter={mapCenter} />
                     </MapContainer>
                  )}
                  {json && mapCenter && mapRendered && screenSize.width <= 960 && mobileMapView === "list" && (
                     <MapContainer>
                        <MapListHolder id="map-list" mapLocations={json} />
                     </MapContainer>
                  )}
                  {json && mapCenter && mapRendered && screenSize.width <= 960 && mobileMapView === "map" && (
                     <MapContainer>
                        <GoogleMaps id="google-map" mobile mapLocations={json} mapCenter={mapCenter} />
                     </MapContainer>
                  )}
               </FormContainer>
            </Col>
         </ContentContainer>
      </Container>
   );
};

export default LockerLookup;
