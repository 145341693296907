import mobileHero from "../../static/img/locker-open-with-boxes-mobile-min.jpg";
import customersInfoPic from "../../static/img/locker-retrieve.jpg";
import shippersInfoPic from "../../static/img/shippers-info-pic.jpg";
import examplePhone from "../../static/img/Smart-Locker-Phone-with-email.png";
import LogInternalLink from "../../common/components/LogInternalLink";
import {
   CardSplit,
   Container,
   ContentContainer,
   FeatureContainer,
   GreyContentCard,
   GreyContentContainer,
   GreyContentContainerInner,
   HeroContainer,
   HeroContentContainer,
   HeroImage,
   HeroInnerContainer,
   NumberedListBlock,
   NumberedListContainer,
   PictureSideText,
} from "../../common/components/WebLayoutComponents";
import { Col } from "react-bootstrap";
import { CUSTOMER_PAGE_URL, LOCKER_LOCATION_PAGE_URL, MERCHANT_PAGE_URL } from "../../config/pages";
import Footnotes from "../../common/components/Footnotes";
import { Link as ScrollLink } from "react-scroll";
import { Helmet } from "react-helmet";

export const Landing = () => {
   return (
      <Container>
         <Helmet>
            <title>Smart Lockers | USPS</title>
            <meta
               name="description"
               content="USPS Smart Lockers are free, secure, and convenient self-service package lockers at Post Office locations. Find Smart Lockers near you, and learn how to receive packages at Smart Lockers or ship to them."
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@USPS" />
            <meta name="twitter:title" content="Smart Lockers | USPS" />
            <meta
               name="twitter:description"
               content="USPS Smart Lockers are free, secure, and convenient self-service package lockers at Post Office locations. Find Smart Lockers near you, and learn how to receive packages at Smart Lockers or ship to them."
            />
            <meta
               name="twitter:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />

            <meta property="og:url" content="https://www.uspssmartpackagelockers.com/" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Smart Lockers | USPS" />
            <meta
               property="og:description"
               content="USPS Smart Lockers are free, secure, and convenient self-service package lockers at Post Office locations. Find Smart Lockers near you, and learn how to receive packages at Smart Lockers or ship to them."
            />
            <meta
               property="og:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />
         </Helmet>
         <HeroContainer className="landing">
            <HeroInnerContainer>
               <HeroImage
                  src={mobileHero}
                  alt="USPS Smart Locker with dozens of package compartments; a screen, scanner, and keypad; and an open compartment with Priotiry Mail Packages in front of it."
               />
               <HeroContentContainer id="first-footnote-refer">
                  <h1>USPS Smart Lockers</h1>
                  <h3>A free, secure, and convenient package delivery alternative.</h3>
                  <p className="hero-width">
                     Easily ship and receive packages at the fully automated, self-service Smart Locker kiosks.
                  </p>
                  <LogInternalLink primary outline="white" to={LOCKER_LOCATION_PAGE_URL}>
                     Find a Locker Location
                  </LogInternalLink>
               </HeroContentContainer>
            </HeroInnerContainer>
         </HeroContainer>
         <ContentContainer className="full">
            <FeatureContainer>
               <h2>Why Smart Lockers?</h2>
               <p className="content-width">
                  With an increased desire for more flexibility and growing concerns related to package theft, package
                  recipients and package shippers want the confidence that their packages will be delivered safely and
                  on time.
               </p>
               <p className="content-width">
                  That's why USPS is expanding our Smart Locker offering and installing locker sites at Post Office
                  <sup>&trade;</sup> locations across the country to provide package recipients and package shippers
                  with a safe, secure delivery alternative.
               </p>
            </FeatureContainer>
         </ContentContainer>
         <GreyContentContainer>
            <GreyContentContainerInner>
               <h2 className="centered">Benefits for Package Recipients & Shippers</h2>
               <GreyContentCard className="medium-mb">
                  <CardSplit>
                     <img src={customersInfoPic} alt="Customer retrieving item from locker" />
                  </CardSplit>
                  <CardSplit className="text vertical-center">
                     <h3>Package Recipients</h3>
                     <ul style={{ marginBottom: "25px" }}>
                        <li>
                           <strong>Enhanced Security:</strong> Rest easy knowing your package was delivered to a secure
                           Post Office Smart Locker location.
                        </li>
                        <li>
                           <strong>Self-Service Option:</strong> Enjoy a hassle-free trip to the Post Office &mdash;
                           skip the line and retrieve your package(s) using the QR code
                           <sup>
                              <ScrollLink to={"footnote1"} offset={-101} style={{ cursor: "pointer" }}>
                                 1
                              </ScrollLink>
                           </sup>{" "}
                           from your mobile device.
                        </li>
                        <li>
                           <strong>More Flexibilty:</strong> Ship and receive packages on your own terms &mdash;
                           anytime, anywhere.
                        </li>
                     </ul>
                     <LogInternalLink outline="#336" to={CUSTOMER_PAGE_URL}>
                        Learn More
                     </LogInternalLink>
                  </CardSplit>
               </GreyContentCard>
               <GreyContentCard className="mobile-reverse">
                  <CardSplit className="text vertical-center">
                     <h3>Package Shippers</h3>
                     <ul style={{ marginBottom: "25px" }}>
                        <li>
                           <strong>Decrease Costs:</strong> Eliminate expenses associated with missing or stolen
                           packages.
                        </li>
                        <li>
                           <strong>Boost Package Recipient Experience:</strong> Provide package recipients a
                           self-service tool that offers flexible shipment options and a tailored digital experience.
                        </li>
                        <li>
                           <strong>Build Brand Loyalty:</strong> Reduce package recipient anxiety over high-value or
                           sensitive packages by ensuring they are received safely.
                        </li>
                     </ul>
                     <LogInternalLink outline="#336" to={MERCHANT_PAGE_URL}>
                        Learn More
                     </LogInternalLink>
                  </CardSplit>
                  <CardSplit>
                     <img src={shippersInfoPic} alt="Customer retrieving item from locker" />
                  </CardSplit>
               </GreyContentCard>
            </GreyContentContainerInner>
         </GreyContentContainer>
         <ContentContainer className="horizontal">
            <PictureSideText>
               <h3 style={{ marginBottom: 25 + "px" }}>How It Works</h3>
               <p style={{ maxWidth: 500 + "px", marginBottom: 40 + "px" }}>
                  USPS<sup>&reg;</sup> Smart Lockers enable packages to be delivered without package recipients needing
                  to be home, providing them with flexibility and options for receiving packages.
               </p>
               <NumberedListContainer>
                  <NumberedListBlock>
                     <span className="dot">1</span>
                     <span>
                        At the time of checkout, integrated merchants can offer customers the option to ship their
                        package to a USPS Smart Locker.
                     </span>
                  </NumberedListBlock>
                  <NumberedListBlock>
                     <span className="dot">2</span>
                     <span>
                        Package recipients will receive an email notifying them that their package has been safely
                        delivered to a USPS Smart Locker location.
                     </span>
                  </NumberedListBlock>
                  <NumberedListBlock>
                     <span className="dot">3</span>
                     <span>
                        Package recipients should visit the Post Office and use the QR code from their email to open the
                        locker compartment and retrieve their package.
                     </span>
                  </NumberedListBlock>
               </NumberedListContainer>
            </PictureSideText>
            <Col style={{ maxWidth: 396 + "px", margin: "0 auto" }}>
               <img
                  src={examplePhone}
                  alt="Phone showing an example email with order details and QR Code"
                  className="picture-with-shadow"
                  style={{ width: 100 + "%" }}
               />
               <p style={{ position: "relative", bottom: 25 + "px", textAlign: "center" }}>
                  Sample email notification indicating that a package has been delivered to a Smart Locker
               </p>
            </Col>
         </ContentContainer>
         <ContentContainer className="full">
            <Footnotes />
         </ContentContainer>
      </Container>
   );
};

export default Landing;
