import { create } from "apisauce";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL, AUTH_URL, CLIENT_ID } from "../config/constants";
import { isEmpty } from "ramda";

const getAuthorizationHeader = () => {
   const user = JSON.parse(sessionStorage.getItem(`oidc.user:${AUTH_URL}/auth/realms/informed:${CLIENT_ID}`));
   return user ? user.token_type + " " + user.access_token : "";
};

export const hasRole = role => {
   const user = JSON.parse(sessionStorage.getItem(`oidc.user:${AUTH_URL}/auth/realms/informed:${CLIENT_ID}`));
   const roles = (user && user.profile.roles) || [];
   return roles.includes(role);
};

export const informedDeliveryRemindersApi = createApi({
   reducerPath: "informedDeliveryRemindersApi",
   baseQuery: fetchBaseQuery({
      baseUrl: API_URL + "/api",
      prepareHeaders: headers => {
         headers.set("authorization", getAuthorizationHeader());
         return headers;
      },
   }),
   tagTypes: [],
   endpoints: builder => ({}),
});

export class Api {
   setup() {
      this.mapapi = create({
         baseURL: "",
         headers: {
            Accept: "application/xml",
            "Cache-Control": "no-cache",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Methods": "POST",
         },
      });

      this.appsauce = create({
         baseURL: process.env.REACT_APP_API_URL,
         headers: {
            Accept: ["application/json", "image/png", "application/vnd.apple.pkpass, text/csv"],
            "x-api-key": process.env.REACT_APP_API_KEY,
         },
         timeout: 8000,
      });
   }

   async postGetLocationsByZip(postalCode, radiusInput) {
      const response = await this.appsauce.post("/callPoLocator", {
         city: "",
         state: "",
         "zip-code": postalCode,
         radius: radiusInput,
         "max-locations": null,
         service: "PARCELLOCKERS",
      });

      if (!isEmpty(response)) {
         if (response.ok) {
            return { response };
         } else if (response.status === 302 || response.status === 414) {
            return { response: "error" };
         }
      } else {
         return [];
      }
   }

   async postGetLocationsByCityState(cityName, stateName, radiusInput) {
      const response = await this.appsauce.post("/callPoLocator", {
         city: cityName,
         state: stateName,
         "zip-code": "",
         radius: radiusInput,
         "max-locations": null,
         service: "PARCELLOCKERS",
      });

      if (!isEmpty(response)) {
         if (response.ok) {
            return { response };
         } else if (response.status === 302 || response.status === 414) {
            return { response: "error" };
         }
      } else {
         return [];
      }
   }

   async postGetCSVLocationsByZip(postalCode, radiusInput) {
      const response = await this.appsauce.post("/callCSVLockerLookup", {
         city: "",
         state: "",
         "zip-code": postalCode,
         radius: radiusInput,
         "max-locations": null,
         service: "PARCELLOCKERS",
      });

      if (!isEmpty(response)) {
         if (response.ok) {
            return { response };
         } else if (response.status === 302 || response.status === 414) {
            return { response: "error" };
         }
      } else {
         return [];
      }
   }

   async postGetCSVLocationsByCityState(cityName, stateName, radiusInput) {
      const response = await this.appsauce.post("/callCSVLockerLookup", {
         city: cityName,
         state: stateName,
         "zip-code": "",
         radius: radiusInput,
         "max-locations": null,
         service: "PARCELLOCKERS",
      });

      if (!isEmpty(response)) {
         if (response.ok) {
            return { response };
         } else if (response.status === 302 || response.status === 414) {
            return { response: "error" };
         }
      } else {
         return [];
      }
   }

   async postContestPageEmail(email, showId) {
      const response = await this.appsauce.post("/registerUser", { email: email, "show-id": showId });
      return response;
   }

   async postLogAnalytics() {
      const queryString = window.location.search;
      if (queryString) {
         const urlParams = new URLSearchParams(queryString);
         const utm_source = urlParams.get("utm_source");
         const utm_medium = urlParams.get("utm_medium");
         const utm_campaign = urlParams.get("utm_campaign");
         const show_id = urlParams.get("show_id");
         const response = await this.appsauce.post("/logAnalytics", {
            "utm-source": utm_source,
            "utm-medium": utm_medium,
            "utm-campaign": utm_campaign,
            "utm-show-id": show_id,
         });
         return response;
      } else {
         const pathName = window.location.pathname.substring(1);
         const utm_show_id = Number(localStorage.getItem("show_id"));
         const response = await this.appsauce.post("/logAnalytics", {
            "utm-source": "offsite",
            "utm-medium": "",
            "utm-campaign": pathName === "" ? "lockers-main" : pathName,
            "utm-show-id": utm_show_id,
         });
         return response;
      }
   }

   async postDynamicLogAnalytics(utm_source, utm_medium, utm_campaign, utm_show_id) {
      return await this.appsauce.post("/logAnalytics", {
         "utm-source": utm_source,
         "utm-medium": utm_medium,
         "utm-campaign": utm_campaign,
         "utm-show-id": utm_show_id,
      });
   }

   async getFeatureFlags() {
      const response = await this.appsauce.get("/getFeatureFlags");
      return response.ok ? response : false;
   }

   async getFeatureFlag(flagName) {
      return await this.appsauce.get(`/getFeatureFlag/${flagName}`);
   }

   async updateFeatureFlag(flagName, flagValue, flagDescription) {
      const postBody = {
         "flag-name": flagName,
         "flag-value": flagValue,
      };

      if (flagDescription) postBody["flag-description"] = flagDescription;

      return await this.appsauce.post("/saveFeatureFlag", postBody);
   }

   async getAllTradeShows() {
      const response = await this.appsauce.get("/getAllTradeShows");
      return response.ok ? response : false;
   }

   async updateTradeShow(data) {
      const [showName, showId, city, state, url, flagValue, startDate, endDate] = data;
      const postBody = {
         "show-name": showName,
         "show-id": showId,
         city: city,
         state: state,
         url: url,
         "start-date": startDate,
         "end-date": endDate,
         "contest-flag": flagValue,
      };

      const response = await this.appsauce.post("/saveTradeShow", postBody);

      return response;
   }

   async deleteTradeShow(showId) {
      return await this.appsauce.delete(`/deleteTradeShow/${showId}`);
   }

   async deleteFeatureFlag(flagId) {
      return await this.appsauce.delete(`/deleteFeatureFlag/${flagId}`);
   }

   async getAllQrCodes() {
      const response = await this.appsauce.get("/getAllQRCodes");
      return response.ok ? response : false;
   }

   async updateQrCode(qrCode, showId, item, status) {
      const postBody = {
         "qr-code": qrCode,
         "show-id": showId,
         status: status ? "A" : "I",
         item: item,
      };
      const response = await this.appsauce.post("/saveQrCode", postBody);

      return response;
   }

   async deleteQrCode(showId, qrCode) {
      return await this.appsauce.delete(`/deleteQrCode?show-id=${showId}&qr-code=${qrCode}`);
   }

   async getQRCode(uuid) {
      const response = await this.appsauce.get("/media/" + uuid + "/qr");
      return response;
   }

   async getJwtQuery(uuid) {
      const response = await this.appsauce.get("/wallet/" + uuid + "/jwt");
      return response;
   }

   async getCurrentLockerList() {
      const response = await this.appsauce.get("/getAllLockerCities");
      return response;
   }

   async getCSVFile() {
      const response = await this.appsauce.get("/exportParcelLockers");
      return response;
   }

   async getCSVLastUpdate() {
      const response = await this.appsauce.get("/getLastUpdateDate");
      return response;
   }

   async getGuestVerificationCode() {
      const postBody = {
         "email-id": window.sessionStorage.getItem("guest_reserve_email"),
      };
      const response = await this.appsauce.post("/guest/generateVerificationCode", postBody);
      return response;
   }

   async guestReserveLockerLocation(verificationCode) {
      const lockerData = JSON.parse(window.sessionStorage.getItem("selectedReservationLocker"));

      const postBody = {
         "email-id": window.sessionStorage.getItem("guest_reserve_email"),
         "verification-code": verificationCode,
         "facility-id": lockerData.facilityId,
      };
      const response = await this.appsauce.post("/guest/reserveLockerLocation", postBody);
      return response;
   }

   async getGuestReservationsByEmail() {
      const postBody = {
         "email-id": window.sessionStorage.getItem("guest_reserve_email"),
      };
      const response = await this.appsauce.post("/guest/getAllReservationsByEmailId", postBody);
      return response;
   }
}
