import styled from "styled-components";
import { Backdrop } from "./Backdrop";
import PropTypes from "prop-types";

const SubmitPopUp = styled.div`
   &.submit-pop-up-active {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -90%);
      min-width: 270px;
      max-width: 1000px;
      text-align: center;
      background: field;
      padding: 20px;
      z-index: 501;
      position: fixed;
      opacity: 1;
      pointer-events: auto;
   }

   &.submit-pop-up {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -90%);
      margin: 0 auto;
      max-width: 1000px;
      text-align: center;
      background: field;
      padding: 20px;
      position: fixed;
      z-index: 501;
      pointer-events: none;
      opacity: 0;
      transition: opacity 150ms ease-in-out;
   }

   @media screen and (max-width: 960px) {
      &.submit-pop-up,
      &.submit-pop-up-active {
         max-width: 350px;
      }
   }

   .pointer:hover {
      cursor: pointer;
   }
`;

const SubmitInnerPopUp = styled.div`
   margin: 10px auto;
   text-align: center;

   button {
      border: transparent;
      background-color: #333366;
      color: white;
      font-weight: bold;
      padding: 5px 15px;
      border-radius: 5px;
   }

   button:hover {
      background-color: lightgrey;
      color: #333366;
      border: transparent;
   }
`;

const ModalProps = {
   modalActive: PropTypes.bool,
   closeFunction: PropTypes.func,
   children: PropTypes.node,
};

export const Modal = props => {
   const { modalActive, closeFunction, children } = props;

   return (
      <>
         <Backdrop backdropActive={modalActive} closeFunction={closeFunction} />
         <SubmitPopUp className={modalActive ? "submit-pop-up-active" : "submit-pop-up"}>
            <SubmitInnerPopUp>{children}</SubmitInnerPopUp>
         </SubmitPopUp>
      </>
   );
};

Modal.propTypes = ModalProps;

// Example of what Component would look like
//
// <Modal modalActive={submitError} closeFunction={() => setSubmitError(false)}>
//    <h1>Hmm...</h1>
//    <p>Looks like something went wrong, please try again.</p>
//    <button onClick={() => setSubmitError(false)}>
//       Close
//    </button>
// </Modal>
//
