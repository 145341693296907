export const DeleteIcon = ({ fill, ...props }) => {
   return (
      <svg
         {...props}
         id="Icon-Delete"
         xmlns="http://www.w3.org/2000/svg"
         width="100"
         height="100"
         viewBox="0 0 100 100"
      >
         <path
            id="Icon-Delete"
            data-name="Icon-Delete"
            d="M50-0.3c-27.9,0-50,22.7-50,50s22.1,50.6,50,50.6s50-22.7,50-50S77.3-0.3,50-0.3z M70.8,56.2H28.6c-3.9,0-6.5-2.6-6.5-6.5 s2.6-6.5,6.5-6.5h42.2c3.9,0,6.5,2.6,6.5,6.5S74.7,56.2,70.8,56.2z"
         ></path>
      </svg>
   );
};
