import { Helmet } from "react-helmet";
import LogExternalLink from "../../common/components/LogExternalLink";
import LogInternalLink from "../../common/components/LogInternalLink";
import {
   CardSplit,
   Container,
   ContentContainer,
   FeatureContainer,
   GreyContentCard,
   GreyContentContainer,
   GreyContentContainerInner,
   HeroContainer,
   HeroContentContainer,
   HeroImage,
   HeroInnerContainer,
   NumberedListBlock,
} from "../../common/components/WebLayoutComponents";
import { LOCKER_LOCATION_PAGE_URL } from "../../config/pages";
import applyingLabel from "../../static/img/applying-label1.jpg";
import mobileHero from "../../static/img/shippers-hero-mobile.jpg";
import selfServingKiosk from "../../static/img/SSK.jpg";

export const PackageShippers = () => {
   return (
      <Container>
         <Helmet>
            <title>Smart Lockers for Package Shippers | USPS</title>
            <meta
               name="description"
               content="USPS Smart Lockers are free, secure, and convenient self-service package lockers at Post Office locations. Learn how you can send packages to a Smart Locker."
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@USPS" />
            <meta name="twitter:title" content="Smart Lockers for Package Shippers | USPS" />
            <meta
               name="twitter:description"
               content="USPS Smart Lockers are free, secure, and convenient self-service package lockers at Post Office locations. Learn how you can send packages to a Smart Locker."
            />
            <meta
               name="twitter:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />

            <meta property="og:url" content="https://www.uspssmartpackagelockers.com/" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Smart Lockers for Package Shippers | USPS" />
            <meta
               property="og:description"
               content="USPS Smart Lockers are free, secure, and convenient self-service package lockers at Post Office locations. Learn how you can send packages to a Smart Locker."
            />
            <meta
               property="og:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />
         </Helmet>
         <HeroContainer className="merchant">
            <HeroInnerContainer>
               <HeroImage src={mobileHero} alt="A man joyfully opens a package in his living room." />
               <HeroContentContainer>
                  <h1>Ship Packages with Confidence</h1>
                  <p className="hero-width">A safe and secure way to ship packages.</p>
                  <LogInternalLink primary outline="white" to={LOCKER_LOCATION_PAGE_URL}>
                     Find a Locker Location
                  </LogInternalLink>
               </HeroContentContainer>
            </HeroInnerContainer>
         </HeroContainer>
         <ContentContainer className="full">
            <FeatureContainer>
               <h2>Smart Lockers: Free, Secure, and Convenient</h2>
               <p className="content-width">
                  Whether you're a small business owner or sending a gift to a loved one, you can rest easy knowing your
                  package will be delivered to a safe and secure Smart Locker location. Plus, your package recipient can
                  enjoy the flexibility of picking up their package at a time that for them.
               </p>
               <p className="content-width">
                  You can ship a package to a Smart Locker by using the self-service kiosks located in your local Post
                  Office<sup>&trade;</sup> or can print a shipping label at home using Click-N-Ship<sup>&reg;</sup>.
                  Simply find an available locker near the recipient's address and rest assured your package will be
                  securely stored until it's ready to be picked up.
               </p>
            </FeatureContainer>
         </ContentContainer>
         <GreyContentContainer id="SSK">
            <GreyContentContainerInner>
               <h2 style={{ textAlign: "center" }}>How to Send Packages to a Smart Locker</h2>
               <GreyContentCard className="mobile-reverse" style={{ marginBottom: "40px" }}>
                  <CardSplit className="text vertical-center">
                     <h3>At the Post Office:</h3>
                     <p>
                        Save time waiting in the retail line and use the self-service kiosks at any eligible Post Office
                        location to print labels and ship packages direct to a Smart Locker.
                     </p>
                     <p>
                        To ship to a Smart Locker, select the “Mail Letters or Packages” prompt on the self-service
                        kiosk screen. If the destination ZIP code has a Smart Locker location nearby, the “USPS Smart
                        Locker” screen will appear. If you select “Yes” to ship to a Smart Locker, you will be asked to
                        enter the recipient's name, email address, and phone number.
                     </p>
                     <p>
                        Once you've printed your label, adhere the Smart Locker label to your package and place it in
                        the blue drop-off bin to have your package delivered to the Smart Locker.
                     </p>
                     <p>
                        Customers can also visit the retail counter to ship packages. If the destination ZIP code is
                        near an available Smart Locker location, the retail clerk will give the customer the option to
                        ship their package to a Smart Locker.
                     </p>
                  </CardSplit>
                  <CardSplit>
                     <img
                        src={selfServingKiosk}
                        alt="A self serving kiosk that is on and ready to be used by the next customer."
                     />
                  </CardSplit>
               </GreyContentCard>
               <GreyContentCard>
                  <CardSplit>
                     <img src={applyingLabel} alt="Person applying label to package" />
                  </CardSplit>
                  <CardSplit className="text vertical-center">
                     <h3>At Home:</h3>
                     <p className="small-mb">
                        The enhanced Click-N-Ship feature allows you to print and ship packages to a Smart Locker, right
                        from your home or office.
                     </p>
                     <h4 className="small-mb">How it works:</h4>
                     <NumberedListBlock>
                        <span className="dot">1</span>
                        <span>
                           Create a shipping label using{" "}
                           <a href="https://cnsb.usps.com/" className="normal-link-color">
                              enhanced Click-N-Ship
                           </a>{" "}
                           service.
                        </span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">2</span>
                        <span>
                           If the destination address is near a Smart Locker location, you can have you package sent to
                           the Smart Locker.
                        </span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">3</span>
                        <span>
                           If you ship the package to a Smart Locker, you must enter the recipient's email address.
                        </span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">4</span>
                        <span>
                           Once you complete and pay for your shipping label, your package is ready to be shipped to a
                           Smart Locker.
                        </span>
                     </NumberedListBlock>
                     <LogExternalLink
                        secondary
                        button
                        target="_blank"
                        rel="noreferrer"
                        href="https://cnsb.usps.com/"
                        style={{ textDecoration: "none" }}
                     >
                        Create a Label
                     </LogExternalLink>
                  </CardSplit>
               </GreyContentCard>
            </GreyContentContainerInner>
         </GreyContentContainer>
         {/*<ContentContainer>*/}
         {/*   <GreyContentCard style={{ marginBottom: "40px" }} className="mobile-reverse">*/}
         {/*      <CardSplit className="text vertical-center">*/}
         {/*         <h3>Smart Locker Reservations</h3>*/}
         {/*         <h4>How it works</h4>*/}
         {/*         <p>*/}
         {/*            Visit the Locker Locations page and select the Post Office Smart Locker location you wish to ship*/}
         {/*            your package to.*/}
         {/*         </p>*/}
         {/*         <p>Once you’ve selected your designated Post Office, click the “Reserve this Locker” button.</p>*/}
         {/*         <p>*/}
         {/*            Once logged in, you will automatically receive an unique reservation code and shipping address*/}
         {/*            generated for your Smart Locker reservation. This is the address you should enter on the merchant’s*/}
         {/*            website at checkout to complete your order.*/}
         {/*         </p>*/}
         {/*      </CardSplit>*/}
         {/*      <CardSplit className="custom-size-image">*/}
         {/*         <div className="custom-image-holder">*/}
         {/*            <img*/}
         {/*               src={reserveLockerButton}*/}
         {/*               alt="Reserve this locker button on Locker Location Page"*/}
         {/*               className="custom-image"*/}
         {/*            />*/}
         {/*         </div>*/}
         {/*      </CardSplit>*/}
         {/*   </GreyContentCard>*/}
         {/*   <GreyContentCard style={{ marginBottom: "40px" }}>*/}
         {/*      <CardSplit className="custom-size-image">*/}
         {/*         <div className="custom-image-holder">*/}
         {/*            <img src={reservationConfirmation} alt="Confirmation screen after successfully reserving locker" />*/}
         {/*         </div>*/}
         {/*      </CardSplit>*/}
         {/*      <CardSplit className="text vertical-center">*/}
         {/*         <h3>Package pick up</h3>*/}
         {/*         <p>*/}
         {/*            Once your package has been delivered to the Smart Locker, you will receive an email with a one-time*/}
         {/*            QR code. This QR code will serve as your digital key for accessing and retrieving your package at*/}
         {/*            the time of pickup.*/}
         {/*         </p>*/}
         {/*      </CardSplit>*/}
         {/*   </GreyContentCard>*/}
         {/*</ContentContainer>*/}
      </Container>
   );
};

export default PackageShippers;
