import { Helmet } from "react-helmet";
import styled from "styled-components";
import LogExternalLink from "../../common/components/LogExternalLink";
import LogInternalLink from "../../common/components/LogInternalLink";
import {
   Container,
   ContentContainer,
   FeatureContainer,
   HeroContainer,
   HeroContentContainer,
   HeroImage,
   HeroInnerContainer
} from "../../common/components/WebLayoutComponents";
import { LOCKER_LOCATION_PAGE_URL, MERCHANT_PAGE_URL } from "../../config/pages";
import LabelApply from "../../static/img/label-on-small-box-wide.png";
import mobileHero from "../../static/img/locker-open-with-boxes-mobile-min.jpg";
import SelfServingKiosk from "../../static/img/SSK-Front-View.png";

const CardContainer = styled.div`
   display: flex;
   margin-bottom: 40px;
   gap: 20px;
   flex-wrap: wrap;
`;

const CardBox = styled.div`
   max-width: 350px;
   display: flex;
   flex-direction: column;
   box-shadow: 0px 3px 6px #00000029;
   border-radius: 5px;
   height: 100%;

   img {
      width: 100%;
   }
`;

const CardBoxText = styled.div`
   justify-content: space-between;
   display: flex;
   flex-direction: column;
   padding: 35px 40px;

   h3 {
      margin-bottom: 20px;
   }
`;

export const Retailers = () => {
   return (
      <Container>
         <Helmet>
            <title>Smart Lockers for Package Shippers | USPS</title>
            <meta
               name="description"
               content="USPS Smart Lockers are free, secure, and convenient self-service package lockers at Post Office locations. Find Smart Lockers near you, and learn how to receive packages at Smart Lockers or ship to them."
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@USPS" />
            <meta name="twitter:title" content="Smart Lockers | USPS" />
            <meta
               name="twitter:description"
               content="USPS Smart Lockers are free, secure, and convenient self-service package lockers at Post Office locations. Find Smart Lockers near you, and learn how to receive packages at Smart Lockers or ship to them."
            />
            <meta
               name="twitter:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />

            <meta property="og:url" content="https://www.uspssmartpackagelockers.com/" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Smart Lockers | USPS" />
            <meta
               property="og:description"
               content="USPS Smart Lockers are free, secure, and convenient self-service package lockers at Post Office locations. Find Smart Lockers near you, and learn how to receive packages at Smart Lockers or ship to them."
            />
            <meta
               property="og:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />
         </Helmet>
         <HeroContainer className="slim-landing" style={{height: '343px'}}>
            <HeroInnerContainer>
            <HeroImage
                  src={mobileHero}
                  alt="USPS Smart Locker with dozens of package compartments; a screen, scanner, and keypad; and an open compartment with Priotiry Mail Packages in front of it."
               />
               <HeroContentContainer>
                  <h1>USPS Smart Lockers</h1>
                  <h3>A free, secure, and convenient package delivery alternative.</h3>
                  <p className="hero-width">
                     Easily ship and receive packages at the fully automated, self-service Smart Locker kiosks.
                  </p>
                  <LogInternalLink primary outline="white" to={LOCKER_LOCATION_PAGE_URL}>
                     Find a Locker Location
                  </LogInternalLink>
               </HeroContentContainer>
            </HeroInnerContainer>
         </HeroContainer>
         <ContentContainer className="full">
            <FeatureContainer>
               <h2>Smart Lockers: Free, Secure, and Convenient</h2>
               <p className="content-width">
                  Whether you're a small business owner or sending a gift to a loved one, you can rest easy knowing your
                  package will be delivered to a safe and secure Smart Locker location. Plus, your package recipient can
                  enjoy the flexibility of picking up their package at a time that for them.
               </p>
               <p className="content-width">
                  You can ship a package to a Smart Locker by using the self-service kiosks located in your local Post
                  Office<sup>&trade;</sup> or can print a shipping label at home using Click-N-Ship<sup>&reg;</sup>.
                  Simply find an available locker near the recipient's address and rest assured your package will be
                  securely stored until it's ready to be picked up.
               </p>
            </FeatureContainer>
            <hr style={{marginTop: '30px', marginBottom: '30px', border: '1px solid #333366'}}/>
            <h2 style={{marginBottom: '30px'}}>Retailers using Smart Lockers</h2>
            <CardContainer>
            <LogInternalLink alternate to={MERCHANT_PAGE_URL + "#SSK"}>
              <CardBox>
                <img src={SelfServingKiosk} alt="Self Service Kiosk"/>
                <CardBoxText>
                  <h3>Self Service Kiosk</h3>
                  <p>Use the self-service kiosks at more than 2,300 Post Office locations to print labels and ship packages directly to a Smart Locker.</p>
                  <div style={{display: 'flex', maxWidth: '170px'}}>
                        
                  </div>
                </CardBoxText>
              </CardBox>
            </LogInternalLink>
            <LogExternalLink outline="#336" href="https://cnsb.usps.com/home">
              <CardBox>
                <img src={LabelApply} alt="Self Service Kiosk"/>
                <CardBoxText>
                  <h3>Click-N-Ship<sup>&reg;</sup></h3>
                  <p>Print and ship packages to a Smart Locker, right from your home or office.</p>
                  <div style={{display: 'flex', maxWidth: '170px'}}>
                  </div>
                </CardBoxText>
              </CardBox>
            </LogExternalLink>
            </CardContainer>
         </ContentContainer>
      </Container>
   );
};

export default Retailers;
