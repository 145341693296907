import mobileHero from "../../static/img/customer-mobile_02.jpg";
import lockerWithPhoneScan from "../../static/img/locker-with-phone-scan.png";
import LogInternalLink from "../../common/components/LogInternalLink";
import {
   Container,
   ContentContainer,
   FeatureContainer,
   FlexColumn,
   GreyContentContainer,
   GreyContentContainerInner,
   HeroContainer,
   HeroContentContainer,
   HeroImage,
   HeroInnerContainer,
   NumberedListBlock,
   NumberedListContainer,
   Row,
} from "../../common/components/WebLayoutComponents";
import SecureAccessIcon from "../../common/components/icons/Icon-Security-Secure-Access.png";
import SelfServiceIcon from "../../common/components/icons/Icon-Services-Self-Service.png";
import AllDaySupportIcon from "../../common/components/icons/Icon-Support-24-Hours-Accessibility.png";
import { LOCKER_LOCATION_PAGE_URL } from "../../config/pages";
import { Helmet } from "react-helmet";

const PackageRecipients = () => {
   return (
      <Container>
         <Helmet>
            <title>Smart Lockers for Package Recipients | USPS</title>
            <meta
               name="description"
               content="Learn how to use a Smart Locker ID (a QR Code) to pick up a package sent to you via a free, secure, self-service USPS Smart Locker."
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@USPS" />
            <meta name="twitter:title" content="Smart Lockers for Package Recipients | USPS" />
            <meta
               name="twitter:description"
               content="Learn how to use a Smart Locker ID (a QR Code) to pick up a package sent to you via a free, secure, self-service USPS Smart Locker."
            />
            <meta
               name="twitter:image"
               content="https://www.uspssmartpackagelockers.com/static/media/customer-mobile_02.94fe094fa19fa0b4d2a3.jpg"
            />

            <meta property="og:url" content="https://www.uspssmartpackagelockers.com/" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Smart Lockers for Package Recipients | USPS" />
            <meta
               property="og:description"
               content="Learn how to use a Smart Locker ID (a QR Code) to pick up a package sent to you via a free, secure, self-service USPS Smart Locker."
            />
            <meta
               property="og:image"
               content="https://www.uspssmartpackagelockers.com/static/media/customer-mobile_02.94fe094fa19fa0b4d2a3.jpg"
            />
         </Helmet>
         <HeroContainer className="customer">
            <HeroInnerContainer>
               <HeroImage
                  src={mobileHero}
                  alt="Woman happily using a USPS Smart Locker with one hand on her phone with her QR Code ready, and the other navigating through the Smart Locker interface."
               />
               <HeroContentContainer>
                  <h1>Never worry about lost or stolen packages again!</h1>
                  <p className="hero-width">Ship your valuable packages direct to a secure Smart Locker location.</p>
                  <LogInternalLink primary outline="white" to={LOCKER_LOCATION_PAGE_URL}>
                     Find a Locker Nearby
                  </LogInternalLink>
               </HeroContentContainer>
            </HeroInnerContainer>
         </HeroContainer>
         <ContentContainer className="full">
            <FeatureContainer>
               <h2>Smart Lockers for Package Recipients</h2>
               <p className="content-width small-mb">
                  Designed with you in mind – USPS Smart Lockers provide an alternative free, secure, and convenient
                  place to receive your packages when home delivery isn’t practical or preferred.
               </p>
               <Row style={{ maxWidth: "1012px", margin: "0 auto" }}>
                  <FlexColumn className="center-content">
                     <img
                        src={SecureAccessIcon}
                        alt="Computer with padlock"
                        style={{ width: "78px", height: "55px", marginTop: "30px" }}
                        className="small-mb"
                     />
                     <h4 className="small-mb">Enhanced Security</h4>
                     <p className="small-mb">
                        Rest easy knowing your package was delivered to a secure Post Office<sup>&trade;</sup> Smart
                        Locker location.
                     </p>
                  </FlexColumn>
                  <FlexColumn className="center-content">
                     <img
                        src={SelfServiceIcon}
                        alt="Self Service Kiosk Icon"
                        style={{ width: "45px", height: "85px" }}
                        className="small-mb"
                     />
                     <h4 className="small-mb">Self-Service Option</h4>
                     <p className="small-mb">
                        Skip the line at the Post Office and retrieve your package using a QR code sent to your email.
                     </p>
                  </FlexColumn>
                  <FlexColumn className="center-content">
                     <img
                        src={AllDaySupportIcon}
                        alt="24 Hour Support Icon"
                        style={{ width: "64px", height: "64px", marginTop: "21px" }}
                        className="small-mb"
                     />
                     <h4 className="small-mb">More Flexibility</h4>
                     <p className="small-mb">
                        Retrieve your packages at a time that works for you – anytime, anywhere.
                     </p>
                  </FlexColumn>
               </Row>
            </FeatureContainer>
         </ContentContainer>
         <GreyContentContainer>
            <GreyContentContainerInner className="horizontal mobile-reverse">
               <div>
                  <img src={lockerWithPhoneScan} alt="Smart Locker with phone scanning" style={{ width: 100 + "%" }} />
               </div>
               <FeatureContainer className="horizontal-center">
                  <h3 className="small-mb">How Smart Locker Delivery Works</h3>
                  <h4 className="medium-mb">
                     To ship your packages to a Smart Locker, follow these simple steps at checkout:
                  </h4>
                  <NumberedListContainer>
                     <NumberedListBlock>
                        <span className="dot">1</span>
                        <span>
                           At the checkout on a company's digital storefront, select the option to have your package
                           delivered to a USPS Smart Locker.
                        </span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">2</span>
                        <span>
                           Choose which Post Office Smart Locker location you would like to ship your package to and
                           finalize your order details.
                        </span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">3</span>
                        <span>
                           Receive an email notification with a one-time QR code indicating that your package has been
                           delivered to a Smart Locker and is available for pick-up.
                        </span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">4</span>
                        <span>Visit the designated Post Office Smart Locker location to pick up your package.</span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">5</span>
                        <span>
                           At the locker kiosk, scan the QR code via your email to open the locker compartment and
                           retrieve your package.
                        </span>
                     </NumberedListBlock>
                  </NumberedListContainer>
               </FeatureContainer>
            </GreyContentContainerInner>
         </GreyContentContainer>
      </Container>
   );
};

export default PackageRecipients;
